export default class PageNames {
    static PRODUCTS_LIST = "products_list";
    static PRODUCT_DETAILS = "product_details";
    static CHECKOUT = "checkout";
    static CART = "cart";
    static LOGIN = "login";
    static PASSWORD_FORGOTTEN = "passwordForgotten";
    static REGISTER = "register";
    static REGISTER_FINISHED = "registerFinished";
    static RENTAL_PROCESS_FINISHED = "rentalProcessFinished";
}