import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from "react-i18next";

import { IconButton, List, ListSubheader, Stack, Typography } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import PlaylistRemoveIcon from '@mui/icons-material/PlaylistRemove';

import CategoriesTree from '../../CategoriesTree';

import { selectHasSelectedCategory } from '../../../../../store/filtersSlice';

export default function CategorySelectionSection() {
    const navigate = useNavigate();
    const { t } = useTranslation();

    const hasCategorySelected = useSelector(selectHasSelectedCategory);

    const clearSelectedCategory = () => {
        navigate("/items/");
    };

    return (
        <List component="nav"
            aria-labelledby="subheader-categories"
            subheader={
                <ListSubheader component="div" id="subheader-categories" disableSticky={true}>
                    <Stack direction="row" spacing={1} alignItems="center">
                        <Typography variant="headline" component="h5">{t("sidebar.categories")}</Typography>
                        <IconButton onClick={clearSelectedCategory}>
                            { hasCategorySelected ? <PlaylistRemoveIcon color="primary" /> : <MenuIcon color="primary" /> }
                        </IconButton>
                    </Stack>
                </ListSubheader>
            }>
            <CategoriesTree />
        </List>
    );
}