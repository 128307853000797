import dayjs from 'dayjs';
import DateTimeFormatConfig from './DateTimeFormatConfig';

var isBetween = require('dayjs/plugin/isBetween')
dayjs.extend(isBetween);

class RecurrenceOccurences {

    // from is a date
    // day is an int reprezenting day of the week
    // the function will return the next "day" (eg. monday, tuesday, ...) starting with the "from" date
    static get_to_date(from, day) {
        let nextDay = from.clone();
        let dayOfWeek = parseInt(day);

        // set the day - this can be before today, if for example today is tuesday, but "day" represents sunday or monday
        nextDay = nextDay.day(dayOfWeek);
        if(nextDay.isBefore(from)) {
            nextDay = nextDay.day(dayOfWeek + 7);
        }

        return nextDay;
    }

    static calculateOccurence(startDate, endDate, interval, day, day2, startTime = false, endTime = false) {
        let result = [];
        if (interval === 0 || day === 0 || day2 === 0) {
            return result;
        }

        startDate = DateTimeFormatConfig.getDateFromDateOnlyString(startDate);
        endDate = DateTimeFormatConfig.getDateFromDateOnlyString(endDate);
        
        const now = dayjs();
        const startDateForOccurence = startDate < now ? now : startDate;
        
        let increaseInterval = parseInt(interval) * 7;
        let current = startDateForOccurence.clone();

        // the searched day in the given interval
        current = current.day(parseInt(day));
        
        // the day can be before the start date
        if(current.isBetween(startDateForOccurence, endDate, 'day', []))
        {
            const to_date = RecurrenceOccurences.get_to_date(current, day2);

            result.push({
                startDate: current.format('YYYY-MM-DD') + (startTime ? " "+startTime : ""),
                endDate: to_date.format('YYYY-MM-DD') + (endTime ? " "+endTime : "")
            });

            // from the next requested step, iterate while we are in the current interval
            current = current.add(increaseInterval, 'd');
        } else {
            // try next week
            current = current.add(7, 'd');
        }

        while(current.isBetween(startDateForOccurence, endDate, 'day', []))
        {
            const to_date = RecurrenceOccurences.get_to_date(current, day2);

            result.push({
                startDate: current.format('YYYY-MM-DD') + (startTime ? " "+startTime : ""),
                endDate: to_date.format('YYYY-MM-DD') + (endTime ? " "+endTime : "")
            });

            current = current.add(increaseInterval, 'd');
        }

        return result;
    }
};

export default RecurrenceOccurences;
