import React from 'react';
import { useTranslation } from "react-i18next";

import { Typography, Stack } from '@mui/material';

export default function PasswordChanged() {
    const { t } = useTranslation(['passwordForgotten']);

    return (
        <Stack spacing={3} sx={{ my: 3 }}>
            <Typography color="text.primary" component="h2" variant="h5">
                {t("passwordChangedSuccessTitle")}
            </Typography>
            <Typography component="h2" variant="body2">
                {t("passwordChangedSuccess")}
            </Typography>
        </Stack>
    );
}