import React, { useEffect, useRef } from 'react';
import { useTranslation } from "react-i18next";

import { 
    Box, 
    Checkbox, 
    Container, 
    FormControlLabel, 
    Stack, 
    styled, 
    Typography,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    useMediaQuery,
    useTheme
 } from '@mui/material';

import QVContainer from '../../QVContainer';
import PrimaryButton from '../../core/PrimaryButton';

import Logo from '../../Logo';

const DIALOG_DISSMISSED_ESCAPE_KEY_DOWN = "escapeKeyDown";
const DIALOG_DISSMISSED_BACKDROP_CLICK = "backdropClick";

const StyledQVContainer = styled(QVContainer)(({ theme }) => ({
    border: "none",
    padding: theme.spacing(2)
}));

const GreyDialogContent = styled(DialogContent)(({ theme }) => ({
    backgroundColor: theme.palette.appBarBGColor.main,
    paddingBottom: theme.spacing(0),
}));

const GreyCenteredDialogActions = styled(DialogActions)(({ theme }) => ({
    backgroundColor: theme.palette.appBarBGColor.main,
    padding: theme.spacing(2, 3),
    paddingTop: theme.spacing(0),
    justifyContent: "center"
}));

export default function FirstLoginPopupDialogBase(props) {
    const { t } = useTranslation();
    const { children, title, open, setOpen, showActionBar = false, showAcceptTermsSection = true, dismissOnBackdropClick = false, dismissOnEscapeKey = false, maxWidth = "lg", modal = false, showLogo = false, primaryButtonLabel = t("primaryButtonLabel") } = props;
    const [checked, setChecked] = React.useState(false);

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const handleChange = (event) => {
        setChecked(event.target.checked);
    };

    const handleClose = (event, reason) => {
        if (modal && reason && ((reason === DIALOG_DISSMISSED_ESCAPE_KEY_DOWN && !dismissOnEscapeKey) || (reason === DIALOG_DISSMISSED_BACKDROP_CLICK && !dismissOnBackdropClick) ))  return;

        setOpen(checked);
    };

    const descriptionElementRef = useRef(null);
    useEffect(() => {
        if (open) {
            const { current: descriptionElement } = descriptionElementRef;
            if (descriptionElement !== null) {
                descriptionElement.focus();
            }
        }
    }, [open]);

    const stackDirection = isMobile ? "column" : "row";
    const logoPosition = isMobile ? "relative" : "absolute";
    const titleMargin = isMobile ? "20px auto" : "0px auto";

    return (
            <Dialog
                open={open}
                onClose={handleClose}
                scroll="paper"
                maxWidth={maxWidth}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
            >
                <DialogTitle sx={{display: "flex"}} id="scroll-dialog-title">
                    <Stack direction={stackDirection} sx={{position: 'relative'}} alignItems="center" width={1}>
                        { showLogo && <Logo sx={{position: logoPosition}} /> }

                        <Box margin={titleMargin}>
                            <Typography component="h1" variant="h5">{title}</Typography>
                        </Box>
                    </Stack>
                </DialogTitle>
                <GreyDialogContent dividers={false}>
                    <StyledQVContainer>
                        { children }
                    </StyledQVContainer>
                </GreyDialogContent>
                { showActionBar && 
                    <GreyCenteredDialogActions>
                        <Container sx={{pt: 2}} maxWidth="md">
                            <Stack direction="column" spacing={2}>
                            { showAcceptTermsSection && <FormControlLabel 
                                    control={ <Checkbox checked={checked} onChange={handleChange} color="primary" sx={{color: "primary.main"}} name="acceptTerms" value="yes" /> }
                                    label={ t("firstLogin.doNotShowAgainCheckboxMessage") }
                                    componentsProps={{
                                        typography: {
                                            padding: "7px",
                                            color: "text.secondary",
                                            variant: "body1"
                                        }
                                    }}
                                /> }
                                <Box sx={{width: 1, display: "flex", justifyContent: "center"}}>
                                    <PrimaryButton label={primaryButtonLabel} onClick={handleClose} />
                                </Box>
                            </Stack>
                        </Container>
                    </GreyCenteredDialogActions> }
            </Dialog>
    );
}