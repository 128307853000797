import React, { useState } from 'react';
import dayjs from 'dayjs';
import { useTranslation } from "react-i18next";

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopTimePicker } from '@mui/x-date-pickers/DesktopTimePicker';

import QVActionBarWithTime from './QVActionBarWithTime';

import DateTimeFormatConfig from '../../../utils/DateTimeFormatConfig';

export default function TimeInput(props) {
    const { value, setValue, onTimeError, error: outsideError, isStartDay, openingHours, recurrenceDay, disabled } = props;
    const { t } = useTranslation();

    const [open, setOpen] = useState(false);

    // since the views prop of TimePicker need to be non empty, we need to hide the action bar, and render minutes view instead as QVActionBarWithTime
    let component = {
        actionBar: () => <div></div>,
    };

    const selectedTime = dayjs(value, DateTimeFormatConfig.timeOnlyFormat);

    const onTimeChanged = (hours, minutes) => {
        const newValue = selectedTime.hour(hours).minute(minutes);
        setValue(DateTimeFormatConfig.formatDateToTimeOnlyString(newValue));
    }

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="de">
            <DesktopTimePicker
                open={open}
                onOpen={() => setOpen(true)}
                onClose={() => setOpen(false)}
                disabled={disabled}
                label={t("sidebar.tab.recurring.startDay.input.label")}
                value={dayjs(value, DateTimeFormatConfig.timeOnlyFormat)}
                mask="__:__"
                minutesStep={15}
                inputFormat={DateTimeFormatConfig.timeOnlyFormat}
                ampm={false}
                onError={onTimeError}
                onChange={(newValue) => {
                    setValue(DateTimeFormatConfig.formatDateToTimeOnlyString(newValue));
                }}
                views={['minutes']}
                viewRenderers={{
                    minutes: () => 
                        <QVActionBarWithTime
                            timePicker={true}
                            selectedDate={selectedTime.weekday(recurrenceDay - 1)}
                            minutes={selectedTime.hour() * 60 + selectedTime.minute()}
                            onTimeChanged={onTimeChanged}
                            isStartDatePicker={isStartDay}
                            openingTimes={openingHours}
                            onAccept={() => setOpen(false)}
                        />
                }}
                slots={component}
                slotProps={{
                    textField: {
                        sx: { input: { cursor: 'pointer' } },
                        readOnly: true,
                        error: outsideError,
                        onClick: (e) => !disabled && setOpen(!open)
                    }
                }}
            />
        </LocalizationProvider>
    );
}