import React, { Fragment } from 'react';
import DOMPurify from 'dompurify';

import { styled } from '@mui/material/styles';
import { Paper, Skeleton, Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material';

import { htmlDecode } from '../../../utils/HtmlDecode';

const OutlinedPaper = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(0),
    textAlign: 'left',
    color: theme.palette.text.secondary,
    boxShadow: "none",
    border: "1px solid "+theme.palette.divider,
    borderRadius: 8,
    // minWidth: 300, // Removed minWidth so that the cart spezies do not overflow on small screens
}));

export default function SpecificationsBox(props) {
    const { item, specifications } = props;

    const buildSpecificationsSkeleton = () => {
        return (
            <Fragment>
                <TableRow
                    key="spec_1"
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                    <TableCell component="th" scope="row"><Skeleton width={80} heigth={15}/></TableCell>
                    <TableCell><Skeleton width={90} heigth={15}/></TableCell>
                </TableRow>
                <TableRow
                    key="spec_2"
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                    <TableCell component="th" scope="row"><Skeleton width={50} heigth={15}/></TableCell>
                    <TableCell><Skeleton width={30} heigth={15}/></TableCell>
                </TableRow>
                <TableRow
                    key="spec_3"
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                    <TableCell component="th" scope="row"><Skeleton width={60} heigth={15}/></TableCell>
                    <TableCell><Skeleton width={40} heigth={15}/></TableCell>
                </TableRow>
            </Fragment>
        )
    }
    
    const buildSpecificationsBoxItem = (specification) => {
        return (
            <TableRow
                key={item.id+"_"+specification.attribute+"_"+specification.value}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
                <TableCell component="th" scope="row" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(htmlDecode(specification.attribute)) }} />
                <TableCell dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(htmlDecode(specification.value)) }} />
            </TableRow>
        )
    }

    return (
        <TableContainer component={OutlinedPaper} >
            <Table aria-label="a dense table">
                <TableBody>
                    { item ? specifications.map(buildSpecificationsBoxItem) : buildSpecificationsSkeleton() }
                </TableBody>
            </Table>
        </TableContainer>
    );
};