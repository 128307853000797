import React from 'react';
import { useSelector } from 'react-redux';
import { Trans } from "react-i18next";

import { Checkbox, FormControlLabel, Link } from '@mui/material';

import { selectDataPrivacyLink, selectTermsAndConditionsLink } from '../../../store/settingsSlice';

export default function AcceptTermsSection(props) {
    const { accepted, onAgreeChanged, error } = props;

    const dataPrivacyLink = useSelector(selectDataPrivacyLink);
    const termsAndConditionsLink = useSelector(selectTermsAndConditionsLink);

    return (
        <FormControlLabel 
            control={
                <Checkbox 
                    required
                    checked={accepted}
                    onChange={onAgreeChanged}
                    color="primary" name="acceptTerms" value="yes" 
                    sx={{color: "primary.main"}} />
            }
            label={
                <Trans
                    i18nKey="gdpr_policy_links"
                    components={{
                        link1: <Link href={dataPrivacyLink}>Link1</Link>,
                        link2: <Link href={termsAndConditionsLink}>Link2</Link>
                    }}
                />
            }
            componentsProps={{
                typography: {
                    padding: "7px",
                    color: error ? "error" : "text.secondary"
                }
            }}
        />
    );
};