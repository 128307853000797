import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from "react-i18next";

import { Box, Container, CssBaseline, Stack, Typography } from '@mui/material';

import { notScrollable, flexColFullHeightScrollable, p1 } from '../common/ui/sx/common';

import { usePageLayoutConfigurator } from '../common/hooks/usePageLayoutConfigurator';
import PageNames from '../common/utils/PageNames';
import PrimaryButton from '../common/ui/qvshop/components/core/PrimaryButton';

import { setRegisterStatusIdle } from '../common/store/registerSlice';

export default function RegisterFinished() {
    usePageLayoutConfigurator(PageNames.REGISTER_FINISHED);
    const { t } = useTranslation(['common', 'registerFinished']);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleRegisterFinishedAcknoledged = () => {
        // Set the make reservation status back to idle
        dispatch(setRegisterStatusIdle());
        navigate("/login");
    }

    return (
        <Container sx={{...flexColFullHeightScrollable, ...notScrollable, ...p1}} component="main" maxWidth="sm" >
            <CssBaseline />
            <Stack sx={{ minHeight: '100vh' }} direction="column" spacing={3} alignItems="center" justifyContent="center">
                <Typography variant="h5">{t("registerFinished:title")}</Typography>
                <Stack direction="column" spacing={2} alignItems="center">
                    <Stack direction="column" spacing={0} alignItems="center">
                        <Typography align="center" variant="body1">{t("registerFinished:text1")}</Typography>
                        <Typography align="center" variant="body1">{t("registerFinished:text2")}</Typography>
                    </Stack>
                    <Box>
                        <PrimaryButton label={t("registerFinished:button.toLogin")} onClick={handleRegisterFinishedAcknoledged} />
                    </Box>
                </Stack>
            </Stack>
        </Container>
    );
};