export default class RecurrenceDays {
    static WORKING_HOURS_KEY_FOR_ALL = 'all';
    static WORKING_HOURS_KEY_FOR_PICKUP = 'pickup';
    static WORKING_HOURS_KEY_FOR_DROPOFF = 'dropoff';

    static RECURRENCE_DAY_NONE = 0;
    static RECURRENCE_DAY_MONDAY = 1;
    static RECURRENCE_DAY_TUESDAY = 2;
    static RECURRENCE_DAY_WEDNESDAY = 3;
    static RECURRENCE_DAY_THURSDAY = 4;
    static RECURRENCE_DAY_FRIDAY = 5;
    static RECURRENCE_DAY_SATURDAY = 6;
    static RECURRENCE_DAY_SUNDAY = 7;
}