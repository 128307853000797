import React from 'react';
import Zoom from 'react-medium-image-zoom'

import { ImageListItem } from '@mui/material';

import ShopConfig from '../../../api/config/shopConfig';
import QVContainer from './QVContainer';

export default function MasonryImageItem(props) {
    const { item } = props;
    
    return (
        <ImageListItem key={item}>
            <Zoom zoomMargin={20} zoomImg={{src: ShopConfig.config().getURLForZoomedProductImage(item)}}>
                <QVContainer borderRadius={12} padding={0}
                    component="img"
                    width="100%"
                    alt="product.name"
                    src={ShopConfig.config().getURLForProductImage(item)}
                />
            </Zoom>
        </ImageListItem>
    )
}