import React from 'react';
import { useTranslation } from 'react-i18next';
import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'
import he from 'he';

import { IconButton, Typography, styled } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import CancelIcon from '@mui/icons-material/Cancel';

import QVContainer from '../../common/ui/qvshop/components/QVContainer';

import SpecificationsBox from '../../common/ui/qvshop/components/SpecificationsBox';
import ReservationSection from '../../common/ui/qvshop/components/ReservationSection';

import ShopConfig from '../../common/api/config/shopConfig';
import { updateCartItem, deleteCartItem } from '../../common/store/cartSlice';
import { useDispatch } from 'react-redux';
import HeaderWithCloseButton from './HeaderWithCloseButton';
import Interval from './Interval';

const StyledQVContainer = styled(QVContainer)(({ theme }) => ({
    overflowY: 'unset',
    position: "relative",
    '> .MuiIconButton-root': {
        position: 'absolute',
        right: "-24px",
        top: "-24px",
    }
}));

export default function ProductCard(props) {
    const dispatch = useDispatch();
    const { t } = useTranslation(['cart']);
    const { item, subarticle, loadingAvailability } = props;

    const hasImage = item.image && item.image !== "_dummy.svg";
    //const hasImage = item.image && item.image !== "_dummy.svg";

    const name = he.decode(item.name) + (subarticle?.id ? " (" + subarticle?.prefix_or_gnr + "-" + subarticle?.number + ")" : "");
    const attributes = (subarticle?.id ? subarticle?.attributes : item?.attributes);
    const occurence = (subarticle?.id ? subarticle?.occurence : item?.occurence);
    const identifier = item?.product_id;

    const handleRemoveFromCart = () => {
        dispatch(deleteCartItem({item, subarticle}));
    }

    const handleCartItemQuantityChanged = (newValue) => {
        dispatch(updateCartItem({item, subarticle, field: "quantity", value: newValue}));
    }

    return (
        <StyledQVContainer>
            <IconButton size="large" onClick={handleRemoveFromCart}>
                <CancelIcon color="primary" fontSize="inherit"/>
            </IconButton>
            <Grid container sx={{ px: 1, py: 1 }} spacing={2}>
                <HeaderWithCloseButton title={name} to={"/itemdetails/"+identifier} />
                <Grid container xs={12} >
                    <Grid container spacing={2}>
                        { hasImage &&
                            <Grid xs={12} sm={3} >
                                <Zoom zoomMargin={20} zoomImg={{src: ShopConfig.config().getURLForZoomedProductImage(item.image)}}>
                                    <QVContainer borderRadius={12} padding={0}
                                        component="img"
                                        width="100%"
                                        alt="product.name"
                                        src={ShopConfig.config().getURLForProductImage(item.image)}
                                    />
                                </Zoom>
                            </Grid> 
                        }
                        { attributes && attributes.length > 0 && 
                            <Grid xs={12} sm={hasImage ? 9 : 12} >
                                <SpecificationsBox item={item} specifications={ attributes ?? [] }/>
                            </Grid> 
                        }
                    </Grid>
                    <Grid xs={12} >
                        <ReservationSection loadingAvailability={loadingAvailability} item={item} subarticle={subarticle} isCartItem showQuantity={!(subarticle && subarticle.id)} onChangeQuantity={handleCartItemQuantityChanged} />
                    </Grid>
                    { occurence && occurence.length > 0 ?
                        <Grid container xs={12} sm={7} md={8} lg={10} xl={9}>
                            <Grid container xs={12} rowSpacing={0} columnSpacing={3}>
                                <Grid xs={6}>
                                    <Typography variant="body2" color="primary">{t("intervals.from")}</Typography>
                                </Grid>
                                <Grid xs={6}>
                                    <Typography variant="body2" color="primary">{t("intervals.until")}</Typography>
                                </Grid>
                            </Grid>
                            { occurence.map(interval => (<Interval key={interval.startDate+"_"+interval.endDate} interval={interval} />)) }
                        </Grid>
                        : null
                    }
                </Grid>
            </Grid>
        </StyledQVContainer>
    );
}