import React from 'react';
import { useTranslation } from "react-i18next";

import { List, ListSubheader, Stack, Typography } from '@mui/material';
import PlaceIcon from '@mui/icons-material/Place';

import SelectLocation from '../../SelectLocation';

export default function LocationSelectSection() {
    const { t } = useTranslation();

    return (
        <List component="nav"
            aria-labelledby="subheader-location"
            subheader={
                <ListSubheader component="div" id="subheader-location" disableSticky={true}>
                    <Stack alignItems="center" direction="row" spacing={1}>
                        <Typography variant="headline" component="h5">{t("sidebar.location")}</Typography>
                        <PlaceIcon color="primary" />
                    </Stack>
                </ListSubheader>
            }>
            <SelectLocation sx={{ width: "100%" }} />
        </List>
    );
}