import React from 'react';

import { Paper } from '@mui/material';
import { styled } from '@mui/material/styles';

const QVPaperStyled = styled(Paper, {
        shouldForwardProp: (prop) => prop !== 'borderRadius' && prop !== 'padding',
        name: 'QVContainer',
        slot: 'Root',
    }) (({ borderRadius = false, padding, theme, flexDirection }) => ({
        borderRadius: 8,
        padding: padding ?? theme.spacing(1),
        margin: theme.spacing(0),
        ...(borderRadius && {
            borderRadius: borderRadius,
        }),
        ...(padding && {
            padding: padding,
        }),
        border: "1px solid "+theme.palette.divider,
        flex: "1 1 auto",
        boxShadow: "none",
        display: "flex",
        ...(flexDirection && {
            flexDirection: flexDirection,
        })
}));

export default function QVContainer(props) {
    return (
        <QVPaperStyled {...props}>
            {props.children}
        </QVPaperStyled>
    );
}