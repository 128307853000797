import React from 'react';
import { useSelector } from 'react-redux';
import {
    Navigate,
    useLocation,
} from 'react-router-dom';

import { useAuth } from "../../common/hooks/useAuth";

import { selectAllowNavigationFromLogin } from '../../common/store/locationsSlice';

export const PublicRoute = ({ children }) => {
    const { token } = useAuth();
    const location = useLocation();

    const allowNavigationFromLogin = useSelector(selectAllowNavigationFromLogin);
    
    if (token && allowNavigationFromLogin) {
        const origin = location.state?.from?.pathname || "/items/";
        return <Navigate to={origin} replace state={{ from: location }} />
    }

    return children;
};