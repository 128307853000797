import RecurrenceDays from "../../ui/qvshop/components/recurringIntervalSelector/utils/RecurrenceDays";
import DateTimeFormatConfig from "../../utils/DateTimeFormatConfig";
import WorkingDays from "../../utils/WorkingDays";

export class ProductUtils {

    static formatPrice(price) {
        return price.toFixed(2).replace(".", ",");
    }

    static updateAvailability(payload, product) {
        if(product.isConfigurable && product.subarticles) {
            for (const [id, subarticle] of Object.entries(product.subarticles)) {
                product.subarticles[id] = {...subarticle, available: payload[product.product_id].subarticles[id]['available']}

                // if(payload[product.product_id].subarticles[id]['availability_per_days']) {
                //     product.subarticles[id].from_availability_calendar[id] = {...product.subarticles[id].from_availability_calendar[id], ...payload[product.product_id].subarticles[id]['availability_per_days']};
                //     product.subarticles[id].to_availability_calendar[id] = {...product.subarticles[id].to_availability_calendar[id], ...payload[product.product_id].subarticles[id]['availability_per_days']};
                // }
            }
        } else {
            product.available = payload[product.product_id]['available'];

            // if((payload[product.product_id] && payload[product.product_id]['availability_per_days']) && (product.from_availability_calendar && product.from_availability_calendar[product.product_id])) {
            //     product.from_availability_calendar[product.product_id] = {...product.from_availability_calendar[product.product_id], ...payload[product.product_id]['availability_per_days']};
            //     product.to_availability_calendar[product.product_id] = {...product.to_availability_calendar[product.product_id], ...payload[product.product_id]['availability_per_days']};
            // }
        }
        return product;
    }

    static updateAvailabilityCalendarData(calendarData, product, field) {
        const calendarDataForId = Object.keys(calendarData)[0];
        if(product.product_id === calendarDataForId) {
            if(product.isConfigurable && product.subarticles) {
                for (const [id, subarticle] of Object.entries(product.subarticles)) {
                    if(calendarData[product.product_id][id]) {
                        product.subarticles[id][field][id] = {...product.subarticles[id][field][id], ...calendarData[product.product_id][id]}
                    }
                }
            } else {
                product[field][product.product_id] = {...product[field][product.product_id], ...calendarData[product.product_id]};
            }
        }
        return product;
    }

    static updateOpeningTimesDataForEntity(changedEntity, state, field) {
        let recurring = changedEntity?.recurring ?? state.filters.recurring;
        if(recurring) {
            const recurrenceStartDay = changedEntity?.recurrenceStartDay ?? state.filters.recurrenceStartDay;
            const recurrenceEndDay = changedEntity?.recurrenceEndDay ?? state.filters.recurrenceEndDay;

            const startDay = DateTimeFormatConfig.getNextDateForWeekday(recurrenceStartDay);
            const endDay = DateTimeFormatConfig.getNextDateForWeekday(recurrenceEndDay);

            if((field === 'recurrenceStartDay' || field === 'recurring') && recurrenceStartDay !== 0) {
                const [openingTimesForDate, openingTime] = WorkingDays.prepareTimeSliderDataForDay(state.settings.settings.working_hours, state.settings.settings.semester_settings, startDay, RecurrenceDays.WORKING_HOURS_KEY_FOR_PICKUP, true);
                changedEntity.openingTimesForStartDate = openingTimesForDate;
                changedEntity.startTime = openingTime;
            }

            if((field === 'recurrenceEndDay' || field === 'recurring') && recurrenceEndDay !== 0) {
                const [openingTimesForDate, openingTime] = WorkingDays.prepareTimeSliderDataForDay(state.settings.settings.working_hours, state.settings.settings.semester_settings, endDay, RecurrenceDays.WORKING_HOURS_KEY_FOR_DROPOFF, true);
                changedEntity.openingTimesForEndDate = openingTimesForDate;
                changedEntity.endTime = openingTime;
            }
        } else {
            const startDate = DateTimeFormatConfig.getDateFromStandardPickerDateFormat(changedEntity?.startDate ?? state.filters.startDate);
            const endDate = DateTimeFormatConfig.getDateFromStandardPickerDateFormat(changedEntity?.endDate ?? state.filters.endDate);

            changedEntity.openingTimesForStartDate = WorkingDays.prepareTimeSliderDataForDay(state.settings.settings.working_hours, state.settings.settings.semester_settings, startDate, RecurrenceDays.WORKING_HOURS_KEY_FOR_PICKUP);
            changedEntity.openingTimesForEndDate = WorkingDays.prepareTimeSliderDataForDay(state.settings.settings.working_hours, state.settings.settings.semester_settings, endDate, RecurrenceDays.WORKING_HOURS_KEY_FOR_DROPOFF);
        }

        return {...changedEntity};
    }
}