//import * as moment from "moment";
import dayjs from 'dayjs';
var customParseFormat = require('dayjs/plugin/customParseFormat')
dayjs.extend(customParseFormat);

class DateTimeFormatConfig {
	static dateOnlyPickerFormat = "DD.MM.YYYY";
	static dateTimePickerFormat = "DD.MM.YYYY HH:mm";

	static dateOnlyPickerFormatMobile = "DD.MM.YY";
	static dateTimePickerFormatMobile = "DD.MM.YY HH:mm";

	static dateOnlyServerFormat = "YYYY-MM-DD";
	static dateTimeServerFormat = "YYYY-MM-DD HH:mm";

	static timeOnlyFormat = "HH:mm";

	// For now keep it here  - might need to get this from the server later on
	static enableHours() {
		// let enableHours = "noTime";
		let enableHours = "";
		return enableHours === 'noTime' ? false : true;
	}

	static getMobilePickerDateFormat(withTime = true) {
		return DateTimeFormatConfig.enableHours() && withTime ? DateTimeFormatConfig.dateTimePickerFormatMobile : DateTimeFormatConfig.dateOnlyPickerFormatMobile;
	}

	static getPickerDateFormat(withTime = true) {
		return DateTimeFormatConfig.enableHours() && withTime ? DateTimeFormatConfig.dateTimePickerFormat : DateTimeFormatConfig.dateOnlyPickerFormat;
	}

	static getServerDateFormat() {
		return DateTimeFormatConfig.enableHours() ? DateTimeFormatConfig.dateTimeServerFormat : DateTimeFormatConfig.dateOnlyServerFormat;
	}

	static standardServerDateFormat(date) {
		let pickerDateTimeFormat = DateTimeFormatConfig.getPickerDateFormat();
		let serverDateTimeFormat  = DateTimeFormatConfig.getServerDateFormat();
		let pickerDateOnlyFormat = DateTimeFormatConfig.dateOnlyPickerFormat;

		const possibleDateFormats = [pickerDateTimeFormat, pickerDateOnlyFormat, serverDateTimeFormat];
		return dayjs(date, possibleDateFormats).format(serverDateTimeFormat);
	}

	static standardPickerDateFormat(date) {
		let pickerDateTimeFormat = DateTimeFormatConfig.getPickerDateFormat();
		let serverDateTimeFormat  = DateTimeFormatConfig.getServerDateFormat();

        const possibleDateFormats = [pickerDateTimeFormat, serverDateTimeFormat];
		return dayjs(date, possibleDateFormats).format(pickerDateTimeFormat);
	}

	static standardPickerDateOnlyFormat(date) {
		let pickerDateOnlyFormat = DateTimeFormatConfig.dateOnlyPickerFormat;
        let serverDateOnlyFormat  = DateTimeFormatConfig.dateOnlyServerFormat;

        const possibleDateFormats = [pickerDateOnlyFormat, serverDateOnlyFormat];
		return dayjs(date, possibleDateFormats).format(pickerDateOnlyFormat);
	}

	static standardPickerTimeFormat(date) {
		let pickersDateTimeFormat = DateTimeFormatConfig.enableHours() ? "HH:" : DateTimeFormatConfig.dateOnlyPickerFormat;

		let newTime = dayjs(date).format(pickersDateTimeFormat) + (DateTimeFormatConfig.enableHours() ? "00" : "");
		return newTime;
	}


	// Get date-times from formatted strings
	static getDateFromStandardPickerDateFormat(dateStr, withTime = true) {
		let pickerDateTimeFormat = DateTimeFormatConfig.getPickerDateFormat(withTime);
        let serverDateTimeFormat  = DateTimeFormatConfig.getServerDateFormat(withTime);
		let pickerDateOnlyFormat = DateTimeFormatConfig.dateOnlyPickerFormat;

        const possibleDateFormats = [pickerDateTimeFormat, serverDateTimeFormat, pickerDateOnlyFormat];
		return dayjs(dateStr, possibleDateFormats);
	}

	static getDateFromDateOnlyString(dateStr) {
		let pickerDateTimeFormat = DateTimeFormatConfig.dateOnlyPickerFormat;
        let serverDateTimeFormat  = DateTimeFormatConfig.dateOnlyServerFormat;

        const possibleDateFormats = [pickerDateTimeFormat, serverDateTimeFormat];
		return dayjs(dateStr, possibleDateFormats);
	}



	// Get formatted date strings from date objects
	static formatDateToStandardPickerDateFormat(date) {
		return date.format(DateTimeFormatConfig.getPickerDateFormat());
	}

	static formatDateToDateOnlyString(date) {
		return date.format(DateTimeFormatConfig.dateOnlyPickerFormat);
	}
	
	static formatNowToStandardPickerDateFormat() {
		return dayjs().format(DateTimeFormatConfig.getPickerDateFormat());
	}
	
	static formatNowToStandardServerDateFormat() {
		return dayjs().format(DateTimeFormatConfig.getServerDateFormat());
	}

	static formatDateToTimeOnlyString(date) {
		return date.format(DateTimeFormatConfig.timeOnlyFormat);
	}

	static timeOnlyStringIsValid(dateStr) {
		return dayjs(dateStr, DateTimeFormatConfig.timeOnlyFormat).isValid();
	}

	static standardPickerTimeStringIsValid(dateStr) {
		return dayjs(dateStr, DateTimeFormatConfig.getPickerDateFormat()).isValid();
	}

	// Get formatted date strings from other formatted date strings
	static dateStringToDateOnlyFormat(date) {
		let pickerDateTimeFormat = DateTimeFormatConfig.getPickerDateFormat();
        let serverDateTimeFormat  = DateTimeFormatConfig.getServerDateFormat()

        const possibleDateFormats = [pickerDateTimeFormat, serverDateTimeFormat];
		return dayjs(date, possibleDateFormats).format(DateTimeFormatConfig.dateOnlyPickerFormat);
	}

	// 
	static getNextDateForWeekday(day) {
		return dayjs().weekday(day+6);
	}
}

export default DateTimeFormatConfig;
