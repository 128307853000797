import dayjs from 'dayjs';
import DateTimeFormatConfig from './DateTimeFormatConfig';
import WorkingDays from './WorkingDays';
import CheckWorkingHours from './CheckWorkingHours';
import RecurrenceDays from '../ui/qvshop/components/recurringIntervalSelector/utils/RecurrenceDays';

var isSameOrBefore = require('dayjs/plugin/isSameOrBefore')
dayjs.extend(isSameOrBefore);

var isBetween = require('dayjs/plugin/isBetween')
dayjs.extend(isBetween);

export class ShopDatesInterval {
    static get(workingHours, semesterSettings, recurring, semSize, currentStartDate, currentEndDate) {
        let startDate = currentStartDate;
        let startDateObj = DateTimeFormatConfig.getDateFromStandardPickerDateFormat(startDate);
        
        if(startDate == null || startDateObj.isBefore(dayjs()) || !ShopDatesInterval.isDateAvailable(workingHours, semesterSettings, startDateObj, RecurrenceDays.WORKING_HOURS_KEY_FOR_PICKUP)) {
            let defaultStartDateTime = ShopDatesInterval.getNextQuaterOfHour(false);
            startDate = DateTimeFormatConfig.formatDateToStandardPickerDateFormat(defaultStartDateTime);

            // ANDY - 04.04.2023 - Move thie next two if's statements otside this if block if you want the start date to change 
            // immediately if you enter manually a date that is not allowed
            if(!recurring || parseInt(semSize) === 1) { // non recurring or recurring VON-BIS
                let calculatedStartDate = ShopDatesInterval.getNextAvailableDate(workingHours, semesterSettings, startDate, RecurrenceDays.WORKING_HOURS_KEY_FOR_PICKUP);
                if(calculatedStartDate) {
                    startDate = calculatedStartDate;
                }
            }

            if(recurring) {
                startDate = DateTimeFormatConfig.dateStringToDateOnlyFormat(startDate);
            }
        }
        
        let endDate = currentEndDate;
        // If end date is null or before or equal to start date - recalculate end date
        if(endDate == null || DateTimeFormatConfig.standardServerDateFormat(endDate) <= DateTimeFormatConfig.standardServerDateFormat(startDate)) {
            let startdateForCalculation = DateTimeFormatConfig.standardServerDateFormat(startDate);

            endDate = dayjs(startdateForCalculation).add(120, 'm');
            endDate = DateTimeFormatConfig.standardPickerDateFormat(endDate);

            if(!recurring || parseInt(semSize) === 1) {
                let calculatedEndDate = ShopDatesInterval.getNextAvailableDate(workingHours, semesterSettings, endDate, RecurrenceDays.WORKING_HOURS_KEY_FOR_DROPOFF);
                if(calculatedEndDate) {
                    endDate = calculatedEndDate;
                }
            }
            
            if(recurring && parseInt(semSize) !== 1) {
                // If selected semester size is not "VON-BIS" get the semester length
                let semesterDates = WorkingDays.get_semester_interval_for_selection(semesterSettings, semSize);
                endDate = DateTimeFormatConfig.dateStringToDateOnlyFormat(semesterDates.endDate);
            }
        }

        // console.log("ShopDatesInterval:-:Dates: ", {
        //     startDate: startDate,
        //     endDate: endDate
        // });

        return {
            startDate: startDate,
            endDate: endDate
        };
    }

    static getNextQuaterOfHour(returnAsTime = true) {
        const minutesToRoundTo = 15;
    
        const now = dayjs();
        const remainder = minutesToRoundTo - (now.minute() % minutesToRoundTo);
     
        const nextRoundedHour = now.add(remainder, "minute");
        return returnAsTime ? nextRoundedHour.format('HH:mm') : nextRoundedHour;
    }

    static fromToDatesHaveErrors(recurring, startDate, endDate, workingHours, semesterSettings) {
        return recurring === 0 
            && !(DateTimeFormatConfig.standardPickerTimeStringIsValid(startDate)
                && DateTimeFormatConfig.standardPickerTimeStringIsValid(endDate)
                && ShopDatesInterval.isDateAvailable(workingHours, semesterSettings, DateTimeFormatConfig.getDateFromStandardPickerDateFormat(startDate), RecurrenceDays.WORKING_HOURS_KEY_FOR_PICKUP)
                && ShopDatesInterval.isDateAvailable(workingHours, semesterSettings, DateTimeFormatConfig.getDateFromStandardPickerDateFormat(endDate), RecurrenceDays.WORKING_HOURS_KEY_FOR_DROPOFF)
                && DateTimeFormatConfig.standardServerDateFormat(endDate) > DateTimeFormatConfig.standardServerDateFormat(startDate)
                );
    }

    static recurrenceHasErrors(recurring, repetitionInterval, recurrenceStartDay, recurrenceEndDay, startTime, endTime, startTimeError, endTimeError, outsideWorkingHoursPickup, outsideWorkingHoursDropoff) {
        return recurring === 1
            && (repetitionInterval === 0 
                || recurrenceStartDay === 0 
                || recurrenceEndDay === 0 
                || !DateTimeFormatConfig.timeOnlyStringIsValid(startTime) 
                || !DateTimeFormatConfig.timeOnlyStringIsValid(endTime) 
                || startTimeError 
                || endTimeError 
                || outsideWorkingHoursPickup 
                || outsideWorkingHoursDropoff
                );
    }

    static getNextAvailableDate(workingHours, semesterSettings, date = DateTimeFormatConfig.formatNowToStandardPickerDateFormat(), pickupDropoff) {
        let dateTimeToCheck = DateTimeFormatConfig.getDateFromStandardPickerDateFormat(date);
        
        let availableTime = ShopDatesInterval.getAvailableTimeForDate(workingHours, semesterSettings, dateTimeToCheck, pickupDropoff);
        
        if(availableTime){
            let dateToReturn = DateTimeFormatConfig.formatDateToDateOnlyString(dateTimeToCheck);
            let dateTimeToReturn = dateToReturn+", "+availableTime;
            
            return dateTimeToReturn;
        } else {
            // For next days start with time 00:00
            dateTimeToCheck = DateTimeFormatConfig.getDateFromStandardPickerDateFormat(date).startOf('d');
            
            for (let i = 1; i <= 7; i++) {
                dateTimeToCheck = dateTimeToCheck.add(1, "d");

                let availableTime = ShopDatesInterval.getAvailableTimeForDate(workingHours, semesterSettings, dateTimeToCheck, pickupDropoff);
        
                if(availableTime) {
                    let dateToReturn = DateTimeFormatConfig.formatDateToDateOnlyString(dateTimeToCheck);
                    let dateTimeToReturn = dateToReturn+", "+availableTime;
                    return dateTimeToReturn;
                }
            }
        }

        return false;
    }

    static getAvailableTimeForDate(workingHours, semesterSettings, dateTimeToCheck, pickupDropoff) {
        const workingHoursForDate = WorkingDays.getWorkingHoursForDay(workingHours, semesterSettings, dateTimeToCheck, pickupDropoff);

        let openingTime = dayjs(workingHoursForDate['von1'], 'HH:mm', true);
        let closingTime = dayjs(workingHoursForDate['bis1'], 'HH:mm', true);

        let openingTime2 = dayjs(workingHoursForDate['von2'], 'HH:mm', true);
        let closingTime2 = dayjs(workingHoursForDate['bis2'], 'HH:mm', true);

        let timeToCheck = dayjs(dateTimeToCheck.format("HH:mm"), 'HH:mm', true);

        let closestOpenTime = false;

        // Time to check is before opening time - set opening time
        if(openingTime.isValid() && timeToCheck.isSameOrBefore(openingTime)) {
            closestOpenTime = openingTime.format("HH:mm");
            return closestOpenTime;
        }

        // Time to check is between opening time - set checked time
        if ((openingTime.isValid() && closingTime.isValid() && timeToCheck.isBetween(openingTime, closingTime, null, '[]')) || 
            (openingTime2.isValid() && closingTime2.isValid() && timeToCheck.isBetween(openingTime2, closingTime2, null, '[]'))) {
            closestOpenTime = timeToCheck.format("HH:mm");
            return closestOpenTime;
        }

        // Time to check is before opening time2 - set opening time2
        if(openingTime2.isValid() && timeToCheck.isSameOrBefore(openingTime2)) {
            closestOpenTime = openingTime2.format("HH:mm");
            return closestOpenTime;
        }

        return closestOpenTime;
    }

    static isDateAvailable(workingHours, semesterSettings, dateTimeToCheck, pickupDropoff, withTime = true) {
        const workingHoursForDate = WorkingDays.getWorkingHoursForDay(workingHours, semesterSettings, dateTimeToCheck, pickupDropoff);

        let openingTime = dayjs(workingHoursForDate['von1'], 'HH:mm', true);
        let closingTime = dayjs(workingHoursForDate['bis1'], 'HH:mm', true);

        let openingTime2 = dayjs(workingHoursForDate['von2'], 'HH:mm', true);
        let closingTime2 = dayjs(workingHoursForDate['bis2'], 'HH:mm', true);

        let timeToCheck = dayjs(dateTimeToCheck.format("HH:mm"), 'HH:mm', true);

        let isAvailable = false;

        if(withTime) {
            // Time to check is between opening time - set checked time
            if ((openingTime.isValid() && closingTime.isValid() && timeToCheck.isBetween(openingTime, closingTime, null, '[]')) || 
                (openingTime2.isValid() && closingTime2.isValid() && timeToCheck.isBetween(openingTime2, closingTime2, null, '[]'))) {
                isAvailable = true;
            }
        } else {
            if ((openingTime.isValid() && closingTime.isValid()) || 
                (openingTime2.isValid() && closingTime2.isValid())) {
                isAvailable = true;
            }
        }

        return isAvailable;
    }

    static getStartDateEndDateForRecurrenceType(semSize, startDate, endDate, semSettings) {
        let startDateSemester = startDate;
        let endDateSemester = endDate;

        if (parseInt(semSize) === 1) { // VON/BIS

            startDateSemester = startDate;
            endDateSemester = endDate;
        } else {
            let dates = WorkingDays.get_semester_interval_for_selection(semSettings, semSize);

            let semStartDate = DateTimeFormatConfig.getDateFromStandardPickerDateFormat(dates.startDate);
            let today = dayjs();
            
            if(semStartDate.isBefore(today, 'd')) {
                startDateSemester = DateTimeFormatConfig.formatDateToDateOnlyString(today);
                endDateSemester = DateTimeFormatConfig.dateStringToDateOnlyFormat(dates.endDate);
            } else {
                startDateSemester = DateTimeFormatConfig.dateStringToDateOnlyFormat(dates.startDate);
                endDateSemester = DateTimeFormatConfig.dateStringToDateOnlyFormat(dates.endDate);
            }
        }

        let semesterDates = {
            'startDate': startDateSemester,
            'endDate': endDateSemester,
        };

        // console.log("Semester dates: ", semesterDates);

        return semesterDates;
    }

    static setDaysForRental(wh, intervalOption, forPickupOrDropoff) {
        let disabledDays = [];
        disabledDays = WorkingDays.setDaysForRental(wh, intervalOption === 0 || intervalOption === 3 ? 'classes' : 'no_classes', forPickupOrDropoff);

        let days = [1, 2, 3, 4, 5, 6, 7];
        return this.common(disabledDays, days);
    }
    
    static common(arr1, arr2) {

        let newArr = [];

        newArr = arr1.filter(function (v) { return arr2.indexOf(v) >= 0; });
        newArr.concat(arr2.filter(function (v) { return newArr.indexOf(v) >= 0; }));

        return newArr;
    }
    
    static workingHoursCheck(von, bis, day, day2, workingDays = false) {
        let wdays = workingDays; // ? workingDays : this.workingDays;

        return CheckWorkingHours.workingHoursCheck(von, bis, day, day2, wdays);
    }

    static WHErrorText(day, day2, workingHoursCheckData, hasExtendedTimes, workingDays = false) {
        let wdays = workingDays; // ? workingDays : this.workingDays;

        return CheckWorkingHours.WHErrorText(day, day2, workingHoursCheckData, wdays, hasExtendedTimes);
    }

    static doWorkingHoursCheck(startTime, endTime, recurrenceStartDay, recurrenceEndDay, wh, hasExtendedTimes) {
        const workingHoursCheckData = this.workingHoursCheck(startTime, endTime, recurrenceStartDay, recurrenceEndDay, wh);
        const workingHoursErrorData = this.WHErrorText(recurrenceStartDay, recurrenceEndDay, workingHoursCheckData, hasExtendedTimes, wh);

        return {
            ...workingHoursCheckData,
            ...workingHoursErrorData
        }
    }

    static doItemWorkingHoursCheck(item, workingDays = false) {
        const inWorkingHours = this.workingHoursCheck(item.startTime, item.endTime, item.recurrenceStartDay, item.recurrenceEndDay, workingDays);
        const workingHoursText = this.WHErrorText(item.recurrenceStartDay, this.recurrenceEndDay, workingDays); 

        return {
            inWorkingHours: inWorkingHours,
            errorText: inWorkingHours ? "" : workingHoursText
        }       
    }

    // Probably not needed - done by mui time input
    static validateTime(time) {
        return CheckWorkingHours.validateTime(time);
    }

    static checkIfSelectedDayIsDisabled(forSelectedDay, disabledDays) {
        let isDisabled = false;

        if (disabledDays.indexOf(parseInt(forSelectedDay)) > -1) {
            isDisabled = true;
        }

        return isDisabled;
    }
}