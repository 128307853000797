import React from 'react';
import { useTranslation } from "react-i18next";

import { Stack, Typography } from '@mui/material';

import QVContainer from './QVContainer';
import { fullWidth } from '../../sx/common';

import ReservationSection from './ReservationSection';

export default function ArticleReservationSection(props) {
    const { t } = useTranslation();
    const { item, onChangeQuantity, showQuantity, showAddToCartButton, loadingAvailability } = props;

    return (
        <QVContainer borderRadius={8} sx={fullWidth}>
            <Stack sx={{m: 1}}  direction='column' spacing={3}>
                <Typography variant="h6">{t("booking")}</Typography>
                <ReservationSection loadingAvailability={loadingAvailability} item={item} showQuantity={showQuantity} onChangeQuantity={onChangeQuantity} showAddToCartButton={showAddToCartButton} />
            </Stack>
        </QVContainer>
    );
};