import React from 'react';

import { Box, Skeleton, Stack, Card } from '@mui/material';

export default function ProductCardSkeleton(props) {
    const { item } = props;

    return (
        <Card variant="outlined" sx={{ width: "100%", minWidth: 280 }}>
            <Stack direction="column" spacing={2} sx={{ width: "100%", minWidth: 280 }}>
                <Skeleton variant="rectangular" height={140} />
                <Box sx={{p: 2, pt: 0.5}} >
                    <Stack direction="column" spacing={3.2} >
                        <Skeleton variant="rectangular" height={30} width="70%"/>
                        <Stack direction="column" spacing={1} >
                            <Stack direction="row">
                                <Stack direction="row" width="50%">
                                    <Skeleton variant="rectangular" width={75} height={15} />
                                </Stack>
                                <Stack direction="row" width="50%">
                                    <Skeleton variant="rectangular" width={35} height={15} />
                                </Stack>
                            </Stack>
                            <Stack direction="row">
                                <Stack direction="row" width="50%">
                                    <Skeleton variant="rectangular" width={55} height={15} />
                                </Stack>
                                <Stack direction="row" width="50%">
                                    <Skeleton variant="rectangular" width={85} height={15} />
                                </Stack>
                            </Stack>
                            { (item % 2 === 0) && <Stack direction="row">
                                <Stack direction="row" width="50%">
                                    <Skeleton variant="rectangular" width={55} height={15} />
                                </Stack>
                                <Stack direction="row" width="50%">
                                    <Skeleton variant="rectangular" width={85} height={15} />
                                </Stack>
                            </Stack> }
                            { item === 2 && <Stack direction="row">
                                <Stack direction="row" width="50%">
                                    <Skeleton variant="rectangular" width={55} height={15} />
                                </Stack>
                                <Stack direction="row" width="50%">
                                    <Skeleton variant="rectangular" width={85} height={15} />
                                </Stack>
                            </Stack> }
                        </Stack>
                        <Stack direction="row" spacing={2} justifyContent="space-between">
                            <Skeleton variant="rectangular" width={70} height={20} />
                            <Skeleton variant="rectangular" width={130} height={20} />
                        </Stack>
                    </Stack>
                </Box>
            </Stack>
        </Card>
    );
}