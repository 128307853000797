import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Outlet } from "react-router-dom";

import { Box, Toolbar, CssBaseline } from '@mui/material';

import { flexRowFullWidth, flexColFullHeight, flexColFullHeightScrollable } from "../../sx/common";
import * as sx from './sx/MainLayoutSx';
import PageNames from '../../../utils/PageNames';

import ResponsiveDrawer from '../components/sidebar/ResponsiveDrawer';
import ShopAppBar from '../components/ShopAppBar';
import ScrollToTop from '../components/ScrollToTop';
import Search from '../components/Search';
import Footer from '../components/Footer';

import { selectActiveScreen } from '../../../store/appStateSlice';
import { selectShowSearch } from '../../../store/layoutSlice';

export default function MainLayout() {
    const showSearch = useSelector(selectShowSearch);
    const activeScreen = useSelector(selectActiveScreen);

    const [mobileOpen, setMobileOpen] = useState(false);

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const overridePadding = (activeScreen === PageNames.PRODUCTS_LIST) ? {pr: 1, pl: 1, pb: 0} : {};

    return (
        <Box sx={sx.mainContentContainer}>
            <CssBaseline />

            <ShopAppBar handleDrawerToggle={handleDrawerToggle} />

            <Box sx={flexRowFullWidth}>
                <ResponsiveDrawer handleDrawerToggle={handleDrawerToggle} mobileOpen={mobileOpen} />

                {/* this box is full height and holds the toolbar and the content - so that only the content is scrollable */}
                <Box sx={flexColFullHeight}>
                    <Toolbar />

                    {showSearch &&
                        <Search />}

                    {/* this box is full height without the toolbar and holds the content - is scrollable */}
                    <Box id="main_content" sx={{ ...flexColFullHeightScrollable, ...sx.mainContent, ...overridePadding }}>
                        <ScrollToTop />

                        <Outlet />

                        <Footer />
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};