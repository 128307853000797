import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from "react-i18next";

import { Box } from '@mui/material';

import TextField from '../../common/ui/qvshop/components/TextField';
import PrimaryButton from '../../common/ui/qvshop/components/core/PrimaryButton';

import { requestPasswordResetCode, selectEmailUsername, selectEmailUsernameError, selectEmailUsernameErrorCode, setEmailUsername, setEmailUsernameError } from '../../common/store/passwordForgottenSlice';

export default function IdentifyUser() {
    const { t } = useTranslation(['passwordForgotten']);
    const dispatch = useDispatch();

    const emailUsername = useSelector(selectEmailUsername);
    const emailUsernameError = useSelector(selectEmailUsernameError);
    const emailUsernameErrorCode = useSelector(selectEmailUsernameErrorCode);

    const changeEmailUsername = (event) => {
        const value = event.target.value;
        dispatch(setEmailUsername(value));
    }

    const handleSubmit = async (event) => {
        event.preventDefault();

        let formHasErrors = false;
        if(emailUsername === '') {
            dispatch(setEmailUsernameError(true));
            formHasErrors = true;
        } else {
            dispatch(setEmailUsernameError(false));
        }

        if(!formHasErrors) {
            dispatch(requestPasswordResetCode());
        } else {
            console.log("Email/Username empty!");
        }
    };

    return (
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            <TextField
                error={emailUsernameError}
                errorTitle=""
                errorText={emailUsernameError && emailUsernameErrorCode !== '' ? t("emailUsernameError."+emailUsernameErrorCode) : ""}
                margin="normal"
                required
                fullWidth
                id="emailUsername"
                label={t("inputLabel.emailOrUsername")}
                name="emailUsername"
                autoComplete="emailUsername"
                autoFocus
                value={emailUsername}
                onChange={changeEmailUsername}
            />
            <PrimaryButton type="submit" fullWidth sx={{ mt: 3, mb: 2 }} label={t("button.requestCode")} />
        </Box>
    );
}