import React from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from "react-i18next";

import { IconButton, Typography, styled } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import CancelIcon from '@mui/icons-material/Cancel';

import QVContainer from '../../common/ui/qvshop/components/QVContainer';
import Interval from './Interval';
import ReturnKeySVG from "../resources/return_key.svg";
import HeaderWithCloseButton from './HeaderWithCloseButton';

import { removeReturnKeyFromCart } from '../../common/store/cartSlice';

const StyledQVContainer = styled(QVContainer)(({ theme }) => ({
    overflowY: 'unset',
    position: "relative",
    '> .MuiIconButton-root': {
        position: 'absolute',
        right: "-24px",
        top: "-24px",
    }
}));

export default function ReturnKeyCard(props) {
    const dispatch = useDispatch();
    const { t } = useTranslation(['cart']);
    const { returnKey } = props; 

    const showCloseButton = returnKey.returnKeyAddedByUser;

    const handleRemoveKeyFromCart = () => {
        dispatch(removeReturnKeyFromCart());
    }

    return (
        <StyledQVContainer sx={{p: 2}} >
            { showCloseButton && <IconButton size="large" onClick={handleRemoveKeyFromCart}>
                <CancelIcon color="primary" fontSize="inherit"/>
            </IconButton> }
            <Grid container spacing={2}>
                <HeaderWithCloseButton title={t("returnKey")} subtitle={!returnKey.returnKeyAddedByUser ? t("returnKey.mandatoryKey") : false} />
                <Grid container xs={12} >
                    <Grid xs={12} sm={5} md={4} lg={2} xl={3}>
                        <QVContainer borderRadius={12} padding={0}
                            component="img"
                            width="100%"
                            alt="product.name"
                            src={ReturnKeySVG}
                        />
                    </Grid>
                    <Grid container xs={12} sm={7} md={8} lg={10} xl={9}>
                        <Grid container xs={12} rowSpacing={0} columnSpacing={3}>
                            <Grid xs={6}>
                                <Typography variant="body2" color="primary">{t("intervals.from")}</Typography>
                            </Grid>
                            <Grid xs={6}>
                                <Typography variant="body2" color="primary">{t("intervals.until")}</Typography>
                            </Grid>
                        </Grid>
                        { returnKey.occurence && returnKey.occurence.length > 0 ?
                            returnKey.occurence.map(interval => (<Interval key={interval.startDate+"_"+interval.endDate} interval={interval} />)) : <Interval key={returnKey.startDate+"_"+returnKey.endDate} interval={{startDate: returnKey.startDate, endDate: returnKey.endDate}} />
                        }
                    </Grid>
                </Grid>
            </Grid>
        </StyledQVContainer>
    );
}