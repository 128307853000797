import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, Outlet, useLocation } from "react-router-dom";

import { useAuth } from "../../common/hooks/useAuth";

import { selectSettingsLoaded, selectLoginInCheckout } from '../../common/store/settingsSlice';
import { selectUserHasLocations, setLocationsNotDefined } from '../../common/store/locationsSlice';
import { getCategories } from '../../common/store/categoriesSlice';
import { getReturnKey } from '../../common/store/cartSlice';

export const ProtectedLayout = () => {
    const { token } = useAuth();
    const dispatch = useDispatch();
    const settingsLoaded = useSelector(selectSettingsLoaded);
    const loginOnlyInCheckout = useSelector(selectLoginInCheckout);
    const userHasLocations = useSelector(selectUserHasLocations);
    const location = useLocation();

    useEffect(() => {
        // If login only in checkout is selected then the locations cannot be defined
        if(loginOnlyInCheckout) {
            dispatch(setLocationsNotDefined(true));
            dispatch(getCategories());
            dispatch(getReturnKey());
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [settingsLoaded])

    if(settingsLoaded) {
        const hasCredentials = token && userHasLocations;

        if (!hasCredentials && !loginOnlyInCheckout) {
            return <Navigate to="/login" replace state={{ from: location }} />;
        }
    
        return (
            <div>
                <Outlet />
            </div>
        )
    }
};