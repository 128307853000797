import React, { useEffect, useState, useCallback } from "react";

import {
  Box,
  Zoom,
  Fab,
} from "@mui/material";
import { KeyboardArrowUp } from "@mui/icons-material";
import useScrollTrigger from "@mui/material/useScrollTrigger";

export default function ScrollToTop() {
    const [scrollTarget, setScrollTarget] = useState(undefined);
    
    const trigger = useScrollTrigger({
        target: scrollTarget,
        disableHysteresis: true,
        threshold: 100,
    });

    useEffect(() => {
        setScrollTarget(document.getElementById('main_content'));
    }, []);
    
 
    const scrollToTop = useCallback(() => {
        scrollTarget.scrollTo({ top: 0, behavior: "smooth" });
    }, [scrollTarget]);
 
    return (
        <Zoom in={trigger}>
            <Box
                role="presentation"
                sx={{
                    position: "fixed",
                    bottom: 32,
                    right: 32,
                    zIndex: 3000,
                    display: { sm: "none"}
                }}
                >
                <Fab
                    onClick={scrollToTop}
                    color="primary"
                    size="small"
                    aria-label="scroll back to top"
                >
                    <KeyboardArrowUp />
                </Fab>
            </Box>
        </Zoom>
    )
}