import React, { useEffect, useState } from 'react';
import { useDebounceCallback } from '@react-hook/debounce';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from "react-i18next";

import { Box, Container, Skeleton, Stack, Typography, Autocomplete } from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import EmailIcon from '@mui/icons-material/Email';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2

import { notScrollable, flexColFullHeightScrollable, flexRowFullWidthCentered, p1, flexRowCentered } from '../common/ui/sx/common';

import IdCardIcon from "./components/IdCardIcon";

import { usePageLayoutConfigurator } from '../common/hooks/usePageLayoutConfigurator';
import BackButton from '../common/ui/qvshop/components/core/BackButton';
import QVContainer from '../common/ui/qvshop/components/QVContainer';
import TextField from '../common/ui/qvshop/components/TextField';
import Select from '../common/ui/qvshop/components/Select';
import AcceptTermsSection from '../common/ui/qvshop/components/AcceptTermsSection';
import PrimaryButton from '../common/ui/qvshop/components/core/PrimaryButton';
import Breadcrumbs from '../common/ui/qvshop/components/Breadcrumbs';

import PageNames from '../common/utils/PageNames';
import { NavigationUtils } from '../common/utils/NavigationUtils';

import { 
    getCheckoutOptions,
    getCostCenters,
    resetCostCenters,
    makeReservation, 
    selectEmailLecturer, 
    selectMakeReservationFailed, 
    selectMakeReservationLoading, 
    selectMakeReservationStatus, 
    selectMakeReservationSuccessfull, 
    selectOptions, 
    selectStudentIdForPickupPerson, 
    selectTeacher, 
    selectTeachers, 
    selectCostCenters,
    setCostCenterIdForReservation,
    selectUsageText, 
    selectUserDetails, 
    selectUserTelephone, 
    setEmailLecturer, 
    setMakeResevationStatusIdle, 
    setStudentIdForPickupPerson, 
    setTeacher, 
    setUsageText, 
    setUserTelephone 
} from '../common/store/checkoutSlice';
import { selectCartHasItems, setCart } from '../common/store/cartSlice';
import { selectHasProduct, selectProduct } from '../common/store/productSlice';
import { selectCategory } from '../common/store/filtersSlice';
import { selectMandatoryCostCenterInCheckout } from '../common/store/settingsSlice';

export default function Checkout() {
    const dispatch = useDispatch();

    usePageLayoutConfigurator(PageNames.CHECKOUT);
    
    const { t } = useTranslation(['checkout']);
    const navigate = useNavigate();
    
    const cartHasItems = useSelector(selectCartHasItems);
    const hasVisitedProductDetailsPage = useSelector(selectHasProduct);
    const lastVisitedProduct = useSelector(selectProduct);
    const selectedCategory = useSelector(selectCategory);

    const userDetails = useSelector(selectUserDetails);
    const options = useSelector(selectOptions);
    const teachers = useSelector(selectTeachers);
    const costCenters = useSelector(selectCostCenters);

    const telephone = useSelector(selectUserTelephone);
    const studentIdForPickupPerson = useSelector(selectStudentIdForPickupPerson);
    const emailLecturer = useSelector(selectEmailLecturer);
    const teacher = useSelector(selectTeacher);
    const usageText = useSelector(selectUsageText);

    const makeReservationStatus = useSelector(selectMakeReservationStatus);
    const makeReservationLoading = useSelector(selectMakeReservationLoading);
    const makeReservationSuccessfull = useSelector(selectMakeReservationSuccessfull);
    const makeReservationFailed = useSelector(selectMakeReservationFailed);

    const mandatoryCostCenterInCheckout = useSelector(selectMandatoryCostCenterInCheckout);

    const [accepted, setAccepted] = useState(false);
    const [acceptedError, setAcceptedError] = useState(false);

    const [telephoneError, setTelephoneError] = useState(false);
    const [emailLecturerError, setEmailLecturerError] = useState(false);
    const [teacherError, setTeacherError] = useState(false);
    const [usageTextError, setUsageTextError] = useState(false);

    const [costCenterError, setCostCenterError] = useState(false);

    const [costCenterSelected, setCostCenterSelected] = useState("");
    const [costCenterPrefix, setCostCenterPrefix] = useState("");
    const [costCenterResponsible, setCostCenterResponsible] = useState("");

    useEffect(() => {
        resetCostCenterSelection();
    }, [])

    useEffect(() => {
        if(makeReservationSuccessfull) {
            dispatch(setCart([]));
            navigate("/checkout/success");
        }

        if(makeReservationFailed) {
            dispatch(setMakeResevationStatusIdle());
            navigate("/cart");
        }
    }, [dispatch, makeReservationFailed, makeReservationStatus, makeReservationSuccessfull, navigate])

    useEffect(() => {
        if(cartHasItems) {
            dispatch(getCheckoutOptions());
        } else {
            if(!makeReservationSuccessfull) {
                // Navigate to previous page. What should be the previous page????
                // navigate(-1); - not the right solution - the previous page might be checkout or any other unavailable page(eg. login page)
                // So the better solutin would be to check if the user has been on any product details page before and go there or if not then go to the products list page
                navigate(NavigationUtils.navigateToProductDetailsOrProductsList(hasVisitedProductDetailsPage, lastVisitedProduct, selectedCategory), { replace: true });
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, cartHasItems])

    const fetchCostCenters = useDebounceCallback(() => {
        if(costCenterPrefix && costCenterPrefix.length >= 3) {
            dispatch(getCostCenters(costCenterPrefix));
        } else {
            dispatch(resetCostCenters());
        }
    }, 400);

    useEffect(() => {
        fetchCostCenters();
    }, [costCenterPrefix, fetchCostCenters])

    const handleTeacherChange = (event) => {
        dispatch(setTeacher(event.target.value));
    };

    const handleAgreeChange = (event) => {
        setAccepted(event.target.checked);
    };

    const changeUserTelephone = (event) => {
        dispatch(setUserTelephone(event.target.value))
    };

    const changeStudentId = (event) => {
        dispatch(setStudentIdForPickupPerson(event.target.value))
    };

    const changeEmailLecturer = (event) => {
        dispatch(setEmailLecturer(event.target.value))
    };

    const changeUsageText = (event) => {
        dispatch(setUsageText(event.target.value))
    };

    const handleCostNumberChange = (costCenter) => {
        setCostCenterSelected(costCenter)
        
        // const costCenter = costCenters.find(costCenter => costCenter.number === costCenterNumber);

        if(costCenter) {
            dispatch(setCostCenterIdForReservation(costCenter.id));
            setCostCenterResponsible(costCenter.responsible);
        } else {
            resetCostCenterSelection();
        }
    };

    const resetCostCenterSelection = () => {
        dispatch(setCostCenterIdForReservation(0));
        setCostCenterSelected("")
        setCostCenterResponsible("");
    };

    const disabledCheckoutButton = (!cartHasItems);
    
    const handleSubmit = async (event) => {
        event.preventDefault();
        setAcceptedError(!accepted);

        let formHasErrors = false;
        
        if(options.phone_is_mandatory && telephone === '') {
            setTelephoneError(true);
            formHasErrors = true;
        } else {
            setTelephoneError(false);
        }
        
        if(options.show_email_lecturer && emailLecturer === '') {
            setEmailLecturerError(true);
            formHasErrors = true;
        } else {
            setEmailLecturerError(false);
        }
        
        if(!userDetails.is_teacher && !options.hide_teachers && options.teacher_is_mandatory && teacher === '') {
            setTeacherError(true);
            formHasErrors = true;
        } else {
            setTeacherError(false);
        }

        if(options.show_checkout_info && options.info_is_mandatory && usageText === '') {
            setUsageTextError(true);
            formHasErrors = true;
        } else {
            setUsageTextError(false);
        }

        // only check if the cost center is mandatory
        if(mandatoryCostCenterInCheckout) {
            if(costCenterSelected === '') {
                setCostCenterError(true);
                formHasErrors = true;
            } else {
                setCostCenterError(false);
            }
        }

        if(
            !formHasErrors &&
            !disabledCheckoutButton && 
            accepted
        ) {
            dispatch(makeReservation());
        } else {
            console.log("Form has errors.");
        }
    };

    return (
        <Container sx={{...flexColFullHeightScrollable, ...notScrollable, ...p1}} component="main" maxWidth="md">
            <Stack direction="column" spacing={3}>
                <Stack direction="row" spacing={2} alignItems="center">
                    <BackButton to="/cart/"/>
                    <Box sx={{
                        display: { xs: 'none', sm: 'block' }}}>
                        <Breadcrumbs />
                    </Box>
                    { false && <Box sx={flexRowFullWidthCentered}>
                        <Typography variant="body1">{t("checkout:checkout.title")}</Typography>
                    </Box> }
                </Stack>
                <Box component="form" onSubmit={handleSubmit} noValidate sx={{ width: 1, flex: "1 1 auto", justifyContent: "center"}}>
                    <QVContainer sx={{maxWidth: "md"}} padding="8px 24px" >
                        <Grid container spacing={2}>
                            <Grid xs={12}>
                                <Typography variant="h6" component="div" paddingY={1}>{ t("checkout:checkout.title") }</Typography>
                            </Grid>
                            <Grid xs={12}>
                                <Stack direction="column" spacing={2} >
                                    <Stack direction="row" spacing={2} >
                                        <PersonIcon color="primary" />
                                        { userDetails ? <Typography variant="body1" component="div" >{userDetails.anrede+" "+userDetails.vname+" "+userDetails.name}</Typography> : <Skeleton width={105} height={15} /> }
                                    </Stack>
                                    <Stack direction="row" spacing={2} >
                                        <EmailIcon color="primary" />
                                        { userDetails ? <Typography variant="body1" component="div" >{userDetails.email}</Typography> : <Skeleton width={95} height={15} /> }
                                    </Stack>
                                    { options?.show_matrikel_number && userDetails?.matriculation_nr !== '' && <Stack direction="row" spacing={2} >
                                        <IdCardIcon color="primary" />
                                        { userDetails ? <Typography variant="body1" component="div" >{userDetails.matriculation_nr}</Typography> : <Skeleton width={65} height={15} /> }
                                    </Stack> }
                                </Stack>
                            </Grid>
                            <Grid container xs={12} >
                                <Grid xs={12} md={6}>
                                    <TextField
                                        fullWidth
                                        error={telephoneError}
                                        errorTitle=""
                                        errorText=""
                                        name="telephone"
                                        label={t("label.telephone")}
                                        id="telephone"
                                        value={telephone}
                                        onChange={changeUserTelephone}
                                    />
                                </Grid>
                                { options?.show_id_number_of_person_collecting && <Grid xs={12} md={6}>
                                    <TextField
                                        fullWidth
                                        errorTitle=""
                                        errorText=""
                                        name="pickupPersonId"
                                        label={t("label.pickupPersonId")}
                                        id="pickupPersonId"
                                        value={studentIdForPickupPerson}
                                        onChange={changeStudentId}
                                    />
                                </Grid> }
                                { options?.show_email_lecturer && <Grid xs={12} md={6}>
                                    <TextField
                                        fullWidth
                                        error={emailLecturerError}
                                        errorTitle=""
                                        errorText=""
                                        required={options?.show_email_lecturer}
                                        name="emailLecturer"
                                        label={t("label.emailLecturer")}
                                        id="emailLecturer"
                                        value={emailLecturer}
                                        onChange={changeEmailLecturer}
                                    />
                                </Grid> }
                            </Grid>

                            {mandatoryCostCenterInCheckout &&
                                <>
                                    <Grid xs={12} md={6}>
                                        <Autocomplete
                                            disablePortal
                                            id="cost_center_number"
                                            options={costCenters}
                                            renderInput={(params) => <TextField {...params} label={t("label.costCenterNumber")} required={true} error={costCenterError} />}
                                            noOptionsText={t("label.noCostCentersFound")}
                                            required={true}
                                            getOptionLabel={(option) => option.number ?? ""}
                                            isOptionEqualToValue={(option, value) => option.number === value.number}
                                            onInputChange={(event, newPrfix) => {
                                                resetCostCenterSelection();
                                                setCostCenterPrefix(newPrfix);        
                                            }}
                                            onChange={(event, costCenterNumber) => {
                                                handleCostNumberChange(costCenterNumber);
                                            }}
                                            value={costCenterSelected}
                                        />
                                    </Grid>
                                    <Grid xs={12} md={6}>
                                        <Autocomplete
                                            disablePortal
                                            id="cost_center_responsible"
                                            options={[]}
                                            renderInput={(params) => <TextField {...params} label={t("label.costCenterResponsible")} required={true} error={costCenterError} />}
                                            // required={true}
                                            value={costCenterResponsible}
                                            readOnly={true}
                                        />
                                    </Grid>
                                </>
                            }
                            
                            { (!options?.hide_teachers && !userDetails?.is_teacher) && teachers && teachers.length > 0 && <Grid container xs={12} >
                                <Grid xs={12} md={6}>
                                    <Select sx={{width: 1}}
                                        error={teacherError}
                                        required={!options?.hide_teachers && !userDetails?.is_teacher && options?.teacher_is_mandatory}
                                        id="teacher"
                                        label={t("label.teacher")} 
                                        value={teacher}
                                        onChange={handleTeacherChange}
                                        options={teachers}
                                        />
                                </Grid>
                            </Grid> }
                            { options?.show_checkout_info && <Grid xs={12} >
                                    <TextField
                                        error={usageTextError}
                                        errorTitle=""
                                        errorText=""
                                        fullWidth
                                        required={options?.show_checkout_info && options?.info_is_mandatory}
                                        multiline
                                        rows={5}
                                        name="usageText"
                                        label={t("label.usageText")}
                                        id="usageText"
                                        value={usageText}
                                        onChange={changeUsageText}
                                    />
                            </Grid> }
                            <Grid xs={12} >
                                <AcceptTermsSection error={acceptedError} accepted={accepted} onAgreeChanged={handleAgreeChange} />
                            </Grid>
                            <Grid xs={12} >
                                <Stack sx={{mb: 2}} direction="row" spacing={2} justifyContent="space-between" alignItems="center">
                                    <BackButton to="/cart/"/>
                                    { makeReservationLoading ? <Skeleton variant="body1" sx={{...flexRowCentered, borderRadius: '8px'}} width={180} height={35}>{t("button.makingReservation.label")}</Skeleton> : <PrimaryButton disabled={disabledCheckoutButton} type="submit" label={t("checkout:button.sendReservation")} /> }
                                </Stack>
                            </Grid>
                        </Grid>
                    </QVContainer>
                </Box>
            </Stack>
        </Container>
    );
};