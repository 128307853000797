import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from "react-i18next";

import dayjs from 'dayjs';
import 'dayjs/locale/de';

import { Stack } from '@mui/material';

import DateTimeFormatConfig from '../../../utils/DateTimeFormatConfig';
import DateTimePickerWithSlider from './DateTimePickerWithSlider';

import WorkingDays from '../../../utils/WorkingDays';
import RecurrenceDays from './recurringIntervalSelector/utils/RecurrenceDays';

import {
    selectStartDate,
    selectEndDate,
    setStartDate,
    setEndDate,
    setStartDateRecurring,
    setEndDateRecurring,
    selectStartPickerEnabledHoursForDay,
    selectEndPickerEnabledHoursForDay,
    setStartPickerEnabledHoursForDay,
    setEndPickerEnabledHoursForDay
} from '../../../store/filtersSlice';
import { getCartFromAvailabilityCalendar, getCartToAvailabilityCalendar, updateCartItem } from '../../../store/cartSlice';
import { getFromAvailabilityCalendar, getToAvailabilityCalendar, updateProduct } from '../../../store/productSlice';
import { selectAllArticlesMustBeInTheSameInterval, selectSemesterSettings, selectWorkingHours } from '../../../store/settingsSlice';

export default function FromToInterval(props) {
    const { direction, withAvailability = false, item = false, subarticle = false, isCartItem = false, fromDate, toDate, withTime = true, isRecurrenceFromToSelector = false } = props;
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const allArticlesMustBeInTheSameInterval = useSelector(selectAllArticlesMustBeInTheSameInterval);
    const workingHours = useSelector(selectWorkingHours);
    const semesterSettings = useSelector(selectSemesterSettings);

    const startDateState = useSelector(selectStartDate);
    const endDateState = useSelector(selectEndDate);

    const startDate = fromDate ?? subarticle?.startDate ?? item?.startDate ?? startDateState;
    const endDate = toDate ?? subarticle?.endDate ?? item?.endDate ?? endDateState;

    const stateOpeningTimesForStartDate = useSelector(selectStartPickerEnabledHoursForDay);
    const stateOpeningTimesForEndDate = useSelector(selectEndPickerEnabledHoursForDay);

    const openingTimesForStartDate = subarticle?.openingTimesForStartDate ?? item?.openingTimesForStartDate ?? stateOpeningTimesForStartDate;
    const openingTimesForEndDate = subarticle?.openingTimesForEndDate ?? item?.openingTimesForEndDate ?? stateOpeningTimesForEndDate;

    const identifier = subarticle?.id ?? item?.product_id;
    const startDateAvailabilityCalendar = subarticle?.from_availability_calendar ?? item?.from_availability_calendar;
    const endDateAvailabilityCalendar = subarticle?.to_availability_calendar ?? item?.to_availability_calendar;

    const doDateChangeForFilters = (field, newValue) => {
        switch (field) {
            case "startDate":
                dispatch(setStartDate(DateTimeFormatConfig.formatDateToStandardPickerDateFormat(newValue)));
                const openingTimesForStartDate = WorkingDays.prepareTimeSliderDataForDay(workingHours, semesterSettings, newValue, RecurrenceDays.WORKING_HOURS_KEY_FOR_PICKUP);
                dispatch(setStartPickerEnabledHoursForDay(openingTimesForStartDate));
                break;
            case "endDate":
                dispatch(setEndDate(DateTimeFormatConfig.formatDateToStandardPickerDateFormat(newValue)));
                const openingTimesForEndDate = WorkingDays.prepareTimeSliderDataForDay(workingHours, semesterSettings, newValue, RecurrenceDays.WORKING_HOURS_KEY_FOR_DROPOFF);
                dispatch(setEndPickerEnabledHoursForDay(openingTimesForEndDate));
                break;
            case "startDateRecurring":
                dispatch(setStartDateRecurring(DateTimeFormatConfig.formatDateToDateOnlyString(newValue)));
                break;
            case "endDateRecurring":
                dispatch(setEndDateRecurring(DateTimeFormatConfig.formatDateToDateOnlyString(newValue)));
                break;

            default:
                console.log("From to interval changed field invalid: ", field);
                break;
        }
    }

    const doDateChangeForField = (field, newValue) => {
        if (item) {
            if (isCartItem) {
                dispatch(updateCartItem({ item, subarticle, field, value: DateTimeFormatConfig.formatDateToStandardPickerDateFormat(newValue) }));
            } else {
                dispatch(updateProduct({ item, subarticle, field, value: (field === "startDateRecurring" || field === "endDateRecurring") ? DateTimeFormatConfig.formatDateToDateOnlyString(newValue) : DateTimeFormatConfig.formatDateToStandardPickerDateFormat(newValue) }));
            }

            if (allArticlesMustBeInTheSameInterval) {
                doDateChangeForFilters(field, newValue);
            }
        } else {
            doDateChangeForFilters(field, newValue);
        }
    }

    const onFromDateChange = (newValue) => {
        doDateChangeForField(isRecurrenceFromToSelector ? "startDateRecurring" : "startDate", newValue);
    }

    const onToDateChange = (newValue) => {
        doDateChangeForField(isRecurrenceFromToSelector ? "endDateRecurring" : "endDate", newValue);
    }

    const onFromMonthChanged = (newMonth) => {
        if (!withAvailability) { return }

        console.log("From to interval FROM month changed to: ", newMonth.format("YYYY-MM-DD"));

        if (isCartItem) {
            dispatch(getCartFromAvailabilityCalendar({
                month: newMonth.format('MM'),
                year: newMonth.format('YYYY'),
                product: item,
                subarticle: subarticle
            }))
        } else {
            dispatch(getFromAvailabilityCalendar({
                month: newMonth.format('MM'),
                year: newMonth.format('YYYY'),
                product: item,
                subarticle: subarticle
            }))
        }
    }

    const onToMonthChanged = (newMonth) => {
        if (!withAvailability) { return }

        console.log("From to interval TO month changed to: ", newMonth.format("YYYY-MM-DD"));

        if (isCartItem) {
            dispatch(getCartToAvailabilityCalendar({
                month: newMonth.format('MM'),
                year: newMonth.format('YYYY'),
                product: item,
                subarticle: subarticle
            }))
        } else {
            dispatch(getToAvailabilityCalendar({
                month: newMonth.format('MM'),
                year: newMonth.format('YYYY'),
                product: item,
                subarticle: subarticle
            }))
        }
    }

    return (
        <Stack direction={direction ?? { xs: 'column', sm: 'row' }} spacing={{ xs: 2, sm: 2 }} >
            {startDate &&
                <DateTimePickerWithSlider
                    withTime={withTime}
                    isStartDatePicker
                    openingTimesForStartDate={openingTimesForStartDate}
                    openingTimesForEndDate={openingTimesForEndDate}
                    disablePast
                    label={t("datetimepicker.from")}
                    selectedDate={DateTimeFormatConfig.getDateFromStandardPickerDateFormat(startDate, withTime)}
                    withAvailability={withAvailability}
                    availabilityCalendar={startDateAvailabilityCalendar ? startDateAvailabilityCalendar[identifier] : null}
                    minDateTime={dayjs()}
                    onChange={onFromDateChange}
                    onMonthChanged={onFromMonthChanged}
                />}

            {endDate &&
                <DateTimePickerWithSlider
                    withTime={withTime}
                    openingTimesForStartDate={openingTimesForStartDate}
                    openingTimesForEndDate={openingTimesForEndDate}
                    disablePast
                    label={t("datetimepicker.until")}
                    selectedDate={DateTimeFormatConfig.getDateFromStandardPickerDateFormat(endDate, withTime)}
                    withAvailability={withAvailability}
                    availabilityCalendar={endDateAvailabilityCalendar ? endDateAvailabilityCalendar[identifier] : null}
                    minDateTime={withTime ? DateTimeFormatConfig.getDateFromStandardPickerDateFormat(startDate).add(15, "m") : DateTimeFormatConfig.getDateFromStandardPickerDateFormat(startDate).add(1, "d")}
                    onChange={onToDateChange}
                    onMonthChanged={onToMonthChanged}
                />}
        </Stack>
    );
};