import React from 'react';

import { Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2

export default function Interval(props) {
    const { interval } = props; 

    return (
        <Grid container xs={12} rowSpacing={0} columnSpacing={3}>
            <Grid xs={6}>
                <Typography sx={{px: 1}} variant="body1">{interval.startDate}</Typography>
            </Grid>
            <Grid xs={6}>
                <Typography sx={{px: 1}} variant="body1">{interval.endDate}</Typography>
            </Grid>
        </Grid>
    );
}