import React from 'react';

import { Typography, styled, Stack } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2

import { ReactRouterLink } from '../../common/ui/qvshop/components/core';

const StyledGridItem = styled(Grid)(({ theme }) => ({
    overflowY: 'unset',
    position: "relative",
    '& .MuiIconButton-root': {
        position: 'absolute',
        right: "-24px",
        top: "-24px",
    }
}));

export default function HeaderWithCloseButton(props) {
    const { title, to = false, subtitle = false, sx = {} } = props;

    const showAsLink = to ? true : false;

    return (
        <StyledGridItem xs={12}>
            <Stack component={showAsLink ? ReactRouterLink : "div"} to={showAsLink ? to : null} sx={sx} direction={{xs: 'column', md: 'row'}} alignItems="center">
                <Typography variant="h6" color="text.primary" >{title}</Typography>
                { subtitle && <Typography sx={{ pl: 1 }} variant="body1">{subtitle}</Typography> }
            </Stack>
        </StyledGridItem>
    );
}