import React from 'react'
import { SvgIcon } from '@mui/material';

// import IdCardSVG from '../../resources/id_card.svg';

export default function IdCardIcon (props) {
    return (
        <SvgIcon {...props}>
            <path id="_9055368_bxs_id_card_icon" data-name="9055368_bxs_id_card_icon" d="M20,4H4A2,2,0,0,0,2,6V18a2,2,0,0,0,2,2H20a2,2,0,0,0,2-2V6A2,2,0,0,0,20,4ZM8.715,8a1.943,1.943,0,0,1,2,2,1.943,1.943,0,0,1-2,2,1.943,1.943,0,0,1-2-2A1.943,1.943,0,0,1,8.715,8Zm3.715,8H5v-.465c0-1.373,1.676-2.785,3.715-2.785s3.715,1.412,3.715,2.785ZM19,15H15V13h4Zm0-4H14V9h5Z" />
        </SvgIcon>
    )
} 