import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from "react-i18next";
import DOMPurify from 'dompurify';

import FirstLoginPopupDialogBase from './FirstLoginPopupDialogBase';

import { selectFirstLoginText } from '../../../../../store/settingsSlice';

export default function LocationsPopupDialog(props) {
    const { t } = useTranslation();

    const firstLoginText = useSelector(selectFirstLoginText);

    return (
        <FirstLoginPopupDialogBase primaryButtonLabel={t("closeFirstLoginPopupButtonLabel")} {...props}>
            <div className="wysiwyg-editor" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(firstLoginText) }} ></div>
        </FirstLoginPopupDialogBase>           
    );
}