import dayjs from "dayjs";

var isSameOrBefore = require('dayjs/plugin/isSameOrBefore');
dayjs.extend(isSameOrBefore);

var isBetween = require('dayjs/plugin/isBetween');
dayjs.extend(isBetween);

var isoWeek = require('dayjs/plugin/isoWeek');
dayjs.extend(isoWeek)

class CheckWorkingHours {

	//TODO - WHErrorText and workingHoursCheck can be combined

    // day is the from day
    // day2 is the to day
    // eg.: monday to friday
	static WHErrorText(day, day2, workingHoursCheckData, workingDays, extendedTimes)
	{
        let errorTextPickup = '';
        let errorTextDropoff = '';
        // console.log("WHErrorText - workingDays: ", workingDays);

		const int_day_from = parseInt(day) - 1;
		const int_day_to = parseInt(day2) - 1;
        if(!workingHoursCheckData.inWorkingHours) {
            if (int_day_from >= 0 && int_day_to >= 0)
            {
                let daysOfWeek = ['Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag', 'Sonntag'];

                let currentDayHoursPickup = workingDays.pickup[int_day_from];
                let currentDayHoursDropoff = workingDays.dropoff[int_day_to];
                if(!workingHoursCheckData.inWorkingHoursPickup) {
                    if (!currentDayHoursPickup['von2'] && !currentDayHoursPickup['bis2']) {
                        errorTextPickup = extendedTimes ? 
                            `Öffnungszeit: ${daysOfWeek[int_day_from]} ${currentDayHoursPickup['von1']}-${currentDayHoursPickup['bis1']}`
                            : 
                            `Abholung: ${daysOfWeek[int_day_from]} ${currentDayHoursPickup['von1']}-${currentDayHoursPickup['bis1']}`;
                    } else {
                        errorTextPickup = extendedTimes ? 
                            `Öffnungszeit: ${daysOfWeek[int_day_from]} ${currentDayHoursPickup['von1']}-${currentDayHoursPickup['bis1']} und ${currentDayHoursPickup['von2']}-${currentDayHoursPickup['bis2']}`
                            : 
                            `Abholung: ${daysOfWeek[int_day_from]} ${currentDayHoursPickup['von1']}-${currentDayHoursPickup['bis1']} und ${currentDayHoursPickup['von2']}-${currentDayHoursPickup['bis2']}`;
                    }
                }

                if(!workingHoursCheckData.inWorkingHoursDropoff) {
                    if (!currentDayHoursDropoff['von2'] && !currentDayHoursDropoff['bis2']) {
                        errorTextDropoff = extendedTimes ? 
                            `Erweiterte Rückgabe: ${daysOfWeek[int_day_to]} ${currentDayHoursDropoff['von1']}-${currentDayHoursDropoff['bis1']}`
                            : 
                            `Rückgabe: ${daysOfWeek[int_day_to]} ${currentDayHoursDropoff['von1']}-${currentDayHoursDropoff['bis1']}`;
                    } else {
                        errorTextDropoff = extendedTimes ? 
                            `Erweiterte Rückgabe: ${daysOfWeek[int_day_to]} ${currentDayHoursDropoff['von1']}-${currentDayHoursDropoff['bis1']} und ${currentDayHoursDropoff['von2']}-${currentDayHoursDropoff['bis2']}`
                            : 
                            `Rückgabe: ${daysOfWeek[int_day_to]} ${currentDayHoursDropoff['von1']}-${currentDayHoursDropoff['bis1']} und ${currentDayHoursDropoff['von2']}-${currentDayHoursDropoff['bis2']}`;
                    }
                }
            }
        }

        let inWorkingHoursError = {
            "inWorkingHoursError": errorTextPickup+" "+errorTextDropoff,
            "inWorkingHoursErrorPickup": errorTextPickup,
            "inWorkingHoursErrorDropoff": errorTextDropoff
        };
        return inWorkingHoursError;
	}
	
    // day is the from day
    // day2 is the to day
    // eg.: monday 10:00 to friday 09:00
	static workingHoursCheck (von, bis, day, day2, workingDays)
	{
        // console.log("workingHoursCheck - workingDays: ", workingDays);
		let inWorkingHoursPickup = true;
        let inWorkingHoursDropoff = true;

		const int_day_from = parseInt(day) - 1;
		const int_day_to = parseInt(day2) - 1;
		if(int_day_from >= 0 && int_day_to >= 0)
		{
            const selectedFromDayIndex = int_day_from;
            const selectedToDayIndex = int_day_to;
            
            let currentDayHoursPickup = workingDays.pickup[selectedFromDayIndex];
            let currentDayHoursDropoff = workingDays.dropoff[selectedToDayIndex];

            let startTimePickup = dayjs(currentDayHoursPickup['von1'], 'HH:mm', true);
            let endTimePickup = dayjs(currentDayHoursPickup['bis1'], 'HH:mm', true);

            let startTimeDropoff = dayjs(currentDayHoursDropoff['von1'], 'HH:mm', true);
            let endTimeDropoff = dayjs(currentDayHoursDropoff['bis1'], 'HH:mm', true);

            let startTime2Pickup = dayjs(currentDayHoursPickup['von2'], 'HH:mm', true);
            let endTime2Pickup = dayjs(currentDayHoursPickup['bis2'], 'HH:mm', true);

            let startTime2Dropoff = dayjs(currentDayHoursDropoff['von2'], 'HH:mm', true);
            let endTime2Dropoff = dayjs(currentDayHoursDropoff['bis2'], 'HH:mm', true);

            let vonTime = dayjs(von, 'HH:mm', true);
            let bisTime = dayjs(bis, 'HH:mm', true);

            // Check Pickup times
            if (!currentDayHoursPickup['von2']) { // - only one pickup interval
                if (! vonTime.isBetween(startTimePickup, endTimePickup, null, '[]')) {
                    inWorkingHoursPickup = false;
                }
            } else { // - Two pickup intervals
                if (! (vonTime.isBetween(startTimePickup, endTimePickup, null, '[]') || 
                    vonTime.isBetween(startTime2Pickup, endTime2Pickup, null, '[]'))){

                        inWorkingHoursPickup = false;
                }
			}

            // Check Dropoff times
            if (!currentDayHoursDropoff['von2']) { // - only one pickup interval
                if (! bisTime.isBetween(startTimeDropoff, endTimeDropoff, null, '[]')) {
                    inWorkingHoursDropoff = false;
                }
            } else { // - Two pickup intervals
                if (! (bisTime.isBetween(startTimeDropoff, endTimeDropoff, null, '[]') || bisTime.isBetween(startTime2Dropoff, endTime2Dropoff, null, '[]'))){
					inWorkingHoursDropoff = false;
                }
			}
		}
        else
        {
            inWorkingHoursPickup = false;
            inWorkingHoursDropoff = false;
        }

        let inWorkingHours = {
            "inWorkingHours": inWorkingHoursPickup && inWorkingHoursDropoff,
            "inWorkingHoursPickup": inWorkingHoursPickup,
            "inWorkingHoursDropoff": inWorkingHoursDropoff
        };

        // console.log("workingHoursCheck - inWorkingHours: ", inWorkingHours);
		return inWorkingHours;
    }
	
    // For extended times - pickup means opening hours - dropoff means extended times with return key required
	static checkWithinOpeningHours(bis, endDate, workingDays, minutesBeforeClosing = 30)
	{
		let returnKeyOptional = false;
        let returnKeyRequired = false;

        let endDateDayOfWeek = endDate.isoWeekday();
		let int_day = parseInt(endDateDayOfWeek) - 1;
		if (int_day >= 0)
		{
            let selectedDayIndex = int_day;
            let currentDayOpeningHours = workingDays.pickup[selectedDayIndex];

            // No working hours for this day
            if(currentDayOpeningHours['von1'] === "false" || currentDayOpeningHours['von1'] === "") {
                let currentDayExtendedHours = workingDays.dropoff[selectedDayIndex];
                
                if(currentDayExtendedHours['von1'] === "false" || currentDayExtendedHours['von1'] === "") {
                    returnKeyOptional = false;
                    returnKeyRequired = false;
                } else {
                    returnKeyOptional = false;
                    returnKeyRequired = true;
                }
            } else {
                let startTimeOpeningHours = dayjs(currentDayOpeningHours['von1'], 'HH:mm', true);
                let endTimeOpeningHours = dayjs(currentDayOpeningHours['bis1'], 'HH:mm', true);

                let startTime2OpeningHours = dayjs(currentDayOpeningHours['von2'], 'HH:mm', true);
                let endTime2OpeningHours = dayjs(currentDayOpeningHours['bis2'], 'HH:mm', true);

                // Minutes before closing
                let minutesBeforeEndTimeOpeningHours = dayjs(currentDayOpeningHours['bis1'], 'HH:mm', true).subtract(minutesBeforeClosing, 'minutes');
                let minutesBeforeEndTime2OpeningHours =  dayjs(currentDayOpeningHours['bis2'], 'HH:mm', true).subtract(minutesBeforeClosing, 'minutes');

                let bisTime = dayjs(bis, 'HH:mm', true);

                // Check within opening times
                if (!currentDayOpeningHours['bis2']) { // - only one opening hours interval
                    if (!bisTime.isBetween(startTimeOpeningHours, endTimeOpeningHours, null, '[]')) {
                        returnKeyRequired = true;
                    }
                } else { // - Two pickup intervals
                    if (! (bisTime.isBetween(startTimeOpeningHours, endTimeOpeningHours, null, '[]') || 
                            bisTime.isBetween(startTime2OpeningHours, endTime2OpeningHours, null, '[]'))){
                        returnKeyRequired = true;
                    }
                }

                // Check within minutes of closing times
                if (!currentDayOpeningHours['bis2']) { // - only one opening hours interval
                    if (bisTime.isBetween(minutesBeforeEndTimeOpeningHours, endTimeOpeningHours, null, '[]')) {
                        returnKeyOptional = true;
                    }
                } else { // - Two pickup intervals
                    if ((bisTime.isBetween(minutesBeforeEndTimeOpeningHours, endTimeOpeningHours, null, '[]') || 
                            bisTime.isBetween(minutesBeforeEndTime2OpeningHours, endTime2OpeningHours, null, '[]'))){
                        returnKeyOptional = true;
                    }
                }
            }
		}

        let returnKeyRequirements = {
            "returnKeyOptional": returnKeyOptional,
            "returnKeyRequired": returnKeyRequired
        };

		return returnKeyRequirements;
    }


    static validateTime(time) {
        var isValid = /^([0-1][0-9]|2[0-3]):([0-5][0-9])$/.test(time);
        return isValid;
      }
}

export default CheckWorkingHours;