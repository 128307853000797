import React from 'react';
import { useSelector } from 'react-redux';
import DOMPurify from 'dompurify';
import { useTranslation } from 'react-i18next';
import "../../sx/wysiwyg-editor.css";

import FirstLoginPopupDialogBase from './FirstLoginPopupDialogBase';

import { selectNoLocationsText } from '../../../../../store/settingsSlice';

export default function NoLocationsPopupDialog(props) {
    const { t } = useTranslation();
    const noLocationsForUserText = useSelector(selectNoLocationsText);

    return (
        <FirstLoginPopupDialogBase primaryButtonLabel={t("closeFirstLoginPopupButtonLabel")} {...props}>
            <div className="wysiwyg-editor" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(noLocationsForUserText) }} ></div>
        </FirstLoginPopupDialogBase>
    );
}