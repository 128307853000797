import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Box, Link, Stack } from '@mui/material';

import { p1 } from '../../sx/common';

import { selectHelpPageLink, selectContactLink, selectDataPrivacyLink, selectImpressumLink, selectTermsAndConditionsLink } from '../../../store/settingsSlice';

function Footer(props) {
    const { direction } = props;
    const { t } = useTranslation();

    const helpPageLink = useSelector(selectHelpPageLink);
    const contactLink = useSelector(selectContactLink);
    const dataPrivacyLink = useSelector(selectDataPrivacyLink);
    const termsAndConditionsLink = useSelector(selectTermsAndConditionsLink);
    const impressumLink = useSelector(selectImpressumLink);

    return (
        <Box sx={p1}>
            <Stack direction={ direction ?? { xs: 'column', md: 'row' } } spacing={{ xs: 1, sm: 5 }} justifyContent="center">
                { helpPageLink !== "" && <Link variant="body1" href={helpPageLink} underline="none" target="_blank" >
                    {t('footer.links.help')}
                </Link> }
                { contactLink !== "" && <Link variant="body1" href={contactLink} underline="none" target="_blank" >
                    {t('footer.links.contact')}
                </Link> }
                { dataPrivacyLink !== "" && <Link variant="body1" href={dataPrivacyLink} underline="none" target="_blank" >
                    {t('footer.links.dataPrivacy')}
                </Link> }
                { termsAndConditionsLink !== "" && <Link variant="body1" href={termsAndConditionsLink} underline="none" target="_blank" >
                    {t('footer.links.termsAndConditions')}
                </Link> }
                { impressumLink !== "" && <Link variant="body1" href={impressumLink} underline="none" target="_blank" >
                    {t('footer.links.impressum')}
                </Link> }
            </Stack>
        </Box>
    );
}

export default Footer;