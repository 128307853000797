import dayjs from "dayjs";

import DateTimeFormatConfig from "../../utils/DateTimeFormatConfig";

import { RecurrenceUtils } from "../../ui/qvshop/components/recurringIntervalSelector/utils/RecurrenceUtils";
import { ShopDatesInterval } from "../../utils/ShopDatesInterval";
import { ProductUtils } from "./ProductUtils";

export class CartSliceUtils {
    static cartHasItems(state) {
        const cart = state.cart.cart;
        return cart.length;
    }

    static cartHasRecurringItems(state) {
        let hasRecurringItems = false;
        const cart = state.cart.cart;
        if(this.cartHasItems(state)) {
            let entityToCheck = cart[0];
            if(entityToCheck.isConfigurable && entityToCheck.subarticles) {
                entityToCheck = Object.values(entityToCheck.subarticles)[0];
            }
            hasRecurringItems = entityToCheck.recurring ? true : false;
        }

        return hasRecurringItems;
    }

    static cartHasNonRecurringItems(state) {
        let hasSingleItems = false;
        const cart = state.cart.cart;
        if(this.cartHasItems(state)) {
            let entityToCheck = cart[0];
            if(entityToCheck.isConfigurable && entityToCheck.subarticles) {
                entityToCheck = Object.values(entityToCheck.subarticles)[0];
            }
            hasSingleItems = entityToCheck.recurring ? false : true;
        }

        return hasSingleItems;
    }
    
    static getCartItemsTenantId(state) {
        let cart = state.cart.cart;
        if(!cart || !Array.isArray(cart) || cart.length < 1) {
            return false;
        }

        let cartItem = cart[0]
        return cartItem.tenant_id;
    }

    static cartHasAllItemsAvailable(state) {
        const cart = state.cart.cart;
        if(this.cartHasItems(state)) {
            return cart.reduce(
                (cartAvailability, cartItem) => {
                    if(cartItem.isConfigurable && cartItem.subarticles) {
                        const allSubarticlesAvailable = Object.values(cartItem.subarticles).reduce(
                            (subarticlesAvailability, subarticle) => {
                                return subarticlesAvailability && (subarticle.available === true);
                            },
                            true
                        );

                        return cartAvailability && allSubarticlesAvailable;
                    }
                    return cartAvailability && (cartItem.available === true);
                },
                true
            );
        }

        return false;
    }

    static getNumberOfItemsInCart(state) {
        const cart = state.cart.cart;
        if(this.cartHasItems(state)) {
            return cart.reduce(
                (cartItemsCount, cartItem) => {
                    if(cartItem.isConfigurable && cartItem.subarticles) {
                        return cartItemsCount + Object.keys(cartItem.subarticles).length;
                    }
                    return cartItemsCount+1;
                },
                0
            );
        }

        return 0;
    }

    static prepareAddItemToCart(state, productToAdd, subarticleToAdd = false) {
        let item = {...productToAdd};
        let subarticle = subarticleToAdd ? {...subarticleToAdd} : false;
        let cart = [...state.cart.cart];

        const hasExtendedTimes = state.settings.settings.extended_times || false;

        const allArticlesMustBeInTheSameInterval = state.settings.settings.all_articles_in_same_interval;

        if(item.quantity && item.quantity !== "") {
            item.quantity = parseInt(item.quantity)
        } else {
            item.quantity = 1
        }

        let changedEntity = subarticle ? subarticle : item;

        const newItemDates = {
            recurring: changedEntity?.recurring ?? state.filters.recurring,
            startDate: changedEntity?.startDate ?? state.filters.startDate,
            endDate: changedEntity?.endDate ?? state.filters.endDate,
            recurrenceType: changedEntity?.recurrenceType ?? state.filters.recurrenceType,
            startDateRecurring: changedEntity?.startDateRecurring ?? state.filters.startDateRecurring,
            endDateRecurring: changedEntity?.endDateRecurring ?? state.filters.endDateRecurring,
            startTime: changedEntity?.startTime ?? state.filters.startTime,
            endTime: changedEntity?.endTime ?? state.filters.endTime,
            repetitionInterval: changedEntity?.repetitionInterval ?? state.filters.repetitionInterval,
            recurrenceStartDay: changedEntity?.recurrenceStartDay ?? state.filters.recurrenceStartDay,
            recurrenceEndDay: changedEntity?.recurrenceEndDay ?? state.filters.recurrenceEndDay,
        };

        changedEntity = {...changedEntity, ...newItemDates};

        if(!changedEntity.recurring) {
            if(allArticlesMustBeInTheSameInterval && this.cartHasRecurringItems(state)) {
                console.log("Cart already contains recurring items - cannot add non recurring items.");
                return cart;
            }
        } else {
            // recurrent item
            if(allArticlesMustBeInTheSameInterval && this.cartHasNonRecurringItems(state)) {
                console.log("Cart already contains single items - cannot add recurring items.");
                return cart;
            }
        }

        let found = false;
        for (let id in cart) {
            if (cart[id].product_id === item.product_id) {
                if(subarticle) {
                    let subarticlesInCart = {...cart[id].subarticles};
                    // If we need to have on single interval for all articles update all subarticles to the dates og the currently added item
                    if (allArticlesMustBeInTheSameInterval) {
                        // change interval for all subarticles
                        subarticlesInCart = RecurrenceUtils.changeSubarticlesData(subarticlesInCart, newItemDates, state, hasExtendedTimes);
                    }

                    if(!changedEntity.recurring) {
                        subarticlesInCart[subarticle.id] = changedEntity;
                    } else {
                        subarticlesInCart[subarticle.id] = RecurrenceUtils.prepareOccurencesForItem(changedEntity);
                    }

                    item.subarticles = subarticlesInCart;
                    // Quantity for subarticles in cart is equal to the number of subarticles
                    item.quantity = Object.keys(subarticlesInCart).length;
                    // Has subarticles with incorrect recurrence data
                    item.hasRecurrenceErrors = Object.values(item.subarticles).reduce((hasRecurrenceErrors, subarticle) => { return subarticle.hasRecurrenceErrors || hasRecurrenceErrors}, false);
                    // Has subarticles with incorrect from-to data
                    item.hasFromToErrors = Object.values(item.subarticles).reduce((hasFromToErrors, subarticle) => { return subarticle.hasFromToErrors || hasFromToErrors}, false);
                } else {
                    if(changedEntity.recurring) {
                        item = RecurrenceUtils.prepareOccurencesForItem(changedEntity);
                    } else {
                        item = {...changedEntity};
                    }
                }

                cart[id] = item;

                found = true;
            } else {
                // ANDY - 14.12.2022 - this needs to be reworked to take into account subarticles
                if (allArticlesMustBeInTheSameInterval) {
                    // change interval for the other items
                    let cartItem = {...cart[id]};

                    if(cartItem.isConfigurable && cartItem.subarticles) {
                        let subarticles = { ...cartItem.subarticles };
                        subarticles = RecurrenceUtils.changeSubarticlesData(subarticles, newItemDates, state, hasExtendedTimes);
    
                        cartItem.subarticles = subarticles;
                        // Quantity for subarticles in cart is equal to the number of subarticles
                        cartItem.quantity = Object.keys(subarticles).length;
                        // Has subarticles with incorrect recurrence data
                        cartItem.hasRecurrenceErrors = Object.values(cartItem.subarticles).reduce((hasRecurrenceErrors, subarticle) => { return subarticle.hasRecurrenceErrors || hasRecurrenceErrors}, false);
                        // Has subarticles with incorrect from-to data
                        cartItem.hasFromToErrors = Object.values(cartItem.subarticles).reduce((hasFromToErrors, subarticle) => { return subarticle.hasFromToErrors || hasFromToErrors}, false);
                    } else {
                        cartItem = {...cartItem, ...newItemDates};
                        if(cartItem.recurring) {
                            cartItem = RecurrenceUtils.changeRecurrenceForItem(cartItem, state, hasExtendedTimes);
                        }
                    }
    
                    cart[id] = cartItem;
                }
            }
        }

        if (!found) {
            if(subarticle) {
                // Recreate the subarticle object as {subId: subarticle}
                let subarticleObj = {};
                if(!changedEntity.recurring) {
                    subarticleObj[subarticle.id] = changedEntity;
                } else {
                    subarticleObj[subarticle.id] = RecurrenceUtils.prepareOccurencesForItem(changedEntity);
                }
                
                item.subarticles = subarticleObj;
                // Quantity for subarticles in cart is equal to the number of subarticles
                item.quantity = Object.keys(item.subarticles).length;
            } else {
                if(changedEntity.recurring) {
                    item = RecurrenceUtils.prepareOccurencesForItem(changedEntity);
                } else {
                    item = {...changedEntity};
                }
            }

            cart = [...cart, item];
        }
        
        return cart;
    }

    static prepareUpdateCartItem(state, dispatch, updateInfo) {
        let item = {...updateInfo.item};
        let subarticle = updateInfo?.subarticle ? {...updateInfo.subarticle} : false;
        let cart = [...state.cart.cart];
        const hasExtendedTimes = state.settings.settings.extended_times || false;
        const allArticlesMustBeInTheSameInterval = state.settings.settings.all_articles_in_same_interval;

        let cartHasRecurrenceErrors = false;
        let cartHasFromToErrors = false;

        let changedEntity = subarticle ? subarticle : item;

        // First apply the changed value
        changedEntity[updateInfo.field] = updateInfo.value;
        if(updateInfo.field === "startDate") {
            const startDate = updateInfo.value;
            const endDate = changedEntity?.endDate ?? state.filters.endDate;
            
            const startDateAsDate = DateTimeFormatConfig.getDateFromStandardPickerDateFormat(startDate);
            const endDateAsDate = DateTimeFormatConfig.getDateFromStandardPickerDateFormat(endDate);
            const startDateIsValid = startDateAsDate.isValid();
            
            if(startDateIsValid) {
                const calculatedDates = ShopDatesInterval.get(state.settings.settings.working_hours, state.settings.settings.semester_settings, 0, 0, startDate, endDateAsDate.isValid() ? endDate : null);
                changedEntity['endDate'] = calculatedDates.endDate;
            }
        }

        changedEntity = ProductUtils.updateOpeningTimesDataForEntity(changedEntity, state, updateInfo.field);

        const newItemDates = {
            recurring: changedEntity?.recurring ?? state.filters.recurring,
            startDate: changedEntity?.startDate ?? state.filters.startDate,
            endDate: changedEntity?.endDate ?? state.filters.endDate,
            recurrenceType: changedEntity?.recurrenceType ?? state.filters.recurrenceType,
            startDateRecurring: changedEntity?.startDateRecurring ?? state.filters.startDateRecurring,
            endDateRecurring: changedEntity?.endDateRecurring ?? state.filters.endDateRecurring,
            startTime: changedEntity?.startTime ?? state.filters.startTime,
            startTimeError: changedEntity?.startTimeError ?? state.filters.startTimeError,
            endTime: changedEntity?.endTime ?? state.filters.endTime,
            endTimeError: changedEntity?.endTimeError ?? state.filters.endTimeError,
            repetitionInterval: changedEntity?.repetitionInterval ?? state.filters.repetitionInterval,
            recurrenceStartDay: changedEntity?.recurrenceStartDay ?? state.filters.recurrenceStartDay,
            recurrenceEndDay: changedEntity?.recurrenceEndDay ?? state.filters.recurrenceEndDay,
            openingTimesForStartDate: changedEntity?.openingTimesForStartDate ?? state.filters.openingTimesForStartDate,
            openingTimesForEndDate: changedEntity?.openingTimesForEndDate ?? state.filters.openingTimesForEndDate,
        };

        const itemToAdd = RecurrenceUtils.changeRecurrenceForItem(changedEntity, state, updateInfo.hasExtendedTimes);

        cart = cart.map(cartItem => {
            let updatedItem = { ...cartItem };
            if(updatedItem.product_id === item.product_id) {
                if(updatedItem.isConfigurable && updatedItem.subarticles && updatedItem.subarticles[subarticle.id] && subarticle) {
                    let subarticlesInCart = { ...updatedItem.subarticles };
                    // If we need to have on single interval for all articles update all subarticles to the dates of the currently changed item
                    if (allArticlesMustBeInTheSameInterval) {
                        // change interval for all subarticles
                        subarticlesInCart = RecurrenceUtils.changeSubarticlesData(subarticlesInCart, newItemDates, state, hasExtendedTimes);
                    }

                    // Add the newly changed subarticle to the subarticles
                    subarticlesInCart[subarticle.id] = itemToAdd;
                    updatedItem.subarticles = subarticlesInCart;
                    // Has subarticles with incorrect recurrence data
                    updatedItem.hasRecurrenceErrors = Object.values(updatedItem.subarticles).reduce((hasRecurrenceErrors, subarticle) => { return subarticle.hasRecurrenceErrors || hasRecurrenceErrors}, false);
                    // Has subarticles with incorrect from-to data
                    updatedItem.hasFromToErrors = Object.values(updatedItem.subarticles).reduce((hasFromToErrors, subarticle) => { return subarticle.hasFromToErrors || hasFromToErrors}, false);
                } else {
                    updatedItem = itemToAdd;
                }
                cartHasRecurrenceErrors = cartHasRecurrenceErrors || updatedItem.hasRecurrenceErrors;
                cartHasFromToErrors = cartHasFromToErrors || updatedItem.hasFromToErrors;
                return updatedItem;
            } else {
                // ANDY - 14.12.2022 - this needs to be reworked to take into account subarticles
                if (allArticlesMustBeInTheSameInterval) {
                    // change interval for the other items
                    if(updatedItem.isConfigurable && updatedItem.subarticles) {
                        let subarticlesInCart = { ...updatedItem.subarticles };
                        subarticlesInCart = RecurrenceUtils.changeSubarticlesData(subarticlesInCart, newItemDates, state, hasExtendedTimes);
    
                        updatedItem.subarticles = subarticlesInCart;
                        // Quantity for subarticles in cart is equal to the number of subarticles
                        updatedItem.quantity = Object.keys(subarticlesInCart).length;
                        // Has subarticles with incorrect recurrence data
                        updatedItem.hasRecurrenceErrors = Object.values(updatedItem.subarticles).reduce((hasRecurrenceErrors, subarticle) => { return subarticle.hasRecurrenceErrors || hasRecurrenceErrors}, false);
                        // Has subarticles with incorrect from-to data
                        updatedItem.hasFromToErrors = Object.values(updatedItem.subarticles).reduce((hasFromToErrors, subarticle) => { return subarticle.hasFromToErrors || hasFromToErrors}, false);
                    } else {
                        updatedItem = {...updatedItem, ...newItemDates};
                        if(updatedItem.recurring) {
                            updatedItem = RecurrenceUtils.changeRecurrenceForItem(updatedItem, state, hasExtendedTimes);
                        }
                    }
                    cartHasRecurrenceErrors = cartHasRecurrenceErrors || updatedItem.hasRecurrenceErrors;
                    cartHasFromToErrors = cartHasFromToErrors || updatedItem.hasFromToErrors;
                    return updatedItem;
                }
            }

            cartHasRecurrenceErrors = cartHasRecurrenceErrors || cartItem.hasRecurrenceErrors;
            cartHasFromToErrors = cartHasFromToErrors || cartItem.hasFromToErrors;
            return updatedItem;
        });

        return {cart, hasRecurrenceErrors: cartHasRecurrenceErrors, hasFromToErrors: cartHasFromToErrors};
    }

    static prepareDeleteCartItem(state, deleteItemInfo) {
        let item = {...deleteItemInfo.item};
        let subarticle = deleteItemInfo?.subarticle ? {...deleteItemInfo.subarticle} : false;
        let cart = [...state.cart.cart];

        if(subarticle?.id) {
            let removeWholeItem = false;
            cart = cart.map(cartItem => {
                if(cartItem.product_id === item.product_id) {
                    let changedItem = {...cartItem}
                    if(changedItem.isConfigurable && changedItem.subarticles[subarticle.id]) {
                        let subarticlesInCart = { ...changedItem.subarticles };
                        delete(subarticlesInCart[subarticle.id]);
                        changedItem.subarticles = subarticlesInCart;
                    }

                    if(Object.values(changedItem.subarticles).length === 0) {
                        removeWholeItem = true;
                    }
                    return changedItem;
                }
                return cartItem;
            });

            if(removeWholeItem) {
                cart = cart.filter(cartItem => cartItem.product_id !== item.product_id);
            }
        } else {
            cart = cart.filter(cartItem => cartItem.product_id !== item.product_id);
        }

        return cart;
    }

    static prepareAddReturnKeyToCart(state, returnKeyToAddData, addedByUser) {
        let item = {...returnKeyToAddData.details};
        let intervals = returnKeyToAddData.intervals;
        let cart = [...state.cart.cart];
        const recurring = state.filters.recurring;

        item.isReturnKey = true;
        item.available = returnKeyToAddData.available;
        item.returnKeyAddedByUser = addedByUser;

        item.recurring = recurring;

        let unique_stamp = dayjs().format("X");

        if(!item.recurring) {
            if (intervals.length === 1) {
                intervals.forEach((interval) => {
                    item.startDate = interval.start; // AppConfig.standardPickerDateFormat(interval.start);
                    item.endDate = interval.end; // AppConfig.standardPickerDateFormat(interval.end);
                    item.isReturnKey = true;
                    item.available = true;
                    item.returnKeyAddedByUser = addedByUser;
                });
            } else if(intervals.length >= 1) {
                let occ = JSON.stringify(item);
                item.occurence = [];

                intervals.forEach((interval) => {
                    let parseOcc = JSON.parse(occ);

                    parseOcc.startDate = interval.start; // AppConfig.standardPickerDateFormat(interval.start);
                    parseOcc.endDate = interval.end; // AppConfig.standardPickerDateFormat(interval.end);
                    parseOcc.resId = unique_stamp;
                    parseOcc.isReturnKey = true;
                    parseOcc.available = true;
                    parseOcc.returnKeyAddedByUser = addedByUser;

                    item.occurence.push(parseOcc);
                });
            }
            
            cart = this.addOrReplaceReturnKeyInCart(cart, item);
        } else {
            item.occurence = [];
            item.isReturnKey = true;
            item.returnKeyAddedByUser = addedByUser;
            let occ = JSON.stringify(item);

            if (intervals.length > 0) {
                intervals.forEach(function (occurence) {
                    let parseOcc = JSON.parse(occ);

                    parseOcc.startDate = occurence.start; // AppConfig.standardPickerDateFormat(occurence.start);
                    parseOcc.endDate = occurence.end; // AppConfig.standardPickerDateFormat(occurence.end);
                    parseOcc.resId = unique_stamp;
                    parseOcc.isReturnKey = true;
                    parseOcc.available = true;
                    parseOcc.returnKeyAddedByUser = addedByUser;

                    item.occurence.push(parseOcc)

                });
                
                cart = this.addOrReplaceReturnKeyInCart(cart, item);
            }
        }

        return cart;
    }

    static addOrReplaceReturnKeyInCart(cart, item) {
        let returnKeyInCart = cart.findIndex(x => x.isReturnKey === true);
        let newCart = [...cart];
        if( returnKeyInCart === -1 ) {
            newCart.push(item);
        } else {
            newCart[returnKeyInCart] = item;
        }

        return newCart;
    }
}