import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { Api } from "../api/api";

const STATUS_IDLE = 'idle';
const STATUS_LOADING = 'loading';
const STATUS_SUCCEEDED = 'succeeded';
const STATUS_FAILED = 'failed';

const initialState = {
    status: STATUS_IDLE,
    error: null,

    categories: {},
};

export const getCategories = createAsyncThunk(
    'shop/getCategories',
    async (_noParams, { getState }) => {
        const location = getState().filters.location;

        const response = await Api.getCategories(location);
        return response;
    }
)

export const slice = createSlice({
    name: 'categories',
    initialState,

    reducers: {
        setStatus: (state, action) => {
            state.status = action.payload;
        },
    },

    extraReducers: (builder) => {
        builder
            .addCase(getCategories.pending, (state) => {
                state.status = STATUS_LOADING;
            })
            .addCase(getCategories.fulfilled, (state, action) => {
                state.status = STATUS_SUCCEEDED;

                state.categories = action.payload.categories;
            })
            .addCase(getCategories.rejected, (state, action) => {
                state.status = STATUS_FAILED;
                state.error = action.payload;
            })
    },
});

export const { setStatus } = slice.actions;

export const selectIsLoadingCategories = state => state.categories.status !== STATUS_SUCCEEDED;

export const selectCategories = state => state.categories.categories;

export default slice.reducer;