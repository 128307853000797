export class ProductsUtils {
    static prepareAttributes(allAttributes) {
        let specifications = [];
        for (const attributes of allAttributes) {
            for (const attr of attributes) {
                let foundAttribute = specifications.find(spezi => spezi.attribute === attr.attribute);
                
                if(!foundAttribute) {
                    specifications = [...specifications, {
                        "attribute": attr.attribute,
                        "values": [{
                            id: "",
                            name: "select.options.general.all"
                        }, 
                        {
                            id: attr.value,
                            name: attr.value
                        }]
                    }];
                } else {
                    // check if value is already added
                    let foundValueForAttribute = foundAttribute.values.find(val => val.name === attr.value);
                    if(!foundValueForAttribute) {
                        foundAttribute.values = [...foundAttribute.values, {id: attr.value, name: attr.value}];
                    }
                }
            };
        };

        specifications.sort(this.sortByObjectAttribute("attribute")).map(attr => attr.values.sort(this.sortByObjectAttribute("id")));
        return specifications;
    }

    static sortByObjectAttribute(attribute) {
        return function (a, b) {
            if (a[attribute] < b[attribute]) return -1;
            if (a[attribute] > b[attribute]) return 1;
            return 0;
        }
      }
}