import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from "react-i18next";

import Select from '../../Select';
import RecurrenceIntervals from '../utils/RecurrenceIntervals';

import { 
    selectRepetitionInterval,
    setRepetitionInterval,
} from '../../../../../store/filtersSlice';
import { updateProduct } from '../../../../../store/productSlice';
import { updateCartItem } from '../../../../../store/cartSlice';
import { selectAllArticlesMustBeInTheSameInterval } from '../../../../../store/settingsSlice';

export default function RecurrenceRepetitionIntervalSelect(props) {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { item = false, subarticle = false, isCartItem = false } = props;

    const allArticlesMustBeInTheSameInterval = useSelector(selectAllArticlesMustBeInTheSameInterval);
    
    const repetitionIntervalState = useSelector(selectRepetitionInterval);

    let repetitionInterval = subarticle?.repetitionInterval ?? item?.repetitionInterval ?? repetitionIntervalState;

    const handleRepetitionIntervalChange = (event) => {
        const newValue = event.target.value;
        if(item) {
            if(isCartItem) {
                dispatch(updateCartItem({item, subarticle, field: "repetitionInterval", value: newValue}));
            } else {
                dispatch(updateProduct({item, subarticle, field: "repetitionInterval", value: newValue}));
            }

            if(allArticlesMustBeInTheSameInterval) {
                dispatch(setRepetitionInterval(newValue));
            }
        } else {
            dispatch(setRepetitionInterval(newValue));
        }
    };

    return (
        <Select 
            sx={{width: "100%", minWidth: "110px"}}
            id="repetitionInterval"
            label={t("sidebar.tab.recurring.repeatEvery.select.label")}
            value={repetitionInterval}
            onChange={handleRepetitionIntervalChange}
            options={[
                {id: RecurrenceIntervals.RECURRENCE_INTERVAL_NONE, name: t("select.options.recurring.interval.choose")},
                {id: RecurrenceIntervals.RECURRENCE_INTERVAL_1_WEEK, name: t("select.options.recurring.interval.1Week")}, 
                {id: RecurrenceIntervals.RECURRENCE_INTERVAL_2_WEEK, name: t("select.options.recurring.interval.2Week")},
                {id: RecurrenceIntervals.RECURRENCE_INTERVAL_3_WEEK, name: t("select.options.recurring.interval.3Week")},
                {id: RecurrenceIntervals.RECURRENCE_INTERVAL_4_WEEK, name: t("select.options.recurring.interval.4Week")}
            ]}
            />
    );
};