import React, { Fragment, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from "react-i18next";

import { Container, Paper, Stepper, Step, StepLabel, Typography, Box, useMediaQuery, Grid, CssBaseline } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import IdentifyUser from './cmponents/IdentifyUser';
import ResetPassword from './cmponents/ResetPassword';
import PasswordChanged from './cmponents/PasswordChanged';

import PageNames from '../common/utils/PageNames';

import { usePageLayoutConfigurator } from "../common/hooks/usePageLayoutConfigurator";
import ReactRouterLink from '../common/ui/qvshop/components/core/ReactRouterLink';

import { resetState, selectActiveStep, setActiveStep } from '../common/store/passwordForgottenSlice';

export default function PasswordForgotten() {
    usePageLayoutConfigurator(PageNames.PASSWORD_FORGOTTEN);
    const { t } = useTranslation(['common', 'passwordForgotten']);
    const dispatch = useDispatch();
    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.down('sm'));
    const activeStep = useSelector(selectActiveStep);

    useEffect(() => {
        return () => {
            dispatch(resetState())
        }
    }, [dispatch]);
    
    const steps = ['passwordForgotten:step.IdentifyUser', 'passwordForgotten:step.ResetPassword', 'passwordForgotten:step.PasswordChanged'];

    const getStepContent = (step) => {
        switch (step) {
            case 0:
                return <IdentifyUser />;
            case 1:
                return <ResetPassword />;
            case 2:
                return <PasswordChanged  moveForward={handleNext} />;
            default:
                throw new Error('Unknown step');
        }
    }

    const handleNext = () => {
        setActiveStep(activeStep + 1);
    };

    return (
        <Container component="main" maxWidth="sm" sx={{ mb: 4 }}>
            <CssBaseline />
            <Paper variant="outlined" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>
                <Typography component="h1" variant="h4" align="center">
                    {t("passwordForgotten:page.title")}
                </Typography>
                <Stepper activeStep={activeStep} sx={{ pt: 3, pb: 5 }} orientation={mobile ? "vertical" : "horizontal"}>
                    {steps.map((label) => (
                        <Step key={label}>
                            <StepLabel>{t(label)}</StepLabel>
                        </Step>
                    ))}
                </Stepper>
                <Fragment>
                    {getStepContent(activeStep)}
                    <Box>
                        <Grid container justifyContent="center">
                            <Grid item>
                                <ReactRouterLink to="/login">{t("passwordForgotten:button.toLogin")}</ReactRouterLink>
                            </Grid>
                        </Grid>
                    </Box>
                </Fragment>
            </Paper>
        </Container>
    );
}