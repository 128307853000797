import React from 'react';
import { useSelector } from 'react-redux';
import { Box, Stack, Typography } from '@mui/material';

import { px1, pb0, fullWidthPadded1, fullWidth, my1 } from "../../common/ui/sx/common";

import SpecificationsBox from '../../common/ui/qvshop/components/SpecificationsBox';
import QVContainer from '../../common/ui/qvshop/components/QVContainer';
import ReservationSection from '../../common/ui/qvshop/components/ReservationSection';
import { selectAllArticlesMustBeInTheSameInterval } from '../../common/store/settingsSlice';

export default function SubarticleSection(props) {
    const { product, item, loadingAvailability } = props;
    const allArticlesMustBeInASingleInterval = useSelector(selectAllArticlesMustBeInTheSameInterval);

    return (
        <QVContainer borderRadius={8} variant="outlined" sx={fullWidth}>
            <Stack sx={my1} direction='column' spacing={2} alignItems="flex-start">
                <Box sx={px1}>
                    <Typography gutterBottom variant="h6" component="div">
                        {item.prefix_or_gnr} - {item.number}
                    </Typography>
                </Box>
                { item && item.attributes && item.attributes.length > 0 && <Box sx={px1}>
                    <SpecificationsBox item={item} specifications={ item.attributes } />
                </Box> }
                <Box sx={{...fullWidthPadded1, ...pb0}} >
                    <ReservationSection loadingAvailability={loadingAvailability} showIntervalSelection={!allArticlesMustBeInASingleInterval} item={product} subarticle={item} showAddToCartButton />
                </Box>
            </Stack>
        </QVContainer>
    );
};