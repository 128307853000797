import React, { useState } from 'react';

import { Alert, AlertTitle, Tooltip, useMediaQuery, useTheme } from '@mui/material';

import MUITextField from '@mui/material/TextField'

export default function TextField(props) {
    const { error, errorTitle, errorText, helperText, forceHelperText = false, ...otherProps } = props;

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    
    const [open, setOpen] = useState(false);

    const hasErrorMessage = error && (errorTitle || errorText) && (errorTitle !== '' || errorText !== '');

    const handleClose = () => {
        setOpen(false);
    };

    const handleOpen = () => {
        if (error && (errorTitle !== '' || errorText !== '')) {
            setOpen(true);
        }
    };

    const handleInputFoused = () => {
        // ANDY - 10.04.2023 - uncomment to show the error tooltip on mobile devices on focus
        // isMobile && setOpen(true);
    }

    const handleInputBlur = () => {
        // ANDY - 10.04.2023 - uncomment to hide the error tooltip on mobile devices on blur
        // isMobile && setOpen(false);
    }

    return (
        <Tooltip open={open} onClose={handleClose} onOpen={handleOpen} placement="bottom-start"
            title={!isMobile && hasErrorMessage ?
                <Alert variant="filled" severity="error">
                    { errorTitle !== '' && <AlertTitle>{ errorTitle }</AlertTitle>}
                    { errorText }
                </Alert> : null
            }
        >
            <MUITextField color="primary" helperText={forceHelperText ? " " : helperText} error={error} {...otherProps} onFocus={handleInputFoused} onBlur={handleInputBlur} />
        </Tooltip>
    );
};