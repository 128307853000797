import React, { StrictMode } from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { configureStore } from '@reduxjs/toolkit';

import { SnackbarProvider } from 'notistack';

import './common/lang/config';

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import App from './App'

import settingsReducer from './common/store/settingsSlice';
import appStateReducer from './common/store/appStateSlice';
import layoutReducer from './common/store/layoutSlice';
import locationsReducer from './common/store/locationsSlice';
import productsReducer from './common/store/productsSlice';
import filtersReducer from './common/store/filtersSlice';
import categoriesReducer from './common/store/categoriesSlice';
import productReducer from './common/store/productSlice';
import cartReducer from './common/store/cartSlice';
import checkoutReducer from './common/store/checkoutSlice';
import registerReducer from './common/store/registerSlice';
import passwordForgottenReducer from './common/store/passwordForgottenSlice';

let appStore = configureStore({
    reducer: {
        settings: settingsReducer,
        appState: appStateReducer,
        layout: layoutReducer,
        locations: locationsReducer,
        products: productsReducer,
        filters: filtersReducer,
        categories: categoriesReducer,
        product: productReducer,
        cart: cartReducer,
        checkout: checkoutReducer,
        register: registerReducer,
        passwordForgotten: passwordForgottenReducer,
    }
})

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <StrictMode>
        <Provider store={appStore}>
            <SnackbarProvider dense autoHideDuration={5000} maxSnack={1}>
                <App />
            </SnackbarProvider>
        </Provider>
    </StrictMode>
);
