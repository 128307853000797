import { createSlice } from '@reduxjs/toolkit';

const STATUS_IDLE = 'idle';
const STATUS_LOADING = 'loading';
// const STATUS_SUCCEEDED = 'succeeded';
// const STATUS_FAILED = 'failed';

const initialState = {
    status: STATUS_IDLE,
    error: null,

    showSearch: false,
    showSidebar: false,
    showFilterIcon: false,
};

export const slice = createSlice({
    name: 'layout',
    initialState,

    reducers: {
        setStatus: (state, action) => {
            state.status = action.payload;
        },
        setShowSidebar: (state, action) => {
            state.showSidebar = action.payload;
        },
        setShowFilterIcon: (state, action) => {
            state.showFilterIcon = action.payload;
        },
        handleDrawerToggle: (state, action) => {
            state.showSidebar = !state.showSidebar;
        },
        toggleShowSearch: (state, action) => {
            state.showSearch = !state.showSearch;
        },
    },
});

export const { setStatus, setShowSidebar, setShowFilterIcon, handleDrawerToggle, toggleShowSearch } = slice.actions;

export const selectIsLoading = state => state.layout.status === STATUS_LOADING;
export const selectShowSearch = state => state.layout.showSearch;
export const selectShowSidebar = state => state.layout.showSidebar;
export const selectShowFilterIcon = state => state.layout.showFilterIcon;

export default slice.reducer;