import { calculateArticlePrice } from './ArticlePrice';

export class CartUtils {
    static gerPriceForCart(cart) {

        let cartPrice = 0;

        for (let item in cart) {
            const cartItem = cart[item];

            if(cartItem.isReturnKey || cartItem.recurring === 1) {
                continue;
            }

            if(cartItem.isConfigurable && Object.values(cartItem.subarticles).length > 0 ) {
                const priceSubarticles = 
                    Object.values(cartItem.subarticles).reduce((sum, subarticle) => {

                        const articlePrice = calculateArticlePrice(subarticle.article_price, subarticle.price_unit, 
                            subarticle.startDate, subarticle.endDate);

                        return sum + articlePrice;
                    }, 0);

                cartPrice += priceSubarticles;
            } else {

                const articlePrice = calculateArticlePrice(cartItem.article_price, cartItem.price_unit,
                    cartItem.startDate, cartItem.endDate, cartItem.quantity);

                cartPrice += articlePrice;
            }
        }

        return cartPrice;
    }
}
