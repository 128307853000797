import React from 'react';
import PropTypes from 'prop-types';

import { Button, DialogActions, Slider } from '@mui/material';
import { useLocaleText  } from '@mui/x-date-pickers/internals';

import { Container } from '@mui/system';

export default function QVActionBarWithTime(props) {
    const { className, onAccept, minutes, onTimeChanged, isStartDatePicker, selectedDate, openingTimes, minDate = false, timePicker = false } = props;
    const localeText = useLocaleText();

    const handleChange = (event, newValue) => {
        const hours = parseInt(newValue / 60);
        const minutes = newValue % 60;

        onTimeChanged(hours, minutes);
    };

    let marks = [];
    
    function valueLabelFormat(value) {
        const r = (value % 60);
        const rText = (r === 0 ? "00" : r);
    
        return (parseInt(value / 60) + ':' + rText);
    }

    // deep clone openingTimes, so that we can change the label of the first mark
    marks = openingTimes !== false ? openingTimes.map(a => {return {...a}}) : [];
    if(!isStartDatePicker && minDate && (minDate.isSame(selectedDate, 'd'))) {
        const minMark = minDate.hour() * 60 + minDate.minute();
        marks = marks.filter(mark => mark.value >= minMark)
    }

    // 7 marks is: 3 quarters, full hour, 3 quarters - the longest possible time interval that displays 1 full hour only
    if(marks.length > 0 && marks.length <= 7) {
        // if there is only one mark or the first two have no label, set the label of the first mark
        if(marks[0].label === '' && (!marks[1] || (marks[1] && marks[1].label === ''))) {
            marks[0].label = valueLabelFormat(marks[0].value);
        }

        // if the last two marks have no label, set the label of the last mark
        if(marks[marks.length-1].label === '' && (!marks[marks.length-2] || (marks[marks.length-2] && marks[marks.length-2].label === ''))) {
            marks[marks.length-1].label = valueLabelFormat(marks[marks.length-1].value);
        }
    }

    return (
        <>
            <Container className={className} sx={timePicker ? {minWidth: '300px', pt: 2} : {}}>
                <Slider
                    aria-label="Restricted values"
                    value={minutes}
                    onChange={handleChange}
                    valueLabelFormat={valueLabelFormat}
                    step={null}
                    valueLabelDisplay="auto"
                    marks={marks}
                    min={marks[0].value}
                    max={marks[marks.length-1].value}
                    track={false}
                />
          
                <DialogActions>
                    {/* <Button onClick={onCancel}>{localeText.cancelButtonLabel}</Button> */}
                    <Button onClick={onAccept}>{localeText.okButtonLabel}</Button>
                </DialogActions>
            </Container>
        </>
    );
}

QVActionBarWithTime.propTypes = {
    // does not use actions
    selectedDate: PropTypes.object,
    minDate: PropTypes.object,
    minutes: PropTypes.number, // is daysjs
    onTimeChanged: PropTypes.func.isRequired,
    onAccept: PropTypes.func.isRequired,
    onCancel: PropTypes.func,
};