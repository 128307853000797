import React from 'react';
import { useTranslation } from "react-i18next";

import { Stack } from '@mui/material';

import Select from '../../Select';
import TimeInput from '../../TimeInput';
import RecurrenceDays from '../utils/RecurrenceDays';

export default function RecurrenceDayTimeSelectorView(props) {
    const { 
        disabledDays, 
        recurrenceDay, 
        handleRecurrenceDayChange, 
        selectDayLabel, 
        recurrenceTime, 
        handleRecurrenceTimeChange, 
        recurrenceTimeLabel, 
        onTimeError,
        error,
        errorTitle,
        errorText,
        isStartDay,
        openingHours
    } = props;
    const { t } = useTranslation();

    return (
        <Stack direction='row' spacing={1} width={1}>
            <Select 
                sx={{width: "100%", minWidth: "100px"}}
                id="pick-day"
                label={selectDayLabel}
                value={recurrenceDay}
                onChange={handleRecurrenceDayChange}
                options={[
                    {id: RecurrenceDays.RECURRENCE_DAY_NONE, name: t("select.options.recurring.day.choose")},
                    {id: RecurrenceDays.RECURRENCE_DAY_MONDAY, name: t("select.options.recurring.day1"), disabled: disabledDays.indexOf(RecurrenceDays.RECURRENCE_DAY_MONDAY) > -1}, 
                    {id: RecurrenceDays.RECURRENCE_DAY_TUESDAY, name: t("select.options.recurring.day2"), disabled: disabledDays.indexOf(RecurrenceDays.RECURRENCE_DAY_TUESDAY) > -1}, 
                    {id: RecurrenceDays.RECURRENCE_DAY_WEDNESDAY, name: t("select.options.recurring.day3"), disabled: disabledDays.indexOf(RecurrenceDays.RECURRENCE_DAY_WEDNESDAY) > -1},
                    {id: RecurrenceDays.RECURRENCE_DAY_THURSDAY, name: t("select.options.recurring.day4"), disabled: disabledDays.indexOf(RecurrenceDays.RECURRENCE_DAY_THURSDAY) > -1},
                    {id: RecurrenceDays.RECURRENCE_DAY_FRIDAY, name: t("select.options.recurring.day5"), disabled: disabledDays.indexOf(RecurrenceDays.RECURRENCE_DAY_FRIDAY) > -1}, 
                    {id: RecurrenceDays.RECURRENCE_DAY_SATURDAY, name: t("select.options.recurring.day6"), disabled: disabledDays.indexOf(RecurrenceDays.RECURRENCE_DAY_SATURDAY) > -1}, 
                    {id: RecurrenceDays.RECURRENCE_DAY_SUNDAY, name: t("select.options.recurring.day7"), disabled: disabledDays.indexOf(RecurrenceDays.RECURRENCE_DAY_SUNDAY) > -1}, 
                ]}
                />
            <TimeInput sx={{width: 170}}
                disabled={recurrenceDay === 0}
                error={error}
                errorTitle={errorTitle}
                errorText={errorText}
                onTimeError={onTimeError}
                name="time"
                label={recurrenceTimeLabel}
                id="time"
                value={recurrenceTime}
                setValue={handleRecurrenceTimeChange}
                recurrenceDay={recurrenceDay}
                isStartDay={isStartDay}
                openingHours={openingHours}
            />
        </Stack>
    );
};