import React from 'react';

import Masonry from 'react-masonry-css';
import "../components/sx/masonryStyles.css";

import { Skeleton, Box } from '@mui/material';

export default function MasonrySection(props) {
    const { items, product, breakpointCols, loading, card: Card, skeleton: SkeletonItem, hideAddToCartButton = false, loadingAvailability } = props;

    const shouldShowSkeleton = loading && items.length === 0;

    return (
        <Box sx={{width: "100%"}}>
            <Masonry breakpointCols={breakpointCols ?? {
                default: 3,
                720: 1,
                1050: 2,
                1380: 3,
            }} 
                className="masonry-grid"
                columnClassName="masonry-grid_column">

                {shouldShowSkeleton && [0, 1, 2].map((item) => (
                    <Box key={"sk_" + item}>
                        {SkeletonItem ? <SkeletonItem item={item}/> : <Skeleton height={140} width={200} />}
                    </Box>
                ))}

                {items.map((item, index) => (
                    <Box key={index}>
                        <Card 
                            item={item} 
                            product={product} 
                            hideAddToCartButton={hideAddToCartButton} 
                            loadingAvailability={ loadingAvailability } 
                        />
                    </Box>
                ))}
            </Masonry>
        </Box>
    );
}