import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import commonTranslationDE from "./locales/de/translations.json";
import commonTranslationEN from "./locales/en/translations.json";
import cartTranslationDE from "../../cart/lang/de/cartTranslations.json";
import cartTranslationEN from "../../cart/lang/en/cartTranslations.json";
import checkoutTranslationDE from "../../checkout/lang/de/checkoutTranslations.json";
import checkoutTranslationEN from "../../checkout/lang/en/checkoutTranslations.json";
import loginTranslationDE from "../../login/lang/de/loginTranslations.json";
import loginTranslationEN from "../../login/lang/en/loginTranslations.json";
import passwordForgottenTranslationDE from "../../passwordForgotten/lang/de/passwordForgottenTranslations.json";
import passwordForgottenTranslationEN from "../../passwordForgotten/lang/en/passwordForgottenTranslations.json";
import registerTranslationDE from "../../register/lang/de/registerTranslations.json";
import registerTranslationEN from "../../register/lang/en/registerTranslations.json";
import rentalProcessFinishedTranslationDE from "../../rentalProcessFinished/lang/de/rentalProcessFinishedTranslations.json";
import rentalProcessFinishedTranslationEN from "../../rentalProcessFinished/lang/en/rentalProcessFinishedTranslations.json";
import registerFinishedTranslationDE from "../../registerFinished/lang/de/registerFinishedTranslations.json";
import registerFinishedTranslationEN from "../../registerFinished/lang/en/registerFinishedTranslations.json";

i18n.use(initReactI18next).init({
  fallbackLng: 'de',
  lng: 'de',
  resources: {
    de: {
      common: commonTranslationDE,
      cart: cartTranslationDE,
      checkout: checkoutTranslationDE,
      login: loginTranslationDE,
      passwordForgotten: passwordForgottenTranslationDE,
      register: registerTranslationDE,
      rentalProcessFinished: rentalProcessFinishedTranslationDE,
      registerFinished: registerFinishedTranslationDE,
    },
    en: {
      common: commonTranslationEN,
      cart: cartTranslationEN,
      checkout: checkoutTranslationEN,
      login: loginTranslationEN,
      passwordForgotten: passwordForgottenTranslationEN,
      register: registerTranslationEN,
      rentalProcessFinished: rentalProcessFinishedTranslationEN,
      registerFinished: registerFinishedTranslationEN,
    }
  },
  ns: ['common', 'cart', 'checkout', 'login', 'passwordForgotten', 'register', 'rentalProcessFinished', 'registerFinished'],
  defaultNS: 'common'
});

i18n.languages = ['de', 'en'];

export default i18n;