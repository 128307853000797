import { createSlice } from '@reduxjs/toolkit';
import DateTimeFormatConfig from '../utils/DateTimeFormatConfig';
import dayjs from 'dayjs';
import { RecurrenceUtils } from '../ui/qvshop/components/recurringIntervalSelector/utils/RecurrenceUtils';
import { ShopDatesInterval } from '../utils/ShopDatesInterval';

const initialState = {
    error: null,

    location: "0",
    category: "",
    categoryPath: [],

    search: false,
    searchCriteria: "",

    specificationsFilters: {},

    recurring: 0,

    workingHoursForSelectedPeriod: null,
    outsideWorkingHoursPickup: false,
    outsideWorkingHoursDropoff: false,
    outsideWorkingHoursPickupError: "",
    outsideWorkingHoursDropoffError: "",

    startPickerEnabledIntervals: [],
    endPickerEnabledIntervals: [],
    startPickerEnabledHoursForDay: [],
    endPickerEnabledHoursForDay: [],
    recurrenceStartTimePickerEnabledHoursForDay: [],
    recurrenceEndTimePickerEnabledHoursForDay: [],

    startDate: null,
    endDate: null,

    recurrenceType: null, // Nothing selected yet
    isCurrentSemesterInProgress: false,
    isCurrentSemesterNoClassesInProgress: false,
    hasNoClasses: false,
    startDateRecurring: null,
    endDateRecurring: null,
    repetitionInterval: 0,
    recurrenceStartDay: 0,
    startTime: DateTimeFormatConfig.standardPickerTimeFormat(),
    startTimeError: false,
    recurrenceEndDay: 0,
    endTime: DateTimeFormatConfig.standardPickerTimeFormat(dayjs().add(2, 'hour')),
    endTimeError: false,
    disabledDaysPickup: [],
    disabledDaysDropoff: []
    // specificationFilters: []
};

export const slice = createSlice({
    name: 'filters',
    initialState,

    reducers: {
        setLocation: (state, action) => {
            state.location = action.payload;
        },
        setCategory: (state, action) => {
            state.category = action.payload;
        },
        setCategoryPath: (state, action) => {
            state.categoryPath = action.payload;
        },

        setSearch: (state, action) => {
            state.search = action.payload;
        },
        setSearchCriteria: (state, action) => {
            state.searchCriteria = action.payload;
        },
        
        setRecurring: (state, action) => {
            state.recurring = action.payload;
        },

        setStartDate: (state, action) => {
            state.startDate = action.payload;
        },
        setEndDate: (state, action) => {
            state.endDate = action.payload;
        },

        setRecurrenceType: (state, action) => {
            state.recurrenceType = action.payload;
        },
        setIsCurrentSemesterInProgress: (state, action) => {
            state.isCurrentSemesterInProgress = action.payload;
        },
        setIsCurrentSemesterNoClassesInProgress: (state, action) => {
            state.isCurrentSemesterNoClassesInProgress = action.payload;
        },
        setHasNoClasses: (state, action) => {
            state.hasNoClasses = action.payload;
        },
        setStartDateRecurring: (state, action) => {
            state.startDateRecurring = action.payload;
        },
        setEndDateRecurring: (state, action) => {
            state.endDateRecurring = action.payload;
        },
        setRepetitionInterval: (state, action) => {
            state.repetitionInterval = action.payload;
        },
        setRecurrenceStartDay: (state, action) => {
            state.recurrenceStartDay = action.payload;
        },
        setRecurrenceStartTime: (state, action) => {
            state.startTime = action.payload;
        },
        setRecurrenceStartTimeError: (state, action) => {
            state.startTimeError = action.payload;
        },
        setRecurrenceEndDay: (state, action) => {
            state.recurrenceEndDay = action.payload;
        },
        setRecurrenceEndTime: (state, action) => {
            state.endTime = action.payload;
        },
        setRecurrenceEndTimeError: (state, action) => {
            state.endTimeError = action.payload;
        },
        setDisabledDaysPickup: (state, action) => {
            state.disabledDaysPickup = action.payload;
        },
        setDisabledDaysDropoff: (state, action) => {
            state.disabledDaysDropoff = action.payload;
        },
        setWorkingHoursForSelectedPeriod: (state, action) => {
            state.workingHoursForSelectedPeriod = action.payload;
        },
        setOutsideWorkingHoursPickup: (state, action) => {
            state.outsideWorkingHoursPickup = action.payload;
        },
        setOutsideWorkingHoursDropoff: (state, action) => {
            state.outsideWorkingHoursDropoff = action.payload;
        },
        setOutsideWorkingHoursPickupError: (state, action) => {
            state.outsideWorkingHoursPickupError = action.payload;
        },
        setOutsideWorkingHoursDropoffError: (state, action) => {
            state.outsideWorkingHoursDropoffError = action.payload;
        },
        setStartPickerEnabledIntervals: (state, action) => {
            state.startPickerEnabledIntervals = action.payload;
        },
        setEndPickerEnabledIntervals: (state, action) => {
            state.endPickerEnabledIntervals = action.payload;
        },
        setStartPickerEnabledHoursForDay: (state, action) => {
            state.startPickerEnabledHoursForDay = action.payload;
        },
        setEndPickerEnabledHoursForDay: (state, action) => {
            state.endPickerEnabledHoursForDay = action.payload;
        },
        setRecurrenceStartTimePickerEnabledHoursForDay: (state, action) => {
            state.recurrenceStartTimePickerEnabledHoursForDay = action.payload;
        },
        setRecurrenceEndTimePickerEnabledHoursForDay: (state, action) => {
            state.recurrenceEndTimePickerEnabledHoursForDay = action.payload;
        },

        addSpecificationsFilter: (state, action) => {
            if(action.payload.value !== '') {
                let newFilter = {};
                newFilter[action.payload.name] = action.payload.value;
                state.specificationsFilters = {...state.specificationsFilters, ...newFilter};
            } else {
                const {[action.payload.name]: _, ...remainaingFilters} = state.specificationsFilters;
                state.specificationsFilters = remainaingFilters;
            }
        },
    }
});

export const { 
    setLocation, 
    setCategory, 
    setCategoryPath,

    setSearch,
    setSearchCriteria,

    setRecurring,

    setStartDate, 
    setEndDate, 
    setRecurrenceType, 
    setIsCurrentSemesterInProgress, 
    setIsCurrentSemesterNoClassesInProgress, 
    setHasNoClasses, 
    setStartDateRecurring, 
    setEndDateRecurring,
    setRepetitionInterval,
    setRecurrenceStartDay,
    setRecurrenceStartTime,
    setRecurrenceStartTimeError,
    setRecurrenceEndDay,
    setRecurrenceEndTime,
    setRecurrenceEndTimeError,
    setDisabledDaysPickup,
    setDisabledDaysDropoff,
    setWorkingHoursForSelectedPeriod,
    setOutsideWorkingHoursPickup,
    setOutsideWorkingHoursDropoff,
    setOutsideWorkingHoursPickupError,
    setOutsideWorkingHoursDropoffError,
    setStartPickerEnabledIntervals,
    setEndPickerEnabledIntervals,
    setStartPickerEnabledHoursForDay,
    setEndPickerEnabledHoursForDay,
    setRecurrenceStartTimePickerEnabledHoursForDay,
    setRecurrenceEndTimePickerEnabledHoursForDay,

    addSpecificationsFilter
} = slice.actions;

export function changeRecurrenceType(recurrenceType) {
    return (dispatch, getState) => {
        const state = getState();
        const hasExtendedTimes = state.settings.settings.extended_times || false;

        const recurrenceDetails = RecurrenceUtils.getRecurrenceDetails(recurrenceType, state.filters.repetitionInterval, state.filters.startDateRecurring, state.filters.endDateRecurring, state.filters.recurrenceStartDay, state.filters.startTime, state.filters.recurrenceEndDay, state.filters.endTime, state.settings.settings.working_hours, state.settings.settings.semester_settings, hasExtendedTimes);
        dispatch(setRecurrenceType(recurrenceDetails.recurrenceType));
        dispatch(setStartDateRecurring(recurrenceDetails.startDate));
        dispatch(setEndDateRecurring(recurrenceDetails.endDate));
        dispatch(setDisabledDaysPickup(recurrenceDetails.disabledDaysPickup));
        dispatch(setDisabledDaysDropoff(recurrenceDetails.disabledDaysDropoff));
        dispatch(setWorkingHoursForSelectedPeriod(recurrenceDetails.workingDaysForRecurrenceType));

        dispatch(setOutsideWorkingHoursPickup(recurrenceDetails.outsideWorkingHoursPickup));
        dispatch(setOutsideWorkingHoursDropoff(recurrenceDetails.outsideWorkingHoursDropoff));
        dispatch(setOutsideWorkingHoursPickupError(recurrenceDetails.outsideWorkingHoursPickupError));
        dispatch(setOutsideWorkingHoursDropoffError(recurrenceDetails.outsideWorkingHoursDropoffError));

        // If the selected pickup day is disabled set as unselected
        if(ShopDatesInterval.checkIfSelectedDayIsDisabled(recurrenceDetails.recurrenceStartDay, recurrenceDetails.disabledDaysPickup)) {
            dispatch(setRecurrenceStartDay(0));
        }

        // If the selected dropoff day is disabled set as unselected
        if(ShopDatesInterval.checkIfSelectedDayIsDisabled(recurrenceDetails.recurrenceEndDay, recurrenceDetails.disabledDaysDropoff)) {
            dispatch(setRecurrenceEndDay(0));
        }
    };
}

export function changeRecurrenceStartDay(newValue) {
    return (dispatch, getState) => {
        const state = getState();
        const hasExtendedTimes = state.settings.settings.extended_times || false;

        dispatch(setRecurrenceStartDay(newValue));
        if(newValue !== 0 && state.filters.recurrenceEndDay !== 0) {
            const inWorkingHoursData = ShopDatesInterval.doWorkingHoursCheck(state.filters.startTime, state.filters.endTime, newValue, state.filters.recurrenceEndDay, state.filters.workingHoursForSelectedPeriod, hasExtendedTimes);
            dispatch(setOutsideWorkingHoursPickup(!inWorkingHoursData.inWorkingHoursPickup));
            dispatch(setOutsideWorkingHoursDropoff(!inWorkingHoursData.inWorkingHoursDropoff));
            dispatch(setOutsideWorkingHoursPickupError(inWorkingHoursData.inWorkingHoursErrorPickup));
            dispatch(setOutsideWorkingHoursDropoffError(inWorkingHoursData.inWorkingHoursErrorDropoff));
        }
    };
}

export function changeRecurrenceStartTime(newValue) {
    return (dispatch, getState) => {
        const state = getState();
        const hasExtendedTimes = state.settings.settings.extended_times || false;
        
        dispatch(setRecurrenceStartTime(newValue));
        if(state.filters.recurrenceStartDay !== 0 && state.filters.recurrenceEndDay !== 0) {
            const inWorkingHoursData = ShopDatesInterval.doWorkingHoursCheck(newValue, state.filters.endTime, state.filters.recurrenceStartDay, state.filters.recurrenceEndDay, state.filters.workingHoursForSelectedPeriod, hasExtendedTimes);
            dispatch(setOutsideWorkingHoursPickup(!inWorkingHoursData.inWorkingHoursPickup));
            dispatch(setOutsideWorkingHoursDropoff(!inWorkingHoursData.inWorkingHoursDropoff));
            dispatch(setOutsideWorkingHoursPickupError(inWorkingHoursData.inWorkingHoursErrorPickup));
            dispatch(setOutsideWorkingHoursDropoffError(inWorkingHoursData.inWorkingHoursErrorDropoff));
        }
    };
}

export function changeRecurrenceStartTimeError(newValue) {
    return (dispatch, getState) => {
        dispatch(setRecurrenceStartTimeError(newValue));
    };
}

export function changeRecurrenceEndDay(newValue) {
    return (dispatch, getState) => {
        const state = getState();
        const hasExtendedTimes = state.settings.settings.extended_times || false;
        
        dispatch(setRecurrenceEndDay(newValue));
        if(state.filters.recurrenceStartDay !== 0 && newValue !== 0) {
            const inWorkingHoursData = ShopDatesInterval.doWorkingHoursCheck(state.filters.startTime, state.filters.endTime, state.filters.recurrenceStartDay, newValue, state.filters.workingHoursForSelectedPeriod, hasExtendedTimes);
            dispatch(setOutsideWorkingHoursPickup(!inWorkingHoursData.inWorkingHoursPickup));
            dispatch(setOutsideWorkingHoursDropoff(!inWorkingHoursData.inWorkingHoursDropoff));
            dispatch(setOutsideWorkingHoursPickupError(inWorkingHoursData.inWorkingHoursErrorPickup));
            dispatch(setOutsideWorkingHoursDropoffError(inWorkingHoursData.inWorkingHoursErrorDropoff));
        }
    };
}

export function changeRecurrenceEndTime(newValue) {
    return (dispatch, getState) => {
        const state = getState();
        const hasExtendedTimes = state.settings.settings.extended_times || false;
        
        dispatch(setRecurrenceEndTime(newValue));
        if(state.filters.recurrenceStartDay !== 0 && state.filters.recurrenceEndDay !== 0) {
            const inWorkingHoursData = ShopDatesInterval.doWorkingHoursCheck(state.filters.startTime, newValue, state.filters.recurrenceStartDay, state.filters.recurrenceEndDay, state.filters.workingHoursForSelectedPeriod, hasExtendedTimes);
            dispatch(setOutsideWorkingHoursPickup(!inWorkingHoursData.inWorkingHoursPickup));
            dispatch(setOutsideWorkingHoursDropoff(!inWorkingHoursData.inWorkingHoursDropoff));
            dispatch(setOutsideWorkingHoursPickupError(inWorkingHoursData.inWorkingHoursErrorPickup));
            dispatch(setOutsideWorkingHoursDropoffError(inWorkingHoursData.inWorkingHoursErrorDropoff));
        }
    };
}

export function changeRecurrenceEndTimeError(newValue) {
    return (dispatch, getState) => {
        dispatch(setRecurrenceEndTimeError(newValue));
    };
}

export const selectLocation = state => state.filters.location;
export const selectNoLocationSelected = state => state.filters.location === "0";
export const selectCategory = state => state.filters.category;
export const selectCategoryPath = state => state.filters.categoryPath;
export const selectHasSelectedCategory = state => state.filters.category !== "";

export const selectSearchActive = state => state.filters.search;
export const selectSearchCriteria = state => state.filters.searchCriteria;
export const selectSpecificationsFilters = state => state.filters.specificationsFilters;

export const selectProductsAreFiltered = state => state.filters.search || Object.entries(state.filters.specificationsFilters).length > 0;

export const selectStartDate = state => state.filters.startDate;
export const selectEndDate = state => state.filters.endDate;

export const selectRecurrenceType = state => state.filters.recurrenceType;
export const selectIsCurrentSemesterInProgress = state => state.filters.isCurrentSemesterInProgress;
export const selectIsCurrentSemesterNoClassesInProgress = state => state.filters.isCurrentSemesterNoClassesInProgress;
export const selectHasNoClasses = state => state.filters.hasNoClasses;
export const selectStartDateRecurring = state => state.filters.startDateRecurring;
export const selectEndDateRecurring = state => state.filters.endDateRecurring;
export const selectRepetitionInterval = state => state.filters.repetitionInterval;
export const selectRecurrenceStartDay = state => state.filters.recurrenceStartDay;
export const selectRecurrenceStartTime = state => state.filters.startTime;
export const selectRecurrenceEndDay = state => state.filters.recurrenceEndDay;
export const selectRecurrenceEndTime = state => state.filters.endTime;
export const selectDisabledDaysPickup = state => state.filters.disabledDaysPickup;
export const selectDisabledDaysDropoff = state => state.filters.disabledDaysDropoff;
export const selectWorkingHoursForSelectedPeriod = state => state.filters.workingHoursForSelectedPeriod;
export const selectOutsideWorkingHours = state => state.filters.outsideWorkingHoursPickup || state.filters.outsideWorkingHoursDropoff;
export const selectOutsideWorkingHoursPickup = state => state.filters.outsideWorkingHoursPickup;
export const selectOutsideWorkingHoursDropoff = state => state.filters.outsideWorkingHoursDropoff;
export const selectOutsideWorkingHoursPickupError = state => state.filters.outsideWorkingHoursPickupError;
export const selectOutsideWorkingHoursDropoffError = state => state.filters.outsideWorkingHoursDropoffError;
export const selectStartPickerEnabledIntervals = state => state.filters.startPickerEnabledIntervals;
export const selectEndPickerEnabledIntervals = state => state.filters.endPickerEnabledIntervals;
export const selectStartPickerEnabledHoursForDay = state => state.filters.startPickerEnabledHoursForDay;
export const selectEndPickerEnabledHoursForDay = state => state.filters.endPickerEnabledHoursForDay;
export const selectRecurrenceStartTimePickerEnabledHoursForDay = state => state.filters.recurrenceStartTimePickerEnabledHoursForDay;
export const selectRecurrenceEndTimePickerEnabledHoursForDay = state => state.filters.recurrenceEndTimePickerEnabledHoursForDay;

export const selectRecurrenceHasErrors = state => ShopDatesInterval.recurrenceHasErrors(state.filters.recurring, state.filters.repetitionInterval, state.filters.recurrenceStartDay, state.filters.recurrenceEndDay, state.filters.startTime, state.filters.endTime, state.filters.startTimeError, state.filters.endTimeError, state.filters.outsideWorkingHoursPickup, state.filters.outsideWorkingHoursDropoff);
export const selectFromToHasErrors = state => ShopDatesInterval.fromToDatesHaveErrors(state.filters.recurring, state.filters.startDate, state.filters.endDate, state.settings.settings.working_hours, state.settings.settings.semester_settings);
export const selectRecurring = state => state.filters.recurring;

export default slice.reducer;