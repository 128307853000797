import React from 'react';

import { ReactRouterLink } from './core';

import Logo from './Logo';

export default function LogoAsLink(props) {
    const { to } = props;

    return (
        <ReactRouterLink to={to} sx={{display: 'flex', position: 'absolute'}} >
            <Logo position="relative" />
        </ReactRouterLink>
    );
}
