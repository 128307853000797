import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

import Button from './Button';

export default function SecondaryButton(props) {
    const { label, onClick = false, to = false, ...otherProps } = props;

    if (onClick) {
        otherProps.onClick = onClick;
    }

    if (to) {
        otherProps.to = to;
        otherProps.component = RouterLink;
    }

    return (
        <Button variant="outlined" size="small" {...otherProps}>
            { label }
        </Button>
    );
};