import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from "react-i18next";

import { Alert, Tab, Tabs, Tooltip, Typography } from '@mui/material';
import { EventRepeat, InsertInvitation } from '@mui/icons-material';

import { updateProduct } from '../../../store/productSlice';
import { updateCartItem, selectDisabledNonRecurringTab, selectDisabledRecurringTab } from '../../../store/cartSlice';
import { selectRecurring, setRecurring } from '../../../store/filtersSlice';

const CloneProps = (props) => {
    const { children, ...other } = props;
    return children(other);
}

function RequestPeriodTabs(props) {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { item = false, subarticle = false, isCartItem = false } = props;
    const recurringState = useSelector(selectRecurring);

    const recurring = subarticle?.recurring ?? item?.recurring ?? recurringState;
    const nonRecurringTabDisabled = useSelector(selectDisabledNonRecurringTab);
    const recurringTabDisabled = useSelector(selectDisabledRecurringTab);

    const [openRecurringTooltip, setOpenRecurringTooltip] = useState(false);
    const [openNonRecurringTooltip, setOpenNonRecurringTooltip] = useState(false);

    const handleCloseRecurringTooltip = () => {
        setOpenRecurringTooltip(false);
    };

    const handleOpenRecurringTooltip = () => {
        if (recurringTabDisabled) {
            setOpenRecurringTooltip(true);
        }
    };
    
    const handleCloseNonRecurringTooltip = () => {
        setOpenNonRecurringTooltip(false);
    };

    const handleOpenNonRecurringTooltip = () => {
        if (nonRecurringTabDisabled) {
            setOpenNonRecurringTooltip(true);
        }
    };

    const handleTabChange = (event, newValue) => {
        if(item) {
            if(isCartItem) {
                dispatch(updateCartItem({item, subarticle, field: "recurring", value: newValue}));
            } else {
                dispatch(updateProduct({item, subarticle, field: "recurring", value: newValue}));
            }
        } else {
            dispatch(setRecurring(newValue));
        }
    };

    return (
        <Tabs value={recurring} onChange={handleTabChange} aria-label="icon label tabs" >
            <CloneProps>  
                { tabProps => (
                    <Tooltip open={openNonRecurringTooltip} onClose={handleCloseNonRecurringTooltip} onOpen={handleOpenNonRecurringTooltip} placement="bottom-start" title={
                        <Alert severity="info">
                            {t("sidebar.tabs.recurringItemsInCart")}
                        </Alert>}
                    >
                        <span>
                            <Tab {...tabProps} value={0} disabled={nonRecurringTabDisabled} icon={<InsertInvitation />} label={ <Typography variant="headline">{t("sidebar.tabs.unique")}</Typography> } />
                        </span>
                    </Tooltip>
                )}
            </CloneProps>
            <CloneProps>  
                { tabProps => (
                    <Tooltip open={openRecurringTooltip} onClose={handleCloseRecurringTooltip} onOpen={handleOpenRecurringTooltip} placement="bottom-start" title={
                        <Alert severity="info">
                            {t("sidebar.tabs.nonRecurringItemsInCart")}
                        </Alert>}
                    >
                        <span>
                            <Tab {...tabProps} value={1} disabled={recurringTabDisabled} icon={<EventRepeat />} label={ <Typography variant="headline">{t("sidebar.tabs.recurring")}</Typography> } />
                        </span>
                    </Tooltip>
                )}
            </CloneProps>
        </Tabs>
    );
}

export default RequestPeriodTabs;