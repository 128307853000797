import dayjs from "dayjs";
import DateTimeFormatConfig from "../../../../../utils/DateTimeFormatConfig";
import RecurrenceOccurences from "../../../../../utils/RecurrenceOccurences";
import { ShopDatesInterval } from "../../../../../utils/ShopDatesInterval";
import WorkingDays from "../../../../../utils/WorkingDays";
import RecurrenceDays from "./RecurrenceDays";

export class RecurrenceUtils {

    static getRecurrenceDetails(recurrenceType, repetitionInterval, startDateRecurring, endDateRecurring, recurrenceStartDay, startTime, recurrenceEndDay, endTime, workingHours, semesterSettings, hasExtendedTimes) {
        let recurrenceDetails = {
            recurrenceType,
            startDateRecurring,
            endDateRecurring,
            recurrenceStartDay,
            recurrenceEndDay,
            // If we will need this in the future
            // workingHours,
            // semesterSettings
        };
        if(recurrenceType !== null) {
            // Set start/end dates for selected recurring interval
            const calculatedDates = ShopDatesInterval.getStartDateEndDateForRecurrenceType(recurrenceType, startDateRecurring, endDateRecurring, semesterSettings);
            recurrenceDetails.startDate = calculatedDates.startDate;
            recurrenceDetails.endDate = calculatedDates.endDate;

            // Set disabled days for selected recurring interval
            const disabledDaysPickup = ShopDatesInterval.setDaysForRental(workingHours, recurrenceType, RecurrenceDays.WORKING_HOURS_KEY_FOR_PICKUP);
            recurrenceDetails.disabledDaysPickup = disabledDaysPickup;

            const disabledDaysDropoff = ShopDatesInterval.setDaysForRental(workingHours, recurrenceType, RecurrenceDays.WORKING_HOURS_KEY_FOR_DROPOFF);
            recurrenceDetails.disabledDaysDropoff = disabledDaysDropoff;

            const workingDaysForRecurrenceType = WorkingDays.getWorkingDaysForDates(workingHours, semesterSettings, calculatedDates.startDate, calculatedDates.endDate);
            recurrenceDetails.workingDaysForRecurrenceType = workingDaysForRecurrenceType;

            recurrenceDetails.outsideWorkingHours = true;
            recurrenceDetails.outsideWorkingHoursPickup = true;
            recurrenceDetails.outsideWorkingHoursDropoff = true;
            
            recurrenceDetails.outsideWorkingHoursError = "";
            recurrenceDetails.outsideWorkingHoursPickupError = "";
            recurrenceDetails.outsideWorkingHoursDropoffError = "";
            if(recurrenceStartDay !== 0 && recurrenceEndDay !== 0 && DateTimeFormatConfig.timeOnlyStringIsValid(startTime) && DateTimeFormatConfig.timeOnlyStringIsValid(endTime)) {
                const inWorkingHoursData = ShopDatesInterval.doWorkingHoursCheck(startTime, endTime, recurrenceStartDay, recurrenceEndDay, workingDaysForRecurrenceType, hasExtendedTimes);
                recurrenceDetails.outsideWorkingHours = !inWorkingHoursData.inWorkingHours;
                recurrenceDetails.outsideWorkingHoursPickup = !inWorkingHoursData.inWorkingHoursPickup;
                recurrenceDetails.outsideWorkingHoursDropoff = !inWorkingHoursData.inWorkingHoursDropoff;
                
                recurrenceDetails.outsideWorkingHoursError = inWorkingHoursData.inWorkingHoursError;
                recurrenceDetails.outsideWorkingHoursPickupError = inWorkingHoursData.inWorkingHoursErrorPickup;
                recurrenceDetails.outsideWorkingHoursDropoffError = inWorkingHoursData.inWorkingHoursErrorDropoff;
            }
        }
        return recurrenceDetails;
    }

    static changeRecurrenceForItem(itemToChange, state, hasExtendedTimes) {
        let changedEntity = {...itemToChange};

        changedEntity.recurring = changedEntity.recurring ?? state.filters.recurring;

        changedEntity.startDateRecurring = changedEntity.startDateRecurring ?? state.filters.startDateRecurring;
        changedEntity.endDateRecurring = changedEntity.endDateRecurring ?? state.filters.endDateRecurring;


        changedEntity.recurrenceType = changedEntity.recurrenceType ?? state.filters.recurrenceType;
        changedEntity.startTime = changedEntity.startTime ?? state.filters.startTime;
        changedEntity.startTimeError = changedEntity.startTimeError ?? state.filters.startTimeError;
        changedEntity.endTime = changedEntity.endTime ?? state.filters.endTime;
        changedEntity.endTimeError = changedEntity.endTimeError ?? state.filters.endTimeError;
        changedEntity.repetitionInterval = changedEntity.repetitionInterval ?? state.filters.repetitionInterval;
        changedEntity.recurrenceStartDay = changedEntity.recurrenceStartDay ?? state.filters.recurrenceStartDay;
        changedEntity.recurrenceEndDay = changedEntity.recurrenceEndDay ?? state.filters.recurrenceEndDay;

        if(changedEntity.repetitionInterval !== 0 && changedEntity.recurrenceStartDay !== 0 && changedEntity.recurrenceEndDay !== 0 && !changedEntity.startTimeError && !changedEntity.endTimeError) {
            const recurrenceDetails = RecurrenceUtils.getRecurrenceDetails(changedEntity.recurrenceType, changedEntity.repetitionInterval, changedEntity.startDateRecurring, changedEntity.endDateRecurring, changedEntity.recurrenceStartDay, changedEntity.startTime, changedEntity.recurrenceEndDay, changedEntity.endTime, state.settings.settings.working_hours, state.settings.settings.semester_settings, hasExtendedTimes);
            changedEntity.startDateRecurring = recurrenceDetails.startDate;
            changedEntity.endDateRecurring = recurrenceDetails.endDate;
            changedEntity.disabledDaysPickup = recurrenceDetails.disabledDaysPickup;
            changedEntity.disabledDaysDropoff = recurrenceDetails.disabledDaysDropoff;

            changedEntity.workingDaysForRecurrenceType = recurrenceDetails.workingDaysForRecurrenceType;
            changedEntity.outsideWorkingHoursPickup = recurrenceDetails.outsideWorkingHoursPickup;
            changedEntity.outsideWorkingHoursDropoff = recurrenceDetails.outsideWorkingHoursDropoff;
            changedEntity.outsideWorkingHoursPickupError = recurrenceDetails.outsideWorkingHoursPickupError;
            changedEntity.outsideWorkingHoursDropoffError = recurrenceDetails.outsideWorkingHoursDropoffError;

            // If the selected pickup day is disabled set as unselected
            if(ShopDatesInterval.checkIfSelectedDayIsDisabled(recurrenceDetails.recurrenceStartDay, recurrenceDetails.disabledDaysPickup)) {
                changedEntity.recurrenceStartDay = 0;
            }

            // If the selected dropoff day is disabled set as unselected
            if(ShopDatesInterval.checkIfSelectedDayIsDisabled(recurrenceDetails.recurrenceEndDay, recurrenceDetails.disabledDaysDropoff)) {
                changedEntity.recurrenceEndDay = 0;
            }
        }
        
        changedEntity.hasFromToErrors = ShopDatesInterval.fromToDatesHaveErrors(changedEntity.recurring, changedEntity.startDate, changedEntity.endDate, state.settings.settings.working_hours, state.settings.settings.semester_settings);
        changedEntity.hasRecurrenceErrors = ShopDatesInterval.recurrenceHasErrors(changedEntity.recurring, changedEntity.repetitionInterval, changedEntity.recurrenceStartDay, changedEntity.recurrenceEndDay, changedEntity.startTime, changedEntity.endTime, changedEntity.startTimeError, changedEntity.endTimeError, changedEntity.outsideWorkingHoursPickup, changedEntity.outsideWorkingHoursDropoff);

        if(!(changedEntity.hasRecurrenceErrors || changedEntity.hasFromToErrors)) {
            changedEntity = this.prepareOccurencesForItem(changedEntity);
        }

        return changedEntity;
    }

    static prepareOccurencesForItem(itemToPrepare) {
        let {occurence, ...item} = itemToPrepare;
        
        if(!item.recurring) { return item }

        const startDate = item.startDateRecurring;
        const endDate = item.endDateRecurring;

        let occurences = RecurrenceOccurences.calculateOccurence(startDate, endDate, item.repetitionInterval, item.recurrenceStartDay, item.recurrenceEndDay);
        let unique_stamp = dayjs().format("X");
        item.occurence = [];
        let occ = JSON.stringify(item);

        if (occurences.length > 0) {
            occurences.forEach(occurence => {

                let parseOcc = JSON.parse(occ);

                parseOcc.startDate = DateTimeFormatConfig.standardPickerDateFormat(occurence.startDate + ' ' + item.startTime);
                parseOcc.endDate = DateTimeFormatConfig.standardPickerDateFormat(occurence.endDate + ' ' + item.endTime);
                parseOcc.resId = unique_stamp;

                item.occurence.push(parseOcc)
            });
        }

        return item;
    }

    static changeSubarticlesData(subarticles, newItemDates, state, hasExtendedTimes) {
        for (const [id, subarticle] of Object.entries(subarticles)) {
            let changedSubarticle = {...subarticle, ...newItemDates};
            if(!changedSubarticle.recurring) {
                subarticles[id] = changedSubarticle;
            } else {
                subarticles[id] = RecurrenceUtils.changeRecurrenceForItem(changedSubarticle, state, hasExtendedTimes);
            }
        }

        return subarticles;
    }
}