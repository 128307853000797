import { Stack, Typography } from '@mui/material';
import React from 'react';

import MasonrySection from '../../common/ui/qvshop/components/MasonrySection';
import ProductCardSkeleton from '../../common/ui/qvshop/components/ProductCardSkeleton';
import SubarticleSection from './SubarticleSection';

export default function SubarticlesMasonry(props) {
    const { title, product, loading, loadingAvailability } = props;

    return (
        <Stack direction='column' spacing={2} width="100%">
            <Typography variant="h6">
                {title}
            </Typography>
            <MasonrySection breakpointCols={{
                    default: 3,
                    720: 1,
                    1050: 2,
                    1380: 3,
                }} 
                items={ Object.values(product.subarticles) }
                loading={loading}
                skeleton={ ProductCardSkeleton } 
                card={ SubarticleSection } 
                product={ product } 
                loadingAvailability={ loadingAvailability } 
                />
        </Stack>
    );
};