import dayjs from 'dayjs';
import apiCustomUrl from './ApiCustomUrl';

class ShopConfig {

	static baseUrl() {

		// let siteUrl;
		let finalUrl = window.location.protocol + '//' + window.location.host + "/";

		if(apiCustomUrl.backend_url !== false) {
			return apiCustomUrl.backend_url;
		}

		// if(window.location.host.indexOf('localhost') >= 0) {
		// 	return "http://quickverleih.local/";
		// }

		// if (window.location.host.indexOf('-shop.') >=0) {

		// 	 siteUrl  = window.location.protocol + '//' + window.location.host.replace(/-?shop\./, '.');
		// 	 siteUrl  = siteUrl.replace(/:3000/, '');
		// 	 finalUrl = (siteUrl + '/').replace(/\/*$/, '/'); // add final slash

		// } else {
		// 	 siteUrl  = window.location.protocol + '//' + window.location.host.replace('.', '-backend.');
		// 	 finalUrl = (siteUrl + '/'); // add final slash
		// }

		// console.log("final base URL: ", finalUrl);
		
		return finalUrl;
	}

	static config() {
		const BASE_URL = ShopConfig.baseUrl();

		const PRODUCT_IMAGES_LOCATION = "product_images/";
		const SHOP_FILES_LOCATION = "shop_images/";
		const ATTACHMENT_LOCATION = 'attachements/';

		const getURLFor = (address, disableCache = false) => {
			return BASE_URL+address+(disableCache ? "?c="+dayjs().unix() : '')
		}

		const getURLForProductImage = (image) => {
			return getURLFor(PRODUCT_IMAGES_LOCATION+image)
		}

		const getURLForZoomedProductImage = (image) => {
			// _small, _big and _orig are the suffixes defined in backend (ArticleImage)
			// for now make the replacement here, but it should be done in backend
			image = image.replace('_big', '_orig');
			image = image.replace('_small', '_orig');

			return getURLFor(PRODUCT_IMAGES_LOCATION+image)
		}

		const getURLForWelcomeImage = (file) => {
			return getURLFor(SHOP_FILES_LOCATION+file)
		}

		const getURLForAttachmentFile = (file) => {
			return getURLFor(ATTACHMENT_LOCATION+file)
		}

		return {
			BASE_URL,
			PRODUCT_IMAGES_LOCATION,
			getURLFor,
			getURLForProductImage,
			getURLForZoomedProductImage,
			getURLForWelcomeImage,
			getURLForAttachmentFile
		}
	}
}

export default ShopConfig;
