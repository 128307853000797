import {
    Routes,
    Route,
    Navigate,
} from "react-router-dom";

// rc: these names do not make sense. rename!
import { PublicRoute } from './conditioners/PublicRoute';
import { ProtectedRoute } from './conditioners/ProtectedRoute';
import { ProtectedLayout } from './conditioners/ProtectedLayout';
import { CheckoutLayout } from './conditioners/CheckoutLayout';

import MainLayout from "../common/ui/qvshop/layout/MainLayout";

import LoginView from "../login/LoginView";
import PasswordForgotten from "../passwordForgotten/PasswordForgotten";
import Register from "../register/Register";
import ProductsListView from "../productslist/ProductsListView";
import ProductDetailsView from "../productdetails/ProductDetailsView";
import Cart from "../cart/Cart";
import Checkout from "../checkout/Checkout";
import RentalProcessFinished from "../rentalProcessFinished/RentalProcessFinished";
import RegisterFinished from "../registerFinished/RegisterFinished";

export default function QVRouter() {

    return (
        <Routes>
            <Route path="/login" element={<PublicRoute><LoginView /></PublicRoute>} />
            <Route path="/passwordForgotten" element={<PublicRoute><PasswordForgotten /></PublicRoute>} />
            <Route path="/register" element={<PublicRoute><Register /></PublicRoute>} />
            <Route path="/register/success" element={<PublicRoute><RegisterFinished /></PublicRoute>} />

            { /* Protected routes - optional login */}
            <Route path="/" element={<ProtectedLayout />}>
                <Route path="" element={<Navigate replace to="/items/" />}/>

                <Route path="cart" element={<MainLayout />}>
                    <Route path="" element={<Cart />} />
                </Route>

                { /* routes */}
                <Route path="" element={<MainLayout />}>
                    { /* Redirects for incomplete routes */}
                    <Route path="items" element={<Navigate replace to="/items/" />}/>
                    <Route path="itemdetails" element={<Navigate replace to="/items/" />}/>

                    { /* Actual routes */}
                    <Route path="items/" element={<ProtectedRoute><ProductsListView /></ProtectedRoute>} />
                    <Route path="items/:categoryId" element={<ProtectedRoute><ProductsListView /></ProtectedRoute>} />
                    <Route path="itemdetails/:productId" element={<ProtectedRoute><ProductDetailsView /></ProtectedRoute>} />
                </Route>
            </Route>

            { /* checkout route - mandatory login, either at the first page or going to checkout */}
            <Route path="/checkout" element={<CheckoutLayout />}>
                <Route path="" element={<MainLayout />}>
                    <Route path="" element={<Checkout />} />
                    <Route path="success" element={<RentalProcessFinished />} />
                </Route>
            </Route>
        </Routes>
    );
}