import React from 'react';
import { useTranslation } from 'react-i18next';

import { FormControl, FormHelperText, InputLabel, MenuItem } from '@mui/material';
import MUISelect from '@mui/material/Select';

export default function Select(props) {
    const { t } = useTranslation();
    const { containerKey, sx, id, label, options, value, onChange, helperText = "", ...otherProps } = props;

    const buildOptions = (option) => {
        let id = option;
        let name = option;
        let disabled = false;
        if(typeof option === "object" ) {
            id = option.id;
            name = option.name;
            disabled = option.disabled;
        }

        return (
            <MenuItem key={id+"_"+name} value={id} disabled={disabled}>{t(name)}</MenuItem>
        );
    }

    return (
        <FormControl key={containerKey ?? ""} sx={sx ?? {}}>
            <InputLabel id={"select-"+id+"-label"}>{label}</InputLabel>
            <MUISelect
                labelId={"select-"+id+"-label"}
                id={"select-"+id}
                name={id}
                value={ value ?? "" }
                label={label}
                onChange={onChange}
                { ...otherProps }
                >
                    { options.map(buildOptions) }
            </MUISelect>
            <FormHelperText size="small" >{helperText}</FormHelperText>
        </FormControl>
    );
};