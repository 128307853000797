import React from 'react';
import { useTranslation } from "react-i18next";

import { List, ListSubheader, Stack, Typography } from '@mui/material';
import ReservationSection from '../../ReservationSection';

export default function IntervalsSelectionSection() {
    const { t } = useTranslation();

    return (
        <List component="nav"
            aria-labelledby="subheader-request-period"
            subheader={
                <ListSubheader component="div" id="subheader-location" disableSticky={true}>
                    <Stack alignItems="center" direction="row" spacing={1}>
                        <Typography variant="headline" component="h5">{t("sidebar.requestPeriod")}</Typography>
                    </Stack>
                </ListSubheader>
            }>
            <ReservationSection direction="column" withAvailability={false} />
        </List>
    );
}