import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from "react-i18next";

import Select from './Select';

import { Storage } from '../../../utils/Storage';

import { selectUserLocations } from '../../../store/locationsSlice';
import { setLocation, selectLocation, setCategory } from '../../../store/filtersSlice';
import { getCategories } from '../../../store/categoriesSlice';
import { getReturnKey } from '../../../store/cartSlice';
import { getSettings } from '../../../store/settingsSlice';

export default function SelectLocation(props) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { sx } = props;
    const userLocations = useSelector(selectUserLocations);
    const location = useSelector(selectLocation);

    const handleLocationChange = (event) => {
        // Also save the new location id in local storage
        Storage.setLocationId(event.target.value);
        dispatch(setLocation(event.target.value));

        dispatch(getSettings());

        dispatch(getCategories());
        dispatch(getReturnKey());

        dispatch(setCategory("X"));
        navigate("/items");
    };

    return (
        <Select
            sx={sx}
            id="location"
            label={t("location.select.label")}
            value={location}
            onChange={handleLocationChange}
            options={[{id: "0", name: "bitte auswahlen..."}, ...userLocations]}
        />
    );
}