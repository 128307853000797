import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Stack } from '@mui/material';

import Select from "./Select";
import { getProductsCount, selectSpecifications } from '../../../store/productsSlice';
import { addSpecificationsFilter, selectSpecificationsFilters } from '../../../store/filtersSlice';

export default function SpecificationsFilter() {
    const dispatch = useDispatch();

    const specifications = useSelector(selectSpecifications);
    const specificationsFilters = useSelector(selectSpecificationsFilters);

    const handleFilterChange = (event) => {
        const { name, value } = event.target;
        dispatch(addSpecificationsFilter({name, value}));
        dispatch(getProductsCount());
    };

    const buildSpecificationsFilter = (specification, i) => {
        return (
            <Select 
                key={specification+"_"+i}
                sx={{ minWidth: 120 }} 
                id={specification.attribute}
                label={specification.attribute} 
                value={ specificationsFilters && specificationsFilters[specification.attribute] ? specificationsFilters[specification.attribute] : "" }
                onChange={handleFilterChange}
                options={specification.values}
                />
        );
    }

    return (
        <Stack direction='column' spacing={2}>
            { specifications.map(buildSpecificationsFilter) }
        </Stack>
    );
};