import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from "react-i18next";
import he from 'he';

import {
    Link as RouterLink,
    useLocation,
} from 'react-router-dom';

import { Link, Typography } from '@mui/material';
import MUIBreadcrumbs from '@mui/material/Breadcrumbs';

import { selectLocationSelected } from '../../../store/locationsSlice';
import { selectCategory, selectCategoryPath } from '../../../store/filtersSlice';
import { selectProduct } from '../../../store/productSlice';
import { CategoriesUtils } from '../../../utils/CategoriesUtils';
import { selectCategories } from '../../../store/categoriesSlice';

function LinkRouter(props) {
    return <Link {...props} component={RouterLink} />;
}

export default function Breadcrumbs() {
    const { t } = useTranslation(['common', 'cart', 'checkout', 'rentalProcessFinished']);
    const location = useLocation();
    const selectedLocation = useSelector(selectLocationSelected);
    const selectedCategory = useSelector(selectCategory);
    const categories = useSelector(selectCategories);
    const selectedCategoryPath = useSelector(selectCategoryPath);
    const product = useSelector(selectProduct);
    const categoryPath = product?.idkat ? CategoriesUtils.prepareCategoryPath(categories, product.idkat) : selectedCategoryPath;
    const pathnames = location.pathname.split('/').filter((x) => x);

    const breadcrumbNameMap = {
        '/itemdetails/': product ? he.decode(product.name) : "",
        '/cart': t("cart:cart"),
        '/checkout': t("checkout:checkout.title"),
        '/success': t("rentalProcessFinished:title"),
    };
    
    return (
        <MUIBreadcrumbs aria-label="breadcrumb">
            { /* Location name if locations are defined and one selected */}
            { selectedLocation ? <LinkRouter underline="always" color="inherit" to={"/items/"+selectedCategory} >
                {selectedLocation.name}
            </LinkRouter> : null }
            { /* 
                Category of the product for details page
                Not sure what else should show
            */ }
            { categoryPath.map(value => {
                return (
                    <LinkRouter underline="always" color="inherit" to={value.to} key={value.to}>
                        {value.name}
                    </LinkRouter>
                );
            }) }
            {pathnames.map((value, index) => {
                const last = index === pathnames.length - 1;
                const to = `/${pathnames.slice(0, index + 1).join('/')}`;
                const routeIdentifier = to.replace(/[0-9]/g, '');
                
                return last ? (
                    <Typography color="text.primary" key={to}>
                        {breadcrumbNameMap[routeIdentifier]}
                    </Typography>
                ) : null;
            })}
        </MUIBreadcrumbs>
    );
}
