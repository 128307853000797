export const mainContentContainer = { 
    display: 'flex', 
    flexDirection: "column", 
    height: '100vh', 
    overflow: 'hidden' 
};

export const mainContent = {
    pt: 2,
    backgroundColor: "shopGrey.background",
};