import React from 'react';
import { useSelector } from 'react-redux';
import {
    Navigate,
    useLocation,
} from 'react-router-dom';

import { useAuth } from "../../common/hooks/useAuth";

import { selectLoginInCheckout } from '../../common/store/settingsSlice';
import { selectUserHasLocations } from '../../common/store/locationsSlice';

export const ProtectedRoute = ({ children }) => {
    const { token } = useAuth();
    const loginOnlyInCheckout = useSelector(selectLoginInCheckout);
    const userHasLocations = useSelector(selectUserHasLocations);
    const location = useLocation();

    const hasCredentials = token && userHasLocations;
        
    if (!hasCredentials && !loginOnlyInCheckout) {
        return <Navigate to="/login" state={{ from: location }} />;
    }

    return children;
};