import dayjs from 'dayjs';
import DateTimeFormatConfig from './DateTimeFormatConfig';
import { ShopDatesInterval } from './ShopDatesInterval';
import RecurrenceDays from '../ui/qvshop/components/recurringIntervalSelector/utils/RecurrenceDays';

var isSameOrBefore = require('dayjs/plugin/isSameOrBefore')
dayjs.extend(isSameOrBefore);

var isBetween = require('dayjs/plugin/isBetween')
dayjs.extend(isBetween);

var weekday = require('dayjs/plugin/weekday')
dayjs.extend(weekday);

class WorkingDays {

    // Used to disable days in the shop recurring tab days of week dropdown
    // Disable the day if it's disabled for any of the pickup/dropoff for that day
    // classesNoClasses can be 'classes'/'no_classes'
    // pickupDropoff can be 'pickup'/'dropoff'/'all' - all means the days are considered from both
    static  setDaysForRental(wh, classesNoClasses, pickupDropoff = RecurrenceDays.WORKING_HOURS_KEY_FOR_ALL) {
        let disabledDays = [];

        let openHoursForInterval = wh[classesNoClasses];

        if(pickupDropoff === RecurrenceDays.WORKING_HOURS_KEY_FOR_ALL || pickupDropoff === RecurrenceDays.WORKING_HOURS_KEY_FOR_PICKUP)
        {
            const workingDays = openHoursForInterval[RecurrenceDays.WORKING_HOURS_KEY_FOR_PICKUP];
            workingDays.forEach(function (day, index) {
                if (day['von1'] === 'false') {
                    disabledDays.push(index + 1);
                }
            });
        }

        if(pickupDropoff === RecurrenceDays.WORKING_HOURS_KEY_FOR_ALL || pickupDropoff === RecurrenceDays.WORKING_HOURS_KEY_FOR_DROPOFF)
        {
            const workingDays = openHoursForInterval[RecurrenceDays.WORKING_HOURS_KEY_FOR_DROPOFF];
            workingDays.forEach(function (day, index) {
                if (day['von1'] === 'false') {
                    disabledDays.push(index + 1);
                }
            });
        }

        return disabledDays;
    }

    // pickupDropoff can be 'pickup'/'dropoff'/'all'
    static  getWorkingDaysForDates(workingDays, semesterDur, startDate, endDate, pickupDropoff = RecurrenceDays.WORKING_HOURS_KEY_FOR_ALL) {
        let sDate = DateTimeFormatConfig.getDateFromStandardPickerDateFormat(startDate);
        let eDate = DateTimeFormatConfig.getDateFromStandardPickerDateFormat(endDate);
        // console.log("getWorkingDaysForDates - Working days: ", workingDays);
        // console.log("getWorkingDaysForDates - sDate: ", sDate);
        // console.log("getWorkingDaysForDates - eDate: ", eDate);

        let referenceDateFull = sDate;
        if(pickupDropoff === RecurrenceDays.WORKING_HOURS_KEY_FOR_DROPOFF) {
            referenceDateFull = eDate;
        }

        // console.log("getWorkingDaysForDates - referenceDate: ", referenceDateFull);

        let referenceDate = referenceDateFull.format('MMDD');
        // console.log("getWorkingDaysForDates - referenceMonthDay: ", referenceDate);


        let summer_start_date = dayjs(semesterDur.summer_start_date).format('MMDD');
        let summer_end_date = dayjs(semesterDur.summer_end_date).format('MMDD');
        let no_classes_summer_start_date = dayjs(semesterDur.no_classes_summer_start_date).format('MMDD');
        let no_classes_summer_end_date = dayjs(semesterDur.no_classes_summer_end_date).format('MMDD');

        let winter_start_date = dayjs(semesterDur.winter_start_date).format('MMDD');
        let winter_end_date = dayjs(semesterDur.winter_end_date).format('MMDD');
        let no_classes_winter_start_date = dayjs(semesterDur.no_classes_winter_start_date).format('MMDD');
        let no_classes_winter_end_date = dayjs(semesterDur.no_classes_winter_end_date).format('MMDD');

        // console.log("getWorkingDaysForDates - summer_start_date: ", summer_start_date);
        // console.log("getWorkingDaysForDates - summer_end_date: ", summer_end_date);
        // console.log("getWorkingDaysForDates - no_classes_summer_start_date: ", no_classes_summer_start_date);
        // console.log("getWorkingDaysForDates - no_classes_summer_end_date: ", no_classes_summer_end_date);
        // console.log("getWorkingDaysForDates - winter_start_date: ", winter_start_date);
        // console.log("getWorkingDaysForDates - winter_end_date: ", winter_end_date);
        // console.log("getWorkingDaysForDates - no_classes_winter_start_date: ", no_classes_winter_start_date);
        // console.log("getWorkingDaysForDates - no_classes_winter_end_date: ", no_classes_winter_end_date);

        let schedule = workingDays;
        if (referenceDate >= summer_start_date && referenceDate <= summer_end_date) {
            // console.log("Summer clases: ", workingDays.classes);
            schedule = workingDays.classes;
        } else if (referenceDate >= no_classes_summer_start_date && referenceDate <= no_classes_summer_end_date) {
            // console.log("Summer NO clases: ", workingDays.no_classes);
            schedule = workingDays.no_classes;
        } else if (referenceDate >= winter_start_date && referenceDate <= winter_end_date) {
            // console.log("Winter clases: ", workingDays.classes);
            schedule = workingDays.classes;
        } else if (referenceDate >= no_classes_winter_start_date && referenceDate <= no_classes_winter_end_date) {
            // console.log("Winter NO clases: ", workingDays.no_classes);
            schedule = workingDays.no_classes;
        } else {
            // console.log("Outside any  of the intervals - should be disabled");
            schedule = workingDays.classes;
        }

        switch (pickupDropoff) {
            case RecurrenceDays.WORKING_HOURS_KEY_FOR_PICKUP:
                return schedule.pickup;
        
            case RecurrenceDays.WORKING_HOURS_KEY_FOR_DROPOFF:
                return schedule.dropoff;
        
            case RecurrenceDays.WORKING_HOURS_KEY_FOR_ALL:
                return schedule;

            default:
                return schedule;
        }
    }

    static _getNonWorkingDaysInInterval(startDate, endDate, workingDays) {
        const disabledDaysPerWeek = [];

        workingDays.forEach(function (day, index) {
            if (day['von1'] === 'false') {
                disabledDaysPerWeek.push(index);
            }
        });

        const disabledNonWorkingDays = [];

        // iterate between the startDate and endDate
        var iterationDate = startDate.clone();

        while(iterationDate.isSameOrBefore(endDate)) {
            // check if the iteration day is in the disabled days
            if(disabledDaysPerWeek.includes(iterationDate.weekday())) {
                // this is the format expected by the date picker, month is zero based: [2021,9,21,'inverted'],
                disabledNonWorkingDays.push(
                    DateTimeFormatConfig.formatDateToDateOnlyString(iterationDate)
                )
            }

            iterationDate = iterationDate.add(1, 'd');
        }

        return disabledNonWorkingDays;
    }

    static _generateEntriesForInterval(wh, semesterSettings, enabledIntervalsWithDisabledDays, now, startDate, endDate, workingDays, pickupDropoff) {
        // the whole interval is in the past, ignore (can happen if we are already after the interval in a year, eg. in October generating for the summer semester of same year)
        if(endDate < now) {
            return;
        }

        // start with today, if the start date is in the past
        const startDateForInterval = startDate < now ? now : startDate;

        // enable the whole interval
        let interval = {
            from: DateTimeFormatConfig.formatDateToDateOnlyString(startDateForInterval),
            to: DateTimeFormatConfig.formatDateToDateOnlyString(endDate),
            disabledInside: WorkingDays._getNonWorkingDaysInInterval(startDateForInterval, endDate, workingDays)
        }

        // If current interval contains today check if it's after closing time and disable today too if so
        if(startDate <= now && now <= endDate) {
            let nextQuaterOfHour = ShopDatesInterval.getNextQuaterOfHour();

            // if time after closing time of today
            if(this.isTimeAfterClosingTime(wh, semesterSettings, nextQuaterOfHour, now, 0, pickupDropoff)) {
                interval.disabledInside.push(DateTimeFormatConfig.formatDateToDateOnlyString(now));
            }
        }

        enabledIntervalsWithDisabledDays.push(interval);
    }

    static _getIntervals(semesterDur) {
        let summer_start_date = dayjs(semesterDur.summer_start_date);
        let summer_end_date = dayjs(semesterDur.summer_end_date);

        let no_classes_summer_start_date = semesterDur.no_classes_summer_start_date !== '' ?
            dayjs(semesterDur.no_classes_summer_start_date) :
            false;

        let no_classes_summer_end_date = semesterDur.no_classes_summer_end_date !== '' ?
            dayjs(semesterDur.no_classes_summer_end_date) :
            false;

        let winter_start_date = dayjs(semesterDur.winter_start_date);
        let winter_end_date = dayjs(semesterDur.winter_end_date);

        let no_classes_winter_start_date = semesterDur.no_classes_winter_start_date !== '' ?
            dayjs(semesterDur.no_classes_winter_start_date) :
            false;

        let no_classes_winter_end_date = semesterDur.no_classes_winter_end_date !== '' ?
            dayjs(semesterDur.no_classes_winter_end_date) :
            false;

        return {summer_start_date, summer_end_date, no_classes_summer_start_date, no_classes_summer_end_date,
            winter_start_date, winter_end_date, no_classes_winter_start_date, no_classes_winter_end_date};
    }

    // will generate for the specified number of years an array with the enabled time ranges
    // as well as the disabled days within that ranges, here an example:
    //      { from: new Date('2021-10-17'), to: new Date('2021-10-28')},
    //      { from: new Date('2021, 11, 17'), to: new Date('2021, 12, 18')},
    //      [2021,9,21,'inverted'],
    //      [2021,9,23,'inverted'],
    // pickupDropoff can be 'pickup'/'dropoff' - corresponding to handouts/returns
    static generateEnabledIntervalsForNextYears(wh, semesterSettings, pickupDropoff, yearsCount = 1) {
        // initializes the intervals, noclasses are set to false if not specified
        const {summer_start_date, summer_end_date, no_classes_summer_start_date, no_classes_summer_end_date,
            winter_start_date, winter_end_date, no_classes_winter_start_date, no_classes_winter_end_date} = WorkingDays._getIntervals(semesterSettings);

        const now = dayjs();

        const enabledIntervalsWithDisabledDays = [];
        for(let i = 0; i < yearsCount; i++) {
            // entries for the semesters
            WorkingDays._generateEntriesForInterval(wh, semesterSettings, enabledIntervalsWithDisabledDays, now, summer_start_date, summer_end_date, wh.classes[pickupDropoff], pickupDropoff);
            WorkingDays._generateEntriesForInterval(wh, semesterSettings, enabledIntervalsWithDisabledDays, now, winter_start_date, winter_end_date, wh.classes[pickupDropoff], pickupDropoff);

            // move to next year
            summer_start_date.add(1, 'y');
            summer_end_date.add(1, 'y');
            winter_start_date.add(1, 'y');
            winter_end_date.add(1, 'y');

            // adds the summer noclasses interval if specified
            if(no_classes_summer_start_date !== false && no_classes_summer_end_date !== false) {
                WorkingDays._generateEntriesForInterval(wh, semesterSettings, enabledIntervalsWithDisabledDays, now, no_classes_summer_start_date, no_classes_summer_end_date,
                    wh.no_classes[pickupDropoff], pickupDropoff);

                // move to next year
                no_classes_summer_start_date.add(1, 'y');
                no_classes_summer_end_date.add(1, 'y');
            }

            // adds the winter noclasses interval if specified
            if(no_classes_winter_start_date !== false && no_classes_winter_end_date !== false) {
                WorkingDays._generateEntriesForInterval(wh, semesterSettings, enabledIntervalsWithDisabledDays, now, no_classes_winter_start_date, no_classes_winter_end_date,
                    wh.no_classes[pickupDropoff], pickupDropoff);

                // move to next year
                no_classes_winter_start_date.add(1, 'y');
                no_classes_winter_end_date.add(1, 'y');
            }
        }

        return enabledIntervalsWithDisabledDays;
    }

    // pickupDropoff can be 'pickup'/'dropoff' - corresponding to handouts/returns
    static getWorkingHoursForDay(wh, semesterSettings, dayToCheck, pickupDropoff) {
        // first get the interval type for the day
        const { currentSemester, currentNoClasses } = WorkingDays.isTodayInSemesterOrInNoClassesInterval(semesterSettings, dayToCheck);

        // then get the working hours for the interval tyoe and from/to type
        let workingDays = false;

        if(currentSemester) {
            workingDays = wh.classes[pickupDropoff]
        }

        if(currentNoClasses) {
            workingDays = wh.no_classes[pickupDropoff]
        }

        if(workingDays !== false) {
            return workingDays[dayToCheck.weekday()];
        }

        return {};
    }

    // Used to disable days in the shop recurring tab days of week dropdown
    // Disable the day if it's disabled for any of the pickup/dropoff for that day
    static  isTodayInSemesterOrInNoClassesInterval(semesterDur, dayToCheck = false) {
        let currentSemester = false;
        let currentNoClasses = false;

        let referenceDate = dayToCheck === false ? dayjs() : dayToCheck;
        // console.log("isTodayInSemesterOrInNoClassesInterval - referenceMonthDay: ", referenceDate);

        let summer_start_date = dayjs(semesterDur.summer_start_date);
        let summer_end_date = dayjs(semesterDur.summer_end_date);
        
        let winter_start_date = dayjs(semesterDur.winter_start_date);
        let winter_end_date = dayjs(semesterDur.winter_end_date);

        if (referenceDate >= summer_start_date && referenceDate <= summer_end_date) {
            // console.log("isTodayInSemesterOrInNoClassesInterval - Summer clases.");
            currentSemester = true;
        } else if (referenceDate >= winter_start_date && referenceDate <= winter_end_date) {
            // console.log("isTodayInSemesterOrInNoClassesInterval - Winter clases.");
            currentSemester = true;
        }

        let hasNoClasses = false;
        if(semesterDur.no_classes_summer_start_date !== '' && semesterDur.no_classes_summer_end_date) {
            let no_classes_summer_start_date = dayjs(semesterDur.no_classes_summer_start_date);
            let no_classes_summer_end_date = dayjs(semesterDur.no_classes_summer_end_date);

            if (referenceDate >= no_classes_summer_start_date && referenceDate <= no_classes_summer_end_date) {
                // console.log("isTodayInSemesterOrInNoClassesInterval - Summer NO clases.");
                currentNoClasses = true;
            }

            hasNoClasses = true;
        }

        if(semesterDur.no_classes_winter_start_date !== '' && semesterDur.no_classes_winter_end_date !== '') {
            let no_classes_winter_start_date = dayjs(semesterDur.no_classes_winter_start_date);
            let no_classes_winter_end_date = dayjs(semesterDur.no_classes_winter_end_date);

            if (referenceDate >= no_classes_winter_start_date && referenceDate <= no_classes_winter_end_date) {
                // console.log("isTodayInSemesterOrInNoClassesInterval - Winter NO clases.");
                currentNoClasses = true;
            }

            hasNoClasses = true;
        }
        
        let returnData = {
            currentSemester,
            currentNoClasses,
            hasNoClasses
        }

        // console.log("isTodayInSemesterOrInNoClassesInterval - return: ", returnData);
        return returnData;
    }

    static getPickupDropOff(isStartDatePicker)
    {
        return isStartDatePicker ? RecurrenceDays.WORKING_HOURS_KEY_FOR_PICKUP : RecurrenceDays.WORKING_HOURS_KEY_FOR_DROPOFF;
    }

    static get_semester_interval(date_to_check = dayjs()) {

        const {summer_start_date, summer_end_date, no_classes_summer_start_date, no_classes_summer_end_date,
            winter_start_date, winter_end_date, no_classes_winter_start_date, no_classes_winter_end_date} = WorkingDays._getIntervals();

        let {interval_start, interval_end} = WorkingDays._get_current_next_interval(summer_start_date, summer_end_date, winter_start_date, winter_end_date, "current", date_to_check);
        if (interval_start !== false && interval_end !== false) {
            return {interval_start, interval_end};
        }

        // check if wihint no classes interval
        let interval = WorkingDays._get_current_next_interval(no_classes_summer_start_date, no_classes_summer_end_date, no_classes_winter_start_date, no_classes_winter_end_date, "current", date_to_check);
        return {interval_start: interval.interval_start, interval_end: interval.interval_end};
    }

    // current_next - can have the values current/next, and shows which interval to return
    static _get_current_next_interval(summer_start_date, summer_end_date, winter_start_date, winter_end_date, current_next, date_to_check = dayjs()) {

        let interval_start = false;
        let interval_end = false;

        // will return the current interval - date_to_check might not be in an interval
        if(current_next === 'current') {
            // check if in summer interval
            if(summer_start_date <= date_to_check && date_to_check <= summer_end_date) {
                interval_start = summer_start_date;
                interval_end = summer_end_date;
            } else
            // check if in winter interval
            if(winter_start_date <= date_to_check && date_to_check <= winter_end_date) {
                interval_start = winter_start_date;
                interval_end = winter_end_date;
            }
        } else
        // must return next interval
        if(current_next === 'next') {
            // both might be in the future or date_to_check might be in one of them
            let start_interval1 = winter_start_date;
            let end_interval1 = winter_end_date;
            let start_interval2 = summer_start_date;
            let end_interval2 = summer_end_date;

            if(summer_start_date < winter_start_date) {
                start_interval1 = summer_start_date;
                end_interval1 = summer_end_date
                start_interval2 = winter_start_date;
                end_interval2 = winter_end_date;
            }

            if(date_to_check < start_interval1) {
                interval_start = start_interval1;
                interval_end = end_interval1;
            } else {
                interval_start = start_interval2;
                interval_end = end_interval2;
            }
        }

        return {interval_start, interval_end};
    }

    static get_semester_interval_for_selection(semesterSettings, selection) {
        let val;

        const {summer_start_date, summer_end_date, no_classes_summer_start_date, no_classes_summer_end_date,
            winter_start_date, winter_end_date, no_classes_winter_start_date, no_classes_winter_end_date} = WorkingDays._getIntervals(semesterSettings);

        switch (parseInt(selection)) {
            case 0:
            {
                // current semester - returns the semester that now is in
                const {interval_start, interval_end} = 
                    WorkingDays._get_current_next_interval(summer_start_date, summer_end_date, winter_start_date, winter_end_date, 'current');

                val = {
                    startDate: DateTimeFormatConfig.standardPickerDateFormat(interval_start),
                    endDate: DateTimeFormatConfig.standardPickerDateFormat(interval_end)
                };
            }
            break;

            case 2:
            {
                // current no classes interval
                const {interval_start, interval_end} = 
                    WorkingDays._get_current_next_interval(no_classes_summer_start_date, no_classes_summer_end_date, no_classes_winter_start_date, no_classes_winter_end_date, 'current');

                val = {
                    startDate: DateTimeFormatConfig.standardPickerDateFormat(interval_start),
                    endDate: DateTimeFormatConfig.standardPickerDateFormat(interval_end)
                };
            }
            break;
    
            case 3:
            {
                // next semester
                const {interval_start, interval_end} = 
                    WorkingDays._get_current_next_interval(summer_start_date, summer_end_date, winter_start_date, winter_end_date, 'next');

                val = {
                    startDate: DateTimeFormatConfig.standardPickerDateFormat(interval_start),
                    endDate: DateTimeFormatConfig.standardPickerDateFormat(interval_end)
                };
            }
            break;
    
            case 4:
            {
                // next no classes interval
                const {interval_start, interval_end} = 
                    WorkingDays._get_current_next_interval(no_classes_summer_start_date, no_classes_summer_end_date, no_classes_winter_start_date, no_classes_winter_end_date, 'next');

                val = {
                    startDate: DateTimeFormatConfig.standardPickerDateFormat(interval_start),
                    endDate: DateTimeFormatConfig.standardPickerDateFormat(interval_end)
                };
            }
            break;
        
            default: // VON/BIS
                break;
        }

        return val;

    }

    static isTimeAfterClosingTime(wh, semesterSettings, timeToCheck, dayToCheck, timeRangeValues, pickupDropoff) {

        const workingHours = WorkingDays.getWorkingHoursForDay(wh, semesterSettings, dayToCheck, pickupDropoff)
        
        // each day has up to 2 intervals
        let bis1 = workingHours['bis1'];
        let bis2 = workingHours['bis2'];
    
        // both are invalid
        if(!bis1.includes(":") && !bis2.includes(":")) {
            return true;
        }
    
        // if bis2 is specified, use that closing time
        if(bis2.includes(":")) {
            // ANDY - 11.07.2022 - return true only if time to check is greater then bis
            // Last time interval for opening hours should be a valid time
            //return timeToCheck >= bis2;
            return timeToCheck > bis2;
        }
    
        // if bis1 is specified, use that closing time
        // ANDY - 11.07.2022 - return true only if time to check is greater then bis
        //return timeToCheck >= bis1;
        return timeToCheck > bis1;
    }

    static prepareTimeSliderDataForDay(wh, semesterSettings, dayToCheck, pickupDropoff, withOpeningTime = false) {
        const workingHours = WorkingDays.getWorkingHoursForDay(wh, semesterSettings, dayToCheck, pickupDropoff);
        if(!workingHours || Object.keys(workingHours).length === 0) {
            return false;
        }

        let von1 = workingHours['von1'];
        let bis1 = workingHours['bis1'];
        let von2 = workingHours['von2'];
        let bis2 = workingHours['bis2'];

        let nextQuaterOfHour = false;
        if(dayToCheck.isSame(dayjs(), 'd')) {
            nextQuaterOfHour = ShopDatesInterval.getNextQuaterOfHour();
        }

        /* in this case, the slider is on today, and the hours are limited to start with the next hour */;
        if(nextQuaterOfHour !== false) {
            // console.log('von1 is set to next quater of the hour');

            von1 = von1 > nextQuaterOfHour ? von1 : nextQuaterOfHour;
            von2 = von2 > nextQuaterOfHour ? von2 : nextQuaterOfHour;

            // ANDY - 11.07.2022 - only if bis1 < next quarter of hour
            // bis1 can be equal to next quarter of hour
            //if(bis1 <= nextQuaterOfHour) {
            if(bis1 < nextQuaterOfHour) {
                von1 = bis1 = '';
            }
        }

        // when editing the date by hand, there might not be set
        let von1Defined = von1 && von1.includes(":");
        let bis1Defined = bis1 && bis1.includes(":");
        let von2Defined = von2 && von2.includes(":");
        let bis2Defined = bis2 && bis2.includes(":");

        // if all are not found -> do no init
        if(!(von1Defined || bis1Defined || von2Defined || bis2Defined)) {
            console.log('NO INTERVAL DEFINED - do not init timeslider ' + von1Defined + " - " + bis1Defined  + " - " + von2Defined + " - " + bis2Defined);
            return false;
        }

        const [von1Hour, von1Min] = von1.split(':');
        const [bis1Hour, bis1Min] = bis1.split(':');

        let marks = WorkingDays.addMarksForTimeInterval(parseInt(von1Hour), parseInt(von1Min), parseInt(bis1Hour), parseInt(bis1Min));

        if(von2Defined && bis2Defined) {
            const [von2Hour, von2Min] = von2.split(':');
            const [bis2Hour, bis2Min] = bis2.split(':');

            let marks2 = WorkingDays.addMarksForTimeInterval(parseInt(von2Hour), parseInt(von2Min), parseInt(bis2Hour), parseInt(bis2Min));

            marks = [...marks, ...marks2];
        }

        if (withOpeningTime) {
            return [marks, (von1Defined ? von1 : '')]
        }

        return marks;
    }

    static addMarksForTimeInterval(vonHour, vonMin, bisHour, bisMin) {
        let marks = [];
        for(let i = vonHour; i <= bisHour; i++) {
            if(i === vonHour && i === bisHour) {
                for(let j = vonMin / 15; j <= (bisMin / 15); j++) {
                    const mins = j * 15;
            
                    marks.push({
                        value: i * 60 + mins,
                        label: (i < 10 ? '0' + i : i + '') + ":"+(mins === 0 ? "00" : mins),
                    });
                }
            } else if(i === vonHour) {
                for(let j = (vonMin / 15); j <= 3; j++) {
                    const mins = j * 15
            
                    marks.push({
                        value: i * 60 + mins,
                        label: j === 0 ? i + '' : '',
                    });
                }
            } else if (i === bisHour) {
                for(let j = 0; j <= (bisMin / 15); j++) {
                    const mins = j * 15
            
                    marks.push({
                        value: i * 60 + mins,
                        label: j === 0 ? i + '' : '',
                    });
                }
            } else {
                for(let j = 0; j <= 3; j++) {
                    const mins = j * 15
            
                    marks.push({
                        value: i * 60 + mins,
                        label: j === 0 ? i + '' : '',
                    });
                }
            }
        }
        return marks;
    }
}

export default WorkingDays;
