import React from 'react';
import { useTranslation } from "react-i18next";

import { DialogContentText, Stack } from '@mui/material';

import ConfirmationDialogBase from './ConfirmationDialogBase';
import SelectLocation from '../SelectLocation';

export default function NoLocationSelectedDialog(props) {
    const { t } = useTranslation();

    return (
        <ConfirmationDialogBase title={t("popups.noLocationSelectedTitle")} {...props}>
            <Stack direction="column" spacing={2} >
                <DialogContentText
                    id="scroll-dialog-description"
                    tabIndex={-1}
                    variant="body1"
                >
                    {t("popups.noLocationSelectedText")}
                </DialogContentText>
                <SelectLocation sx={{ width: {xs: "100%", sm: "50%"} }} />
            </Stack>
        </ConfirmationDialogBase>           
    );
}