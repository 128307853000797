import React from 'react';

import { Stack, Typography } from '@mui/material';

import MasonrySection from '../../common/ui/qvshop/components/MasonrySection';
import ProductCard from '../../common/ui/qvshop/components/ProductCard';
import ProductCardSkeleton from '../../common/ui/qvshop/components/ProductCardSkeleton';

export default function ExtraArticles(props) {
    const { title, articles, loading, hideAddToCartButton } = props;

    return (
        <Stack direction='column' spacing={2} width="100%">
            <Typography variant="h6">
                {title}
            </Typography>
            <MasonrySection breakpointCols={{
                default: 3,
                720: 1,
                1050: 2,
                1380: 3,
            }} 
                items={ Object.values(articles) }
                loading={loading}
                skeleton={ ProductCardSkeleton } 
                card={ ProductCard } 
                hideAddToCartButton={ hideAddToCartButton }
                />
        </Stack>
    );
};