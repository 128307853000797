import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useDebounceCallback } from '@react-hook/debounce';
import { useTranslation } from "react-i18next";

import { Box, Avatar, Typography, Container, Stack, CssBaseline } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';

import { RegisterUtils } from '../common/store/utils/RegisterUtils';
import Debounce from '../common/utils/Debounce';
import PageNames from '../common/utils/PageNames';
import ShopConfig from '../common/api/config/shopConfig';

import { usePageLayoutConfigurator } from "../common/hooks/usePageLayoutConfigurator";
import QVContainer from '../common/ui/qvshop/components/QVContainer';
import Select from '../common/ui/qvshop/components/Select';
import TextField from '../common/ui/qvshop/components/TextField';
import AcceptTermsSection from '../common/ui/qvshop/components/AcceptTermsSection';
import PrimaryButton from '../common/ui/qvshop/components/core/PrimaryButton';
import { ReactRouterLink } from '../common/ui/qvshop/components/core';

import { 
    checkUsername,
    clearRegisterData,
    registerUser, 
    selectCountry, 
    selectEmail, 
    selectEmailError, 
    selectFestnetz, 
    selectMatrNr, 
    selectMobil, 
    selectMobilError, 
    selectName, 
    selectNameError, 
    selectOrt, 
    selectPassword, 
    selectPasswordConfirm, 
    selectPasswordConfirmError, 
    selectPasswordError, 
    selectPasswordsDoNotMatch, 
    selectPlz, 
    selectRegisterFailed, 
    selectRegisterStatus, 
    selectRegisterSuccessfull, 
    selectRukruf, 
    selectSalutation, 
    selectSalutations, 
    selectStr, 
    selectStudNr, 
    selectTitle, 
    selectTitles, 
    selectUsername, 
    selectUsernameError, 
    selectUsernameTakenError, 
    selectVorname, 
    selectVornameError, 
    setEmailError, 
    setMobilError, 
    setNameError, 
    setPassError, 
    setPasswordConfirmError, 
    setRegisterStatusIdle, 
    setUserData,
    setUsernameError,
    setVornameError
} from '../common/store/registerSlice';
import { selectCountries, selectOrganisationCountry, selectOrganisationLogo } from '../common/store/settingsSlice';

export default function Register() {
    usePageLayoutConfigurator(PageNames.REGISTER);
    const dispatch = useDispatch();
    const { t } = useTranslation(['common', 'register']);
    const navigate = useNavigate();

    const organisationLogo = useSelector(selectOrganisationLogo);
    const organisationCountry = useSelector(selectOrganisationCountry);

    const salutations = useSelector(selectSalutations);
    const titles = useSelector(selectTitles);
    const countries = useSelector(selectCountries);

    const salutation = useSelector(selectSalutation);
    const title = useSelector(selectTitle);
    const vname = useSelector(selectVorname);
    const name = useSelector(selectName);
    const matrikelNr = useSelector(selectMatrNr);
    const studNr = useSelector(selectStudNr);

    const str = useSelector(selectStr);
    const plz = useSelector(selectPlz);
    const ort = useSelector(selectOrt);
    const country = useSelector(selectCountry);
    const email = useSelector(selectEmail);
    const mobil = useSelector(selectMobil);
    const festnetz = useSelector(selectFestnetz);
    const ruckrufNr = useSelector(selectRukruf);

    const username = useSelector(selectUsername);
    const password = useSelector(selectPassword);
    const passwordConfirm = useSelector(selectPasswordConfirm);

    // Errors
    const vnameError = useSelector(selectVornameError);
    const nameError = useSelector(selectNameError);
    const emailError = useSelector(selectEmailError);
    const mobilError = useSelector(selectMobilError);
    const usernameError = useSelector(selectUsernameError);
    const usernameTaken = useSelector(selectUsernameTakenError);
    const passwordError = useSelector(selectPasswordError);
    const passwordConfirmError = useSelector(selectPasswordConfirmError);
    const passwordsDoNotMatchError = useSelector(selectPasswordsDoNotMatch);

    const registerStatus = useSelector(selectRegisterStatus);
    const registerSuccessfull = useSelector(selectRegisterSuccessfull);
    const registerFailed = useSelector(selectRegisterFailed);

    useEffect(() => {
        if(registerSuccessfull) {
            dispatch(clearRegisterData());
            navigate("/register/success");
        }

        if(registerFailed) {
            dispatch(setRegisterStatusIdle());
        }
    }, [dispatch, registerStatus, registerSuccessfull, registerFailed, navigate])
    
    const debouncedUsernameCheck = useDebounceCallback(
        (username) => {
            if(username) {
                dispatch(checkUsername());
            }
        },
        // delay in ms
        Debounce.DEBOUNCE_TIMEOUT
    );

    useEffect(() => {
        debouncedUsernameCheck(username);
    }, [dispatch, username, debouncedUsernameCheck]);

    const changeData = (field, event) => {
        const value = event.target.value;
        dispatch(setUserData({field, value}));
    }

    const [accepted, setAccepted] = useState(false);
    const [acceptedError, setAcceptedError] = useState(false);

    const handleAgreeChange = (event) => {
        setAccepted(event.target.checked);
    };
    
    const handleSubmit = (event) => {
        event.preventDefault();

        let formHasErrors = false;
        setAcceptedError(!accepted);

        if(vname === '') {
            dispatch(setVornameError(true));
            formHasErrors = true;
        } else {
            dispatch(setVornameError(false));
        }

        if(name === '') {
            dispatch(setNameError(true));
            formHasErrors = true;
        } else {
            dispatch(setNameError(false));
        }
        
        if(email === '' || !RegisterUtils.validateEmail(email)) {
            dispatch(setEmailError(true));
            formHasErrors = true;
        } else {
            dispatch(setEmailError(false));
        }

        if(mobil === '') {
            dispatch(setMobilError(true));
            formHasErrors = true;
        } else {
            dispatch(setMobilError(false));
        }

        if(username === '') {
            dispatch(setUsernameError(true));
            formHasErrors = true;
        } else {
            dispatch(setUsernameError(false));
        }

        if(password === '') {
            dispatch(setPassError(true));
            formHasErrors = true;
        } else {
            dispatch(setPassError(false));
        }

        if(passwordConfirm === '') {
            dispatch(setPasswordConfirmError(true));
            formHasErrors = true;
        } else {
            dispatch(setPasswordConfirmError(false));
        }

        if(!(formHasErrors 
            || vnameError 
            || nameError 
            || emailError 
            || mobilError 
            || usernameTaken 
            || usernameError 
            || passwordError 
            || passwordsDoNotMatchError
            || !accepted
            )) 
        {
            dispatch(registerUser());
        } else {
            console.log("Form has errors - NO register!");
        }
    };

    useEffect(() => {
        if(country === '' && organisationCountry !== '') {
            dispatch(setUserData({field: "land", value: organisationCountry}))
        }
    }, [country, dispatch, organisationCountry]);

    return (
        <Container component="main" maxWidth="md">
            <CssBaseline />
            <Box
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                { organisationLogo === "" ?
                    <Avatar sx={{ m: 1, bgcolor: 'primary.main' }}>
                        <LockOutlinedIcon />
                    </Avatar>
                    :
                    <Box
                        component="img"
                        alt="OrganisationLogo"
                        src={ShopConfig.config().getURLFor(organisationLogo)}
                        sx={{ m: 1, maxWidth: "300px", objectFit: "contain" }}
                    />
                }
                <Typography sx={{ m: 1}} component="h1" variant="h5">
                    { t("register:page.title") }
                </Typography>
                <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
                    <Grid container spacing={4} justifyContent="center">
                        <Grid container spacing={2} xs={12} sm={6} md={4}>
                            <QVContainer flexDirection="column" sx={{ width: 1 }}>
                                <Grid xs={12}>
                                    <Typography component="h1" variant="h5">
                                        { t("register:subtitle.basedata") }
                                    </Typography>
                                </Grid>
                                <Grid xs={12}>
                                    <Select
                                        sx={{ width: "100%" }}
                                        id="anrede"
                                        label={t("register:selectLabel.gender")}
                                        options={salutations}
                                        value={salutation}
                                        onChange={(ev) => changeData('anrede', ev)}
                                    />
                                </Grid>
                                <Grid xs={12}>
                                    <Select
                                        sx={{ width: "100%" }}
                                        id="title"
                                        label={t("register:selectLabel.title")}
                                        options={titles}
                                        value={title}
                                        onChange={(ev) => changeData('titel', ev)}
                                    />
                                </Grid>
                                <Grid xs={12}>
                                    <TextField
                                        error={vnameError}
                                        errorTitle=""
                                        errorText=""
                                        autoComplete="given-name"
                                        required
                                        fullWidth
                                        id="vorname"
                                        label={t("register:inputLabel.vorname")}
                                        name="vorname"
                                        value={vname}
                                        onChange={(ev) => changeData('vorname', ev)}
                                    />
                                </Grid>
                                <Grid xs={12}>
                                    <TextField
                                        error={nameError}
                                        errorTitle=""
                                        errorText=""
                                        autoComplete="family-name"
                                        required
                                        fullWidth
                                        id="name"
                                        label={t("register:inputLabel.name")}
                                        name="name"
                                        value={name}
                                        onChange={(ev) => changeData('name', ev)}
                                    />
                                </Grid>
                                <Grid xs={12}>
                                    <TextField
                                        fullWidth
                                        id="matrNr"
                                        label={t("register:inputLabel.matrNr")}
                                        name="matrNr"
                                        value={matrikelNr}
                                        onChange={(ev) => changeData('matrikelNr', ev)}
                                    />
                                </Grid>
                            </QVContainer>
                        </Grid>
                        <Grid container spacing={2} xs={12} sm={6} md={4}>
                            <QVContainer flexDirection="column" sx={{ width: 1 }}>
                                <Grid xs={12}>
                                    <Typography component="h1" variant="h5">
                                        { t("register:subtitle.contactInformation") }
                                    </Typography>
                                </Grid>
                                <Grid xs={12}>
                                    <TextField
                                        fullWidth
                                        id="str"
                                        label={t("register:inputLabel.str")}
                                        name="str"
                                        value={str}
                                        onChange={(ev) => changeData('strasse', ev)}
                                    />
                                </Grid>
                                <Grid xs={12}>
                                    <TextField
                                        fullWidth
                                        id="plz"
                                        label={t("register:inputLabel.plz")}
                                        name="plz"
                                        value={plz}
                                        onChange={(ev) => changeData('plz', ev)}
                                    />
                                </Grid>
                                <Grid xs={12}>
                                    <TextField
                                        fullWidth
                                        id="ort"
                                        label={t("register:inputLabel.ort")}
                                        name="ort"
                                        value={ort}
                                        onChange={(ev) => changeData('ort', ev)}
                                    />
                                </Grid>
                                <Grid xs={12}>
                                    <Select
                                        sx={{ width: "100%" }}
                                        id="country"
                                        label={t("register:selectLabel.country")}
                                        options={countries}
                                        value={country}
                                        onChange={(ev) => changeData('land', ev)}
                                    />
                                </Grid>
                                <Grid xs={12}>
                                    <TextField
                                        error={emailError}
                                        errorTitle=""
                                        errorText=""
                                        required
                                        fullWidth
                                        id="email"
                                        label={t("register:inputLabel.email")}
                                        name="email"
                                        value={email}
                                        onChange={(ev) => changeData('email', ev)}
                                    />
                                </Grid>
                                <Grid xs={12}>
                                    <TextField
                                        error={mobilError}
                                        errorTitle=""
                                        errorText=""
                                        required
                                        fullWidth
                                        id="mobil"
                                        label={t("register:inputLabel.mobil")}
                                        name="mobil"
                                        value={mobil}
                                        onChange={(ev) => changeData('mobil', ev)}
                                    />
                                </Grid>
                                <Grid xs={12}>
                                    <TextField
                                        fullWidth
                                        id="festnetz"
                                        label={t("register:inputLabel.festnetz")}
                                        name="festnetz"
                                        value={festnetz}
                                        onChange={(ev) => changeData('festnetz', ev)}
                                    />
                                </Grid>
                                <Grid xs={12}>
                                    <TextField
                                        fullWidth
                                        id="rukruf"
                                        label={t("register:inputLabel.rukruf")}
                                        name="rukruf"

                                        value={ruckrufNr}
                                        onChange={(ev) => changeData('ruckrufNr', ev)}
                                    />
                                </Grid>
                            </QVContainer>
                        </Grid>
                        <Grid container spacing={2} xs={12} sm={12} md={4}>
                            <QVContainer flexDirection="column" sx={{ width: 1 }}>
                                <Grid xs={12}>
                                    <Typography component="h1" variant="h5">
                                        { t("register:subtitle.loginInformation") }
                                    </Typography>
                                </Grid>
                                <Grid xs={12}>
                                    <TextField
                                        error={usernameError || usernameTaken}
                                        errorTitle=""
                                        errorText={usernameTaken ? t("register:inputError.usernameTaken") : ""}
                                        name="username"
                                        required
                                        fullWidth
                                        id="username"
                                        label={t("register:inputLabel.username")}
                                        autoComplete="new-user"
                                        value={username}
                                        onChange={(ev) => changeData('username', ev)}
                                    />
                                </Grid>
                                <Grid xs={12}>
                                    <TextField
                                        error={passwordError}
                                        errorTitle=""
                                        errorText={usernameTaken ? t("register:inputError.usernameTaken") : ""}
                                        required
                                        fullWidth
                                        name="password"
                                        label={t("register:inputLabel.password")}
                                        type="password"
                                        id="password"
                                        autoComplete="new-password"
                                        value={password}
                                        onChange={(ev) => changeData('pass', ev)}
                                    />
                                </Grid>
                                <Grid xs={12}>
                                    <TextField
                                        error={passwordConfirmError || passwordsDoNotMatchError}
                                        errorTitle=""
                                        errorText={passwordsDoNotMatchError ? t("register:inputError.passwordsDoNotMatch") : ""}
                                        required
                                        fullWidth
                                        name="shopPassConfirm"
                                        label={t("register:inputLabel.shopPassConfirm")}
                                        type="password"
                                        id="shopPassConfirm"
                                        autoComplete="confirm-password"
                                        value={passwordConfirm}
                                        onChange={(ev) => changeData('passwordConfirm', ev)}
                                    />
                                </Grid>
                            </QVContainer>
                        </Grid>
                    </Grid>
                    <Grid sx={{mt: 2}} container spacing={2} justifyContent="center">
                        <Grid >
                            <AcceptTermsSection error={acceptedError} accepted={accepted} onAgreeChanged={handleAgreeChange} />
                        </Grid>
                        <Grid sx={{mb: 3}}>
                            <Stack direction={"column"}>
                                <PrimaryButton sx={{ mb: 2 }} fullWidth type="submit" label={t("register:button.registerNow")} />
                                <ReactRouterLink to="/login">{t("register:link.toLogin")}</ReactRouterLink>
                            </Stack>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </Container>
    );
}