import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from "react-i18next";

import { Button } from '@mui/material';

export default function BackButton(props) {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { label = false, to = false } = props;

    const handleOnClick = () => {
        if(to !== false) {
            navigate(to);
        } else {
            navigate(-1);
        }
    }

    return (
        <Button variant="outlined" size="small" onClick={handleOnClick} >
            { label ? label : t("button.back") }
        </Button>
    );
};