export const drawerContainer = { 
    height: '100vh', 
    justifyContent: 'flex-between', 
    marginTop: "4px"
};

const drawerWidth = 297;
export const drawerMobile = {
    display: { xs: 'flex', sm: 'none' },
    '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
};

export const drawerDesktop = {
    display: { xs: 'none', sm: 'flex' },
    '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
};