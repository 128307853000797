import React from 'react';
import { useSelector } from 'react-redux';

import { Box, Drawer, Toolbar } from '@mui/material';

import { px2, flexColFullHeight, flexColFullHeightScrollable } from "../../../sx/common";
import * as sx from "../sx/ResponsiveDrawerSx";

import LocationSelectSection from './components/LocationSelectSection';
import IntervalsSelectionSection from './components/IntervalsSelectionSection';
import CategorySelectionSection from './components/CategorySelectionSection';
import SpecificationsFilterSection from './components/SpecificationsFilterSection';

import { selectShowSidebar } from '../../../../store/layoutSlice';
import { selectHasLocationsDefined, selectUserHasOneLocation } from '../../../../store/locationsSlice'; 
import { selectCartIsEmpty } from '../../../../store/cartSlice';

function ResponsiveDrawer(props) {
    const showSidebar = useSelector(selectShowSidebar);
    const hasLocationsDefined = useSelector(selectHasLocationsDefined);
    const hasOnlyOneLocation = useSelector(selectUserHasOneLocation);

    const cartIsEmpty = useSelector(selectCartIsEmpty);
    
    const { window, mobileOpen, handleDrawerToggle } = props;

    const drawer = (
        <Box sx={{ ...flexColFullHeight, ...sx.drawerContainer }}>
            <Toolbar />

            <Box sx={{ ...flexColFullHeightScrollable, ...px2 }}>
                <IntervalsSelectionSection />
                { hasLocationsDefined && !hasOnlyOneLocation && cartIsEmpty &&  <LocationSelectSection /> }
                <CategorySelectionSection />
                <SpecificationsFilterSection />
            </Box>
        </Box>
    );

    const container = window !== undefined ? () => window().document.body : undefined;

    return (
        <>
            <Drawer
                container={container}
                variant="temporary"
                open={mobileOpen}
                onClose={handleDrawerToggle}
                ModalProps={{
                    keepMounted: true, // Better open performance on mobile.
                }}
                sx={sx.drawerMobile}
            >

                {drawer}
            </Drawer>
            {showSidebar && <Drawer
                variant="permanent"
                sx={sx.drawerDesktop}
                open
            >
                {drawer}
            </Drawer>}
        </>
    );
}

export default ResponsiveDrawer;