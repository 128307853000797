import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { CartSliceUtils } from './utils/CartSliceUtils';
import { Api } from "../api/api";
import ReturnKey from '../utils/ReturnKey';
import { AvailabilityUtils } from './utils/AvailabilityUtils';
import { ProductUtils } from './utils/ProductUtils';
import { Storage } from '../utils/Storage';

const STATUS_IDLE = 'idle';
const STATUS_LOADING = 'loading';
const STATUS_SUCCEEDED = 'succeeded';
const STATUS_FAILED = 'failed';

const initialState = {
    status: STATUS_IDLE,
    error: null,

    availability_status: STATUS_SUCCEEDED,
    availability_error: null,
    cart_availability_trigger: new Date().getTime(),

    // returnKey: null,
    returnKeyData: {
        "optional": false,
        "required": false,
        "available": true,
        "intervals": [],
        "intervalsForAvailability": [],
        "details": {}
    },

    returnKeyDataChanged: new Date().getTime(),

    hasRecurrenceErrors: false,
    hasFromToErrors: false,
    cart: [],
};

export const getCartFromAvailabilityCalendar = createAsyncThunk(
    'shop/getCartFromAvailabilityCalendar',
    async ({ month, year, product, subarticle }, { getState }) => {
        const state = getState();
        const location = state.filters.location;
        const articleId = product.product_id;
        const subarticleId = subarticle?.id ? subarticle.id : false;

        const response = await Api.getCalendar(month, year, location, articleId, subarticleId);
        return response;
    }
)

export const getCartToAvailabilityCalendar = createAsyncThunk(
    'shop/getCartToAvailabilityCalendar',
    async ({ month, year, product, subarticle }, { getState }) => {
        const state = getState();
        const location = state.filters.location;
        const articleId = product.product_id;
        const subarticleId = subarticle?.id ? subarticle.id : false;

        const response = await Api.getCalendar(month, year, location, articleId, subarticleId);
        return response;
    }
)

export const checkAvailabilityForCart = createAsyncThunk(
    'shop/checkAvailabilityForCart',
    async (_noParams, { getState }) => {
        const state = getState();
        const location = state.filters.location;
        const products = state.cart.cart;
        const cartArticlesTenantId = CartSliceUtils.getCartItemsTenantId(state);
        
        const dataForAvailability = AvailabilityUtils.prepareAvailabilityDataForProducts(products, state);
        
        const response = await Api.checkAvailabilityForProducts(dataForAvailability, cartArticlesTenantId, location);
        
        return response;
    }
)

export const getReturnKey = createAsyncThunk(
    'cart/getReturnKey',
    async (_noParams, { getState }) => {
        const location = getState().filters.location;

        const response = await Api.getReturnKey(location);
        return response;
    }
)

export const checkKeyAvailability = createAsyncThunk(
    'cart/checkKeyAvailability',
    async (_noParams, { getState }) => {
        const state = getState();
        const returnKey = state.cart.returnKeyData.details;
        const location = state.filters.location;
        const cartArticlesTenantId = CartSliceUtils.getCartItemsTenantId(state);
        const intervals = state.cart.returnKeyData.intervalsForAvailability;
        const dataForAvailability = [{
            article_id: returnKey.product_id,
            intervals,
            withAvailabilityForEachInterval: false
        }];

        const response = await Api.checkAvailabilityForProducts(dataForAvailability, cartArticlesTenantId, location);

        return response;
    }
)

export const slice = createSlice({
    name: 'cart',
    initialState,

    reducers: {
        setCart: (state, action) => {
            state.cart = action.payload;
            Storage.setCart(state.cart);
            state.cart_availability_trigger = new Date().getTime();
        },
        emptyCart: (state, action) => {
            state.cart = [];
            Storage.setCart(state.cart);
        },
        setCartHasRecurrenceErrors: (state, action) => {
            state.hasRecurrenceErrors = action.payload;
        },
        setCartHasFromToErrors: (state, action) => {
            state.hasFromToErrors = action.payload;
        },
        setReturnKeyData: (state, action) => {
            state.returnKeyData = action.payload;
        },
        removeReturnKeyFromCart: (state, action) => {
            state.cart = state.cart.filter(cartItem => !cartItem.isReturnKey);
            Storage.setCart(state.cart);
        },
        setReturnKeyAvailable: (state, action) => {
            state.returnKeyData.available = action.payload;
        },
        setReturnKeyDataChanged: (state) => {
            state.returnKeyDataChanged = new Date().getTime();
        },
    },
    
    extraReducers: (builder) => {
        builder
            .addCase(getCartFromAvailabilityCalendar.pending, (state) => {
                state.status = STATUS_LOADING;
            })
            .addCase(getCartFromAvailabilityCalendar.fulfilled, (state, action) => {
                state.status = STATUS_SUCCEEDED;
                state.cart = state.cart.map(product => {
                    return ProductUtils.updateAvailabilityCalendarData(action.payload.calendarData, product, 'from_availability_calendar');
                });
                Storage.setCart(state.cart);
            })
            .addCase(getCartFromAvailabilityCalendar.rejected, (state, action) => {
                state.status = STATUS_FAILED;
                state.error = action.payload;
            })

            .addCase(getCartToAvailabilityCalendar.pending, (state) => {
                state.status = STATUS_LOADING;
            })
            .addCase(getCartToAvailabilityCalendar.fulfilled, (state, action) => {
                state.status = STATUS_SUCCEEDED;
                state.cart = state.cart.map(product => {
                    return ProductUtils.updateAvailabilityCalendarData(action.payload.calendarData, product, 'to_availability_calendar');
                });
                Storage.setCart(state.cart);
            })
            .addCase(getCartToAvailabilityCalendar.rejected, (state, action) => {
                state.status = STATUS_FAILED;
                state.error = action.payload;
            })
            .addCase(checkAvailabilityForCart.pending, (state) => {
                state.availability_status = STATUS_LOADING;
            })
            .addCase(checkAvailabilityForCart.fulfilled, (state, action) => {
                state.availability_status = STATUS_SUCCEEDED;

                state.cart = state.cart.map(product => {
                    return ProductUtils.updateAvailability(action.payload, product);
                });
                Storage.setCart(state.cart);
            })
            .addCase(checkAvailabilityForCart.rejected, (state, action) => {
                state.availability_status = STATUS_FAILED;
                state.availability_error = action.payload;
            })

            .addCase(getReturnKey.fulfilled, (state, action) => {
                state.status = STATUS_SUCCEEDED;

                state.returnKeyData.details = action.payload.return_key;
            })

            .addCase(getReturnKey.rejected, (state, action) => {
                state.status = STATUS_FAILED;
                state.error = action.payload;
            })

            .addCase(checkKeyAvailability.pending, (state) => {
                state.status = STATUS_LOADING;
            })
            .addCase(checkKeyAvailability.fulfilled, (state, action) => {
                state.status = STATUS_SUCCEEDED;

                state.returnKeyData.available = action.payload[state.returnKeyData.details.product_id].available;
            })
            .addCase(checkKeyAvailability.rejected, (state, action) => {
                state.status = STATUS_FAILED;
                state.error = action.payload;
            })
    }
});

export const { setCart, emptyCart, setCartHasRecurrenceErrors, setCartHasFromToErrors, setCartWithItemAdded, setReturnKeyData, removeReturnKeyFromCart, setReturnKeyAvailable, setReturnKeyDataChanged } = slice.actions;

export function addItemToCart(item, subarticle = false) {
    return (dispatch, getState) => {
        const state = getState();
        const cart = CartSliceUtils.prepareAddItemToCart(state, item, subarticle);
        dispatch(setCart(cart));

        // Return key related information
        dispatch(prepareReturnKeyData());
    };
}

export function updateCartItem(updateInfo) {
    return (dispatch, getState) => {
        const state = getState();
        const hasExtendedTimes = state.settings.settings.extended_times || false;
        updateInfo.hasExtendedTimes = hasExtendedTimes;

        const { cart, hasRecurrenceErrors, hasFromToErrors} = CartSliceUtils.prepareUpdateCartItem(state, dispatch, updateInfo);

        dispatch(setCart(cart));
        dispatch(setCartHasRecurrenceErrors(hasRecurrenceErrors));
        dispatch(setCartHasFromToErrors(hasFromToErrors));

        // Return key related information
        dispatch(prepareReturnKeyData());
    };
}

export function deleteCartItem(deleteInfo) {
    return (dispatch, getState) => {
        const state = getState();
        const cart = CartSliceUtils.prepareDeleteCartItem(state, deleteInfo);
        dispatch(setCart(cart));

        // Return key related information
        dispatch(prepareReturnKeyData());
    };
}

export function prepareReturnKeyData() {
    return (dispatch, getState) => {
        const state = getState();
        const returnKeyInitialData = {...state.cart.returnKeyData};
        const hasExtendedTimes = state.settings.settings.extended_times || false;
        const cart = state.cart.cart;

        if (hasExtendedTimes) {
            const { returnKeyData, returnKeyDataHasChanged } = ReturnKey.prepareReturnKeyData(cart, returnKeyInitialData, state.settings.settings.working_hours, state.settings.settings.semester_settings);
            dispatch(setReturnKeyData(returnKeyData));

            if(returnKeyDataHasChanged) {
                dispatch(setReturnKeyDataChanged())
            }
        }
    };
}

export function addReturnKeyToCart(addedByUser = false) {
    return (dispatch, getState) => {
        const state = getState();
        const returnKey = state.cart.returnKeyData;
        const hasExtendedTimes = state.settings.settings.extended_times || false;
        
        if (hasExtendedTimes) {
            const cart = CartSliceUtils.prepareAddReturnKeyToCart(state, returnKey, addedByUser);
            dispatch(setCart(cart));
        }
    };
}

export const selectCart = state => state.cart.cart;
export const selectCartHasRecurrenceErrors = state => state.cart.hasRecurrenceErrors;
export const selectCartHasFromToErrors = state => state.cart.hasFromToErrors;
export const selectReturnKeyData = state => state.cart.returnKeyData;
export const selectItemsInCart = state => CartSliceUtils.getNumberOfItemsInCart(state);
export const selectCartHasItems = state => state.cart.cart.length > 0;
export const selectCartIsEmpty = state => state.cart.cart.length === 0;

export const selectCartHasAllItemsAvailable = state => CartSliceUtils.cartHasAllItemsAvailable(state);

export const selectIsLoadingAvailability = state => state.cart.availability_status === STATUS_LOADING;
export const selectTriggerCartAvailability = state => state.cart.cart_availability_trigger;
export const selectTriggerReturnKeyDataChanged = state => state.cart.returnKeyDataChanged;

export const selectCartContainsAReturnKey = state => state.cart.cart.length > 0 && (state.cart.cart.findIndex(x => x.isReturnKey === true) >= 0);
export const selectcartContainsAMandatoryReturnKey = state => state.cart.cart.length > 0 && (state.cart.cart.findIndex(x => x.isReturnKey === true && !x.returnKeyAddedByUser) >= 0) ;
export const selectReturnKeyFromCart = state => state.cart.cart.length > 0 && (state.cart.cart.find(x => x.isReturnKey === true)) ;

export const selectHasReturnKeyForLocation = state => state.cart.returnKey !== null;

export const selectDisabledNonRecurringTab = state => state.settings.settings.all_articles_in_same_interval && CartSliceUtils.cartHasRecurringItems(state);
export const selectDisabledRecurringTab = state => state.settings.settings.all_articles_in_same_interval && CartSliceUtils.cartHasNonRecurringItems(state);

export default slice.reducer;