import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from "react-i18next";

import { Stack, Typography } from '@mui/material';

import RecurrenceTypeSelect from './components/RecurrenceTypeSelect';
import RecurrenceRepetitionIntervalSelect from './components/RecurrenceRepetitionIntervalSelect';
import RecurrenceDayAndTimeSelector from './components/RecurrenceDayAndTimeSelector';
import RecurrenceTypes from './utils/RecurrenceTypes';

import { selectRecurrenceType } from '../../../../store/filtersSlice';

export default function RecurringIntervalSelector(props) {
    const { direction, item = false, subarticle = false, isCartItem = false } = props;
    const { t } = useTranslation();
    const recurrenceType = useSelector(selectRecurrenceType);
    const shownRecurrenceType = subarticle?.recurrenceType ?? item?.recurrenceType ?? recurrenceType;
    const fromToShown = shownRecurrenceType === RecurrenceTypes.RECURRENCE_TYPE_FROM_UNTIL;
    
    return (
        <Stack direction={direction ?? { xs: 'column', lg: fromToShown ? 'column' : 'row' }} spacing={{ xs: 2, sm: 2 }} alignItems='flex-start'>
            <Stack direction={direction ?? { xs: 'column', md: 'row' }} spacing={{ xs: 2, sm: 2 }} width={1}>
                <RecurrenceTypeSelect direction={direction ?? { xs: 'column', md: 'row' }} item={item} subarticle={subarticle} isCartItem={isCartItem} />
                <RecurrenceRepetitionIntervalSelect item={item} subarticle={subarticle} isCartItem={isCartItem} />
            </Stack>
            <Stack direction={direction ?? { xs: 'column', md: 'row' }} spacing={{ xs: 1, md: 1 }} width={1}>
                <RecurrenceDayAndTimeSelector item={item} subarticle={subarticle} isCartItem={isCartItem} isStartDay />
                <Stack direction='column' spacing={1} alignItems="center" justifyContent="center">
                    <Typography variant="body1" color="text.secondary">{t("recurring.until")}</Typography>
                </Stack>
                <RecurrenceDayAndTimeSelector item={item} subarticle={subarticle} isCartItem={isCartItem} />
            </Stack>
        </Stack>
    );
};