export class CategoriesUtils {

    static prepareCategoryPath(categories, searchId) {
        /**
         * contains name, to
         * @type {Array}
         */
        let path = [];

        function _setPath(name, idCategory) {
            path.unshift({
                name: name,
                id : idCategory,
                to : '/items/' + idCategory
            });
        }

        function _findParent(categories, searchId) {
            // for (var index )
            var category, child;
            for (var index in categories) {
                category = categories[index].Category;

                if (category.idkat === searchId) {

                    _setPath(category.kat, category.idkat); // last elem
                    return true;
                } else {

                    child = _findParent(categories[index].children, searchId);

                    if (child === true) {

                        _setPath(category.kat, category.idkat); // parent
                        return true;
                    }
                }
            }
            return false;
        }

        _findParent(categories, searchId);

        return path;
    }
}