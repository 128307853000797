import { Api } from '../api/api'; 

import DateTimeFormatConfig from './DateTimeFormatConfig';
import CheckWorkingHours from './CheckWorkingHours';
import RecurrenceOccurences from './RecurrenceOccurences';
import WorkingDays from './WorkingDays';
import dayjs from 'dayjs';

const CONFIRM_RETURN_KEY_INTERVAL_BEFORE_CLOSING = 30;// min

class ReturnKey {

    static prepareReturnKeyData(cart, returnKeyInitialData, workingHours, semesterDur) {
        // let returnKeyData = {
        //     "optional": false,
        //     "required": false,
        //     "available": true,
        //     "intervals": [],
        //     "intervalsForAvailability": [],
        //     "details": {}
        // };
        let returnKeyData = {...returnKeyInitialData};

        const cartItemIntervals = this.getCartItemsIntervals(cart, workingHours, semesterDur);
        const returnKeyOptionalOrRequired = this.cartHasArticleIntervalsWithReturnTimesRightBeforeOrAfterClosingTime(cartItemIntervals);
        returnKeyOptionalOrRequired.returnKeyIntervals = this.mergeIntervalsForReturnKey(returnKeyOptionalOrRequired.returnKeyIntervals);
        returnKeyOptionalOrRequired.returnKeyIntervalsForAvailabilityCheck = this.prepareIntervalsForAvailabilityCheck(returnKeyOptionalOrRequired.returnKeyIntervals);
        returnKeyData.optional = returnKeyOptionalOrRequired.returnKeyOptional;
        returnKeyData.required = returnKeyOptionalOrRequired.returnKeyRequired;
        returnKeyData.intervals = returnKeyOptionalOrRequired.returnKeyIntervals;
        returnKeyData.intervalsForAvailability = returnKeyOptionalOrRequired.returnKeyIntervalsForAvailabilityCheck;

        const returnKeyDataHasChanged = ReturnKey.returnKeyDataHaveChanged(returnKeyInitialData, returnKeyData);

        return { returnKeyData, returnKeyDataHasChanged};
    }

    static async checkReturnKeyAvailability(item, hasExtendedTimes, wh) {
        let returnKeyData = {
            "optional": false,
            "required": false,
            "available": true,
            "key": {
                "intervals": [],
                "details": {}
            }
        };

        // Extended hours activated
        if(hasExtendedTimes) {
            // Get cart items intervals
            let itemIntervals = this.getItemIntervals(item, wh);

            // Check intervals with return times close to or after closing time
            let returnKeyOptionalOrRequired = this.cartHasArticleIntervalsWithReturnTimesRightBeforeOrAfterClosingTime(itemIntervals);
            console.log("returnKeyOptionalOrRequired: ", returnKeyOptionalOrRequired);
            let intervals = this.mergeIntervalsForReturnKey(returnKeyOptionalOrRequired.returnKeyIntervals);
            console.log("returnKey intervals: ", intervals);
            // Return key is optional or is mandatory
            if(returnKeyOptionalOrRequired && (returnKeyOptionalOrRequired.returnKeyRequired || returnKeyOptionalOrRequired.returnKeyOptional)) {
                let returnKey = await Api.getReturnKey(/*tenant_id*/);
                console.log("Received return key: ", returnKey);
                let intervalsForAvailability = this.prepareIntervalsForAvailabilityCheck(intervals);

                let returnKeyAvalilability = await Api.checkKeyAvailability(returnKey.product_id, intervalsForAvailability);
                console.log("Received return key availability: ", returnKeyAvalilability);
                if(returnKeyOptionalOrRequired.returnKeyRequired) {
                    returnKeyData.required = true;
                    returnKeyData.optional = false;
                    
                    returnKeyData.available = returnKeyAvalilability.available;
                    returnKeyData.key.details = returnKey;
                    returnKeyData.key.intervals = intervals;
                } else {
                    returnKeyData.required = false;
                    returnKeyData.optional = true;
                    // For now return always available if the key is optional
                    // returnKeyData.available = returnKeyAvalilability.available;
                    returnKeyData.available = true;
                    returnKeyData.key.details = returnKey;
                    returnKeyData.key.intervals = intervals;
                }
            }
        } else {
            console.log("NO Extended times. Regular reservation within opening times.");
        }

        return returnKeyData;
    }
    
    static getItemIntervals(item, wh) {
        let returnKeyIntervals = [];

        // Get working hours for interval
        item.workingDays = WorkingDays.getWorkingDaysForDates(wh, item.startDate, item.endDate, 'all');

        // If we have recurring items get the interval for each occurence
        if(item.recurring) {
            let occurences = [];

            let startDate = DateTimeFormatConfig.standardServerDateFormat(item.startDate);
            let endDate = DateTimeFormatConfig.standardServerDateFormat(item.endDate);

            occurences = RecurrenceOccurences.calculateOccurence(startDate, endDate, item.repetitionInterval, item.recurrenceStartDay, item.recurrenceEndDay);

            if (occurences.length > 0) {
                occurences.forEach((occurence) => {
                    let occurenceFake = {
                        "startDate": DateTimeFormatConfig.standardPickerDateFormat(occurence.startDate + ' ' + item.startTime),
                        "endDate": DateTimeFormatConfig.standardPickerDateFormat(occurence.endDate + ' ' + item.endTime),
                        "workingDays": item.workingDays
                    }

                    returnKeyIntervals = this.prepareUniqueIntervals(occurenceFake, returnKeyIntervals);
                });
            }
        } else {
            returnKeyIntervals = this.prepareUniqueIntervals(item, returnKeyIntervals);
        }

        return returnKeyIntervals;
    }

    static cartHasArticleIntervalsWithReturnTimesRightBeforeOrAfterClosingTime(intervals) {
        let cartReturnKeyRequirements = {
            "returnKeyOptional": false,
            "returnKeyRequired": false,
            "returnKeyIntervals": []
        };

        // If we have items in cart
        if(intervals.length > 0) {
            intervals.forEach((interval, index) => {
                let intervalEnd = DateTimeFormatConfig.getDateFromStandardPickerDateFormat(interval.end);
                let intervalEndTime = DateTimeFormatConfig.formatDateToTimeOnlyString(intervalEnd);
                let intervalWorkingHours = interval.wh;
                let itemKeyRequirements = CheckWorkingHours.checkWithinOpeningHours(intervalEndTime, intervalEnd, intervalWorkingHours, CONFIRM_RETURN_KEY_INTERVAL_BEFORE_CLOSING);
                
                if(itemKeyRequirements.returnKeyOptional || itemKeyRequirements.returnKeyRequired) {
                    cartReturnKeyRequirements.returnKeyOptional = cartReturnKeyRequirements.returnKeyOptional || itemKeyRequirements.returnKeyOptional;
                    cartReturnKeyRequirements.returnKeyRequired = cartReturnKeyRequirements.returnKeyRequired || itemKeyRequirements.returnKeyRequired;
                    cartReturnKeyRequirements.returnKeyIntervals.push(interval);

                    // If return key is required it is not optional
                    cartReturnKeyRequirements.returnKeyOptional = cartReturnKeyRequirements.returnKeyRequired ? !cartReturnKeyRequirements.returnKeyRequired : cartReturnKeyRequirements.returnKeyOptional;
                }
            });
        }

        return cartReturnKeyRequirements;
    }
    
    static getCartItemsIntervals(myCart, wh, semesterDur) {
        let cart = myCart;
        let cartReturnKeyIntervals = [];
        // If we have items in cart
        if(cart.length > 0) {
            cart.forEach((cartItem, index) => {
                // Skip the key if there is one already inside the cart
                if (cartItem.isReturnKey) return;

                if(cartItem.isConfigurable && Object.values(cartItem.subarticles).length > 0) {
                    Object.values(cartItem.subarticles).forEach((cartSubItem, index) => {
                        cartReturnKeyIntervals = this.prepareIntervals(cartReturnKeyIntervals, cartSubItem, wh, semesterDur);
                    });
                } else {
                    cartReturnKeyIntervals = this.prepareIntervals(cartReturnKeyIntervals, cartItem, wh, semesterDur);
                }
            });
            return cartReturnKeyIntervals;
        } else {
            return false;
        }
    }
    
    static prepareIntervals(intervals, item, wh, semesterDates) {
        let cartItem = {...item};
        // Get working hours for interval
        cartItem.workingDays = WorkingDays.getWorkingDaysForDates(wh, semesterDates, cartItem.startDate, cartItem.endDate, 'all');
        // If we have recurring items get the interval for each occurence
        if(cartItem.occurence && cartItem.occurence.length > 0) {
            cartItem.occurence.forEach((occurence, index) => {
                let cartItemOccurence = {...occurence};
                cartItemOccurence.workingDays = cartItem.workingDays;
                intervals = this.prepareUniqueIntervals(cartItemOccurence, intervals);
            });
        } else {
            intervals = this.prepareUniqueIntervals(cartItem, intervals);
        }
        return intervals;
    }

    static mergeIntervalsForReturnKey(intervals) {
        if (intervals.length < 2) return intervals;
        
        const mergedIntervals = [];
        let previous = intervals[0];
        let previousEndForCompare = DateTimeFormatConfig.standardServerDateFormat(previous.end);// moment.default(previous.end, "DD MMMM, YYYY HH:mm").format("YYYY-MM-DD HH:mm");
        
        for (let i = 1; i < intervals.length; i += 1) {
            let nextIntervalsStartDate = DateTimeFormatConfig.getDateFromStandardPickerDateFormat(intervals[i].start);// moment.default(intervals[i].start, "DD MMMM, YYYY HH:mm");
            let nextIntervalsStartDateReduced = DateTimeFormatConfig.standardServerDateFormat(nextIntervalsStartDate.subtract(24, "h"));// nextIntervalsStartDate.subtract(24, "h").format("YYYY-MM-DD HH:mm");
            let intervalStartForCompare = DateTimeFormatConfig.standardServerDateFormat(intervals[i].start); // moment.default(intervals[i].start, "DD MMMM, YYYY HH:mm").format("YYYY-MM-DD HH:mm");
            let intervalEndForCompare = DateTimeFormatConfig.standardServerDateFormat(intervals[i].end);// moment.default(intervals[i].end, "DD MMMM, YYYY HH:mm").format("YYYY-MM-DD HH:mm");

            if (previousEndForCompare >= intervalStartForCompare) {
                previous.end = previousEndForCompare > intervalEndForCompare ? previous.end : intervals[i].end;
            } else if (previousEndForCompare >= nextIntervalsStartDateReduced) { // check if it's within 24h from the next interval's start date
                previous.end = previousEndForCompare > intervalEndForCompare ? previous.end : intervals[i].end;
            } else {
                mergedIntervals.push(previous);
                previous = intervals[i];
            }
            // Recreate the formatted end date for compare with the new end date
            previousEndForCompare = DateTimeFormatConfig.standardServerDateFormat(previous.end);// moment.default(previous.end, "DD MMMM, YYYY HH:mm").format("YYYY-MM-DD HH:mm");
        }
        
        mergedIntervals.push(previous);
        
        return mergedIntervals;
      };

    static prepareUniqueIntervals(needle, haystack) {
        let interval = {
            "start": needle.startDate,
            "end": needle.endDate,
            "wh": needle.workingDays
        }

        // Skip duplicated intervals
        if(haystack.findIndex(x => x.start === interval.start && x.end === interval.end) === -1) {
            haystack.push(interval);
        }

        // Sort the intervals
        haystack.sort((a, b) => {
            const start_a = DateTimeFormatConfig.standardServerDateFormat(a.start);// moment.default(a.start, "DD MMMM, YYYY HH:mm").format("YYYY-MM-DD HH:mm");
            const start_b = DateTimeFormatConfig.standardServerDateFormat(b.start);// moment.default(b.start, "DD MMMM, YYYY HH:mm").format("YYYY-MM-DD HH:mm");

            if(start_a < start_b) return -1;
            if(start_a > start_b) return 1;
            return 0;
        });

        return haystack;
    }

    static async checkCartReturnKey(myCart, start, end, location, hasExtendedTimes, wh) {
        let returnKeyData = {
            "optional": false,
            "required": false,
            "available": true,
            "key": {
                "intervals": [],
                "details": {}
            }
        };

        console.log("Cart: ", myCart);
        console.log("Start: ", start);
        console.log("End: ", end);
        console.log("Has Extended Times: ", hasExtendedTimes);
        console.log("Working Hours: ", wh);

        // Extended hours activated
        if(hasExtendedTimes) {
            let cart = myCart;

            // Get cart items intervals
            let articlesIntervals = this.getCartItemsIntervals(cart, wh);
            console.log("checkCartReturnKey :-: articlesIntervals: ", articlesIntervals);
            // Check intervals with return times close to or after closing time
            let returnKeyOptionalOrRequired = this.cartHasArticleIntervalsWithReturnTimesRightBeforeOrAfterClosingTime(articlesIntervals);
            console.log("checkCartReturnKey :-: returnKeyOptionalOrRequired: ", returnKeyOptionalOrRequired);
            let intervals = this.mergeIntervalsForReturnKey(returnKeyOptionalOrRequired.returnKeyIntervals);

            // Return key is optional or is mandatory
            if(returnKeyOptionalOrRequired && (returnKeyOptionalOrRequired.returnKeyRequired || returnKeyOptionalOrRequired.returnKeyOptional)) {
                let returnKey = await Api.getReturnKey(location, /*tenant_id*/);
                
                let intervalsForAvailability = this.prepareIntervalsForAvailabilityCheck(intervals);

                let returnKeyAvalilability = await Api.checkKeyAvailability(returnKey.product_id, intervalsForAvailability, location);
                returnKeyData.available = returnKeyAvalilability.available;
                returnKeyData.key = this.prepareReturnKeyDetailsData(returnKey, intervals);

                if(returnKeyOptionalOrRequired.returnKeyRequired) {
                    console.log("Return key required and not optional - Add to cart!");
                    returnKeyData.required = true;
                    this.addReturnKeyToCart(cart, returnKey.product_id, returnKey, intervals, start, end);
                } else {
                    returnKeyData.optional = true;
                }
            } else {
                console.log("Return key not required and not optional - remove from cart: ");
                this.removeReturnKeyFromCart(cart);
            }

        } else {
            console.log("NO Extended times. Regular reservation within opening times.");
        }

        return returnKeyData;
    }

    static prepareReturnKeyDetailsData(returnKey, intervals) {
        return {
            "intervals": intervals,
            "details": returnKey
        };
    }
    
    static addReturnKeyToCart(myCart, itemKey, item, intervals, start, end, recurring, addedByUser = false) {
        item.item_id = itemKey;

        item.recurring = recurring;
        item.isReturnKey = true;
        item.available = true;
        item.returnKeyAddedByUser = addedByUser;

        // Using this interval is missleading - removing for now
        // item.startDate = item.startDate || start;
        // item.endDate = item.endDate || end;

        let cart = myCart;
        let unique_stamp = dayjs().format("X");

        if(!item.recurring) {
            if (intervals.length === 1) {
                intervals.forEach((interval) => {
                    item.startDate = interval.start; // AppConfig.standardPickerDateFormat(interval.start);
                    item.endDate = interval.end; // AppConfig.standardPickerDateFormat(interval.end);
                    item.isReturnKey = true;
                    item.available = true;
                    item.returnKeyAddedByUser = addedByUser;
                });
            } else if(intervals.length >= 1) {
                let occ = JSON.stringify(item);
                item.occurence = [];

                intervals.forEach((interval) => {
                    let parseOcc = JSON.parse(occ);

                    parseOcc.startDate = interval.start; // AppConfig.standardPickerDateFormat(interval.start);
                    parseOcc.endDate = interval.end; // AppConfig.standardPickerDateFormat(interval.end);
                    parseOcc.resId = unique_stamp;
                    parseOcc.isReturnKey = true;
                    parseOcc.available = true;
                    parseOcc.returnKeyAddedByUser = addedByUser;

                    item.occurence.push(parseOcc);
                });
            }
            
            cart = this.addOrReplaceReturnKeyInCart(cart, item);
        } else {
            item.occurence = [];
            item.isReturnKey = true;
            item.returnKeyAddedByUser = addedByUser;
            let occ = JSON.stringify(item);

            if (intervals.length > 0) {
                intervals.forEach(function (occurence) {
                    let parseOcc = JSON.parse(occ);

                    parseOcc.startDate = occurence.start; // AppConfig.standardPickerDateFormat(occurence.start);
                    parseOcc.endDate = occurence.end; // AppConfig.standardPickerDateFormat(occurence.end);
                    parseOcc.resId = unique_stamp;
                    parseOcc.isReturnKey = true;
                    parseOcc.available = true;
                    parseOcc.returnKeyAddedByUser = addedByUser;

                    item.occurence.push(parseOcc)

                });
                
                cart = this.addOrReplaceReturnKeyInCart(cart, item);
            }
        }

        //localStorage.setItem("cart", JSON.stringify(cart));
        return cart;
    }

    static removeReturnKeyFromCart(myCart) {
        let cart = myCart;
        let returnKeyInCart = cart.findIndex(x => x.isReturnKey === true);
        if( returnKeyInCart !== -1) {
            cart.splice(returnKeyInCart, 1);
            // localStorage.setItem('cart', JSON.stringify(cart));
            return cart;
        }
    }

    static getCartReturnKey(myCart) {
        let cart = myCart;
        let returnKeyInCart = cart.findIndex(x => x.isReturnKey === true);
        if( returnKeyInCart === -1) {
            return false;
        } else {
            return cart[returnKeyInCart];
        }
    }

    static cartContainsAReturnKey(cart) {
        let returnKeyInCart = cart.findIndex(x => x.isReturnKey === true);
        if( returnKeyInCart === -1) {
            return false;
        } else {
            return true;
        }
    }

    static cartContainsAMandatoryReturnKey(cart) {
        let returnKeyInCart = cart.findIndex(x => x.isReturnKey && !x.returnKeyAddedByUser);
        if( returnKeyInCart === -1) {
            return false;
        } else {
            return true;
        }
    }

    // Format the intervals for the availability check
    static prepareIntervalsForAvailabilityCheck(intervals) {
        let intervalsForAvailability = [];
        if(intervals.length > 0) {
            intervals.forEach((interval, index) => {
                intervalsForAvailability.push({
                    'startDate': DateTimeFormatConfig.standardServerDateFormat(interval.start),// moment.default(interval.start, "DD MMMM, YYYY HH:mm").format("YYYY-MM-DD HH:mm"),
                    'endDate': DateTimeFormatConfig.standardServerDateFormat(interval.end)// moment.default(interval.end, "DD MMMM, YYYY HH:mm").format("YYYY-MM-DD HH:mm")
                });
            });
        }

        return intervalsForAvailability;
    }

    static addOrReplaceReturnKeyInCart(cart, item) {
        let returnKeyInCart = cart.findIndex(x => x.isReturnKey === true);
        let newCart = [...cart];
        if( returnKeyInCart === -1 ) {
            newCart.push(item);
        } else {
            newCart[returnKeyInCart] = item;
        }

        return newCart;
    }

    static returnKeyDataHaveChanged(returnKeyInitialData, returnKeyData) {
        // let returnKeyData = {
        //     "optional": false,
        //     "required": false,
        //     "available": true,
        //     "intervals": [],
        //     "intervalsForAvailability": [],
        //     "details": {}
        // };
        if(
            returnKeyInitialData.optional !== returnKeyData.optional
            || returnKeyInitialData.required !== returnKeyData.required
            || returnKeyInitialData.available !== returnKeyData.available
            || returnKeyInitialData.intervals.length !== returnKeyData.intervals.length
            ) {
            return true;
        }

        // Check intervals end dates have changed
        return returnKeyInitialData.intervals.some((initialInterval, index) => {
            return returnKeyInitialData.intervals[index].end !== returnKeyData.intervals[index].end;
        });
    }
}

export default ReturnKey;