import React from 'react';

import { Box } from '@mui/material';

import logo from "../../../../resources/qv_logo.svg";
import ShopConfig from '../../../api/config/shopConfig';

import { useSelector } from 'react-redux';
import { selectOrganisationLogo } from '../../../store/settingsSlice';

export default function Logo(props) {
    const {position = "absolute"} = props;
    const organisationLogo = useSelector(selectOrganisationLogo);

    return (
        <>
        { organisationLogo === "" ?
            <Box
                component="img"
                sx={{
                    mr: 3,
                    width: 170,
                    maxWidth: { xs: 100, md: 130 },
                    position: position
                }}
                alt="QVLogo"
                src={logo}
            /> :
            <Box
                component="img"
                alt="OrganisationLogo"
                src={ShopConfig.config().getURLFor(organisationLogo)}
                sx={{ maxHeight: (theme) => theme.mixins.toolbar.minHeight * 0.95, objectFit: "contain", maxWidth: { xs: 150, sm: 200, md: 250 }, pl: 1, ...props.sx }}
            />

        }
        </>
    );
}
