import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useTranslation } from "react-i18next";

import { AppBar, Box, Toolbar, IconButton, Badge, MenuItem, Menu } from '@mui/material';
import TuneIcon from '@mui/icons-material/Tune';
import SearchIcon from '@mui/icons-material/Search';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import PersonIcon from '@mui/icons-material/Person';

import * as sx from "./sx/ShopAppBarSx";

import { useAuth } from "../../../hooks/useAuth";
import { Storage } from '../../../utils/Storage';
import Search from './Search';
import LogoAsLink from './LogoAsLink';

import { toggleShowSearch, selectShowFilterIcon } from '../../../store/layoutSlice';
import { selectItemsInCart } from '../../../store/cartSlice';

export default function ShopAppBar(props) {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { onLogout } = useAuth();

    const token = Storage.getAuthToken();
    const showFilterIcon = useSelector(selectShowFilterIcon);
    const itemsInCart = useSelector(selectItemsInCart)
    const { handleDrawerToggle } = props;
    const [anchorEl, setAnchorEl] = useState(null);
    const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);

    const isMenuOpen = Boolean(anchorEl);
    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

    const handleProfileMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMobileMenuClose = () => {
        setMobileMoreAnchorEl(null);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
        handleMobileMenuClose();
    };

    const handleLogout = () => {
        setAnchorEl(null);
        handleMobileMenuClose();

        onLogout();
    };

    const handleLogin = () => {
        setAnchorEl(null);
        handleMobileMenuClose();

        navigate("/login");
    };

    const handleShowSearch = () => {
        dispatch(toggleShowSearch());
    }

    const menuId = 'primary-search-account-menu';
    const renderMenu = (
        <Menu
            anchorEl={anchorEl}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            id={menuId}
            keepMounted
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            open={isMenuOpen}
            onClose={handleMenuClose}
        >
            { /* Only login/logout for now */ false && <MenuItem onClick={handleMenuClose}>Profile</MenuItem> }
            { /* Only login/logout for now */ false && <MenuItem onClick={handleMenuClose}>My account</MenuItem> }
            { token ? <MenuItem onClick={handleLogout}>{t("AppBar.logout")}</MenuItem> : <MenuItem onClick={handleLogin}>{t("AppBar.login")}</MenuItem> }
        </Menu>
    );

    const mobileMenuId = 'primary-search-account-menu-mobile';
    const renderMobileMenu = (
        <Menu
            anchorEl={mobileMoreAnchorEl}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            id={mobileMenuId}
            keepMounted
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            open={isMobileMenuOpen}
            onClose={handleMobileMenuClose}
        >
            <MenuItem>
                <IconButton
                    size="large"
                    aria-label="cart"
                    color="primary"
                    component={RouterLink} to={"/cart"}
                >
                    <Badge badgeContent={2} color="error">
                        <ShoppingCartIcon />
                    </Badge>
                </IconButton>
                <p>Cart</p>
            </MenuItem>
            <MenuItem onClick={handleProfileMenuOpen}>
                <IconButton
                    size="large"
                    aria-label="account of current user"
                    aria-controls="primary-search-account-menu"
                    aria-haspopup="true"
                    color="inherit"
                >
                    <PersonIcon />
                </IconButton>
                <p>Profile</p>
            </MenuItem>
        </Menu>
    );

    return (
        <>
            <AppBar elevation={0} position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }} color="appBarBGColor" >
                <Toolbar>
                    <LogoAsLink to="/" />
                    <Box sx={sx.flexGrowCentered}>
                        <Box sx={{ display: { xs: 'none', sm: 'flex' } }}>
                            <Search />
                        </Box>
                    </Box>
                    <Box >
                        {showFilterIcon && <IconButton
                            size="large"
                            color={showFilterIcon ? "primary" : "inherit"}
                            edge="end"
                            aria-label="open drawer"
                            onClick={handleDrawerToggle}
                            sx={{ display: { sm: 'none' } }}
                        >
                            <TuneIcon />
                        </IconButton>}
                        <IconButton
                            size="large"
                            color="primary"
                            edge="end"
                            aria-label="open drawer"
                            onClick={handleShowSearch}
                            sx={{ display: { sm: 'none' } }}
                        >
                            <SearchIcon />
                        </IconButton>
                        { itemsInCart > 0 && <IconButton
                            size="large"
                            edge="end"
                            aria-label="cart"
                            color="primary"
                            component={RouterLink} to={"/cart"}
                        >
                            <Badge badgeContent={itemsInCart} color="error">
                                <ShoppingCartIcon />
                            </Badge>
                        </IconButton> }
                        <IconButton
                            size="large"
                            edge="end"
                            aria-label="account of current user"
                            aria-controls={menuId}
                            aria-haspopup="true"
                            onClick={token ? handleProfileMenuOpen : handleLogin}
                            color={token ? "primary" : "default"}
                        >
                            <PersonIcon />
                        </IconButton>
                    </Box>
                </Toolbar>
            </AppBar>
            {renderMobileMenu}
            {renderMenu}
        </>
    );
}
