import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Outlet, useLocation } from "react-router-dom";

import { useAuth } from "../../common/hooks/useAuth";

import { selectUserHasLocations } from '../../common/store/locationsSlice';

export const CheckoutLayout = () => {
    const { token } = useAuth();
    const location = useLocation();
    const userHasLocations = useSelector(selectUserHasLocations);

    if (!(token && userHasLocations)) {
        return <Navigate to="/login" replace state={{ from: location }} />;
    }
  
    return (
        <div>
            <Outlet />
        </div>
    )
};