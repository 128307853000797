import { createSlice } from '@reduxjs/toolkit';

const STATUS_IDLE = 'idle';
const STATUS_LOADING = 'loading';
// const STATUS_SUCCEEDED = 'succeeded';
// const STATUS_FAILED = 'failed';

const initialState = {
    status: STATUS_IDLE,
    error: null,

    activeScreen: '',
};

export const slice = createSlice({
    name: 'appState',
    initialState,

    reducers: {
        setStatus: (state, action) => {
            state.status = action.payload;
        },
        setActiveScreen: (state, action) => {
            state.activeScreen = action.payload;
        }
    }
});

export const { setStatus, setActiveScreen } = slice.actions;

export const selectActiveScreen = state => state.appState.activeScreen;
export const selectIsLoading = state => state.appState.status === STATUS_LOADING;

export default slice.reducer;