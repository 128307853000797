import dayjs from 'dayjs';
import DateTimeFormatConfig from './DateTimeFormatConfig';

// should be like on the server
const PriceUnitDay = 0;
const PriceUnitHour = 1;

/**
 * Calculates the price of an article based on the price and price unit, as well as the specified from and to dates.
 * @param {number} price - The price of the article.
 * @param {string} priceUnit - The price unit of the article, either "hour" or "day".
 * @param {Date} fromDate - The start date of the rental period.
 * @param {Date} toDate - The end date of the rental period.
 * @returns {number} - The calculated price of the article.
 */
export function calculateArticlePrice(price, priceUnit, fromDate, toDate, quantity = 1) {
    // console.log(`calculateArticlePrice: price: ${price}, priceUnit: ${priceUnit}, fromDate: ${fromDate}, toDate: ${toDate}`);

    if(!fromDate || !toDate) {
        return 0;
    }

    const from = DateTimeFormatConfig.getDateFromStandardPickerDateFormat(fromDate);

    // subtracts 1 second from the end date, so that the end date is not counted as a full day/hour
    // if the dates both start at same time or same minutes for hours interval
    const to = DateTimeFormatConfig.getDateFromStandardPickerDateFormat(toDate).subtract(1, 'second');

    const numUnits = to.diff(from, priceUnit == PriceUnitHour ? 'hour' : 'day') + 1;

    // console.log(priceUnit == PriceUnitHour ? 'hour' : 'day')
    // console.log(`calculateArticlePrice: numUnits: ${numUnits}`);

    return price * numUnits * quantity;
}
