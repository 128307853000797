import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { Api } from "../api/api";

const STATUS_IDLE = 'idle';
const STATUS_LOADING = 'loading';
const STATUS_SUCCEEDED = 'succeeded';
const STATUS_FAILED = 'failed';

const initialState = {
    status: STATUS_IDLE,
    error: null,

    activeStep: 0,

    emailUsername: "",
    emailUsernameError: false,
    emailUsernameErrorCode: "",

    code: "",
    codeError: false,
    codeErrorCode: "",

    password: "",
    passwordError: false,

    passwordConfirm: "",
    passwordConfirmError: false,
    passwordConfirmErrorCode: "",
};

export const requestPasswordResetCode = createAsyncThunk(
    'shop/requestPasswordResetCode',
    async (_noParams, { getState }) => {
        const emailUsername = getState().passwordForgotten.emailUsername;
        const response = await Api.requestPasswordResetCode(emailUsername);
        return response;
    }
)

export const resetPassword = createAsyncThunk(
    'shop/resetPassword',
    async (_noParams, { getState }) => {
        const state = getState();
        const emailUsername = state.passwordForgotten.emailUsername;
        const code = state.passwordForgotten.code;
        const password = state.passwordForgotten.password;
        
        const response = await Api.resetPassword(emailUsername, code, password);
        return response;
    }
)

export const slice = createSlice({
    name: 'passwordForgotten',
    initialState,

    reducers: {
        setStatus: (state, action) => {
            state.status = action.payload;
        },
        setActiveStep: (state, action) => {
            state.activeStep = action.payload;
        },
        setEmailUsername: (state, action) => {
            state.emailUsername = action.payload;
            state.emailUsernameError = false; 
        },
        setEmailUsernameError: (state, action) => {
            state.emailUsernameError = action.payload;
        },
        setCode: (state, action) => {
            state.code = action.payload;
            state.codeError = false; 
        },
        setCodeError: (state, action) => {
            state.codeError = action.payload;
        },
        setPassword: (state, action) => {
            state.password = action.payload;
            state.passwordError = false; 
        },
        setPasswordError: (state, action) => {
            state.passwordError = action.payload;
        },
        setPasswordConfirm: (state, action) => {
            state.passwordConfirm = action.payload;
            state.passwordConfirmError = false; 
        },
        setPasswordConfirmError: (state, action) => {
            state.passwordConfirmError = action.payload;
        },
        resetState: (state, action) => {
            state.activeStep = 0;

            state.emailUsername = "";
            state.emailUsernameError = false;
            state.emailUsernameErrorCode = "";

            state.code = "";
            state.codeError = false;
            state.codeErrorCode = "";

            state.password = "";
            state.passwordError = false;

            state.passwordConfirm = "";
            state.passwordConfirmError = false;
            state.passwordConfirmErrorCode = "";
        },
    },

    extraReducers: (builder) => {
        builder
            .addCase(requestPasswordResetCode.pending, (state) => {
                state.status = STATUS_LOADING;
            })
            .addCase(requestPasswordResetCode.fulfilled, (state, action) => {
                state.status = STATUS_SUCCEEDED;

                state.emailUsernameError = !action.payload.success;
                state.emailUsernameErrorCode = action.payload.error;
                if(action.payload.success) {
                    state.activeStep = state.activeStep + 1;
                }
            })
            .addCase(requestPasswordResetCode.rejected, (state, action) => {
                state.status = STATUS_FAILED;
                state.error = action.payload;
            })

            .addCase(resetPassword.pending, (state) => {
                state.reset_password_status = STATUS_LOADING;
            })
            .addCase(resetPassword.fulfilled, (state, action) => {
                state.status = STATUS_SUCCEEDED;

                state.codeError = !action.payload.success;
                state.codeErrorCode = action.payload.error;
                if(action.payload.success) {
                    state.activeStep = state.activeStep + 1;
                }
            })
            .addCase(resetPassword.rejected, (state, action) => {
                state.status = STATUS_FAILED;
                state.error = action.payload;
            })
    }
});

export const { setStatus, setActiveStep, setEmailUsername, setEmailUsernameError, setCode, setCodeError, setPassword, setPasswordError, setPasswordConfirm, setPasswordConfirmError, resetState } = slice.actions;

export const selectIsLoading = state => state.passwordForgotten.status === STATUS_LOADING;
export const selectActiveStep = state => state.passwordForgotten.activeStep;

export const selectEmailUsername = state => state.passwordForgotten.emailUsername;
export const selectEmailUsernameError = state => state.passwordForgotten.emailUsernameError;
export const selectEmailUsernameErrorCode = state => state.passwordForgotten.emailUsernameErrorCode;
export const selectCode = state => state.passwordForgotten.code;
export const selectCodeError = state => state.passwordForgotten.codeError;
export const selectCodeErrorCode = state => state.passwordForgotten.codeErrorCode;
export const selectPassword = state => state.passwordForgotten.password;
export const selectPasswordError = state => state.passwordForgotten.passwordError;
export const selectPasswordConfirm = state => state.passwordForgotten.passwordConfirm;
export const selectPasswordConfirmError = state => state.passwordForgotten.passwordConfirmError;
export const selectPasswordsDoNotMatch = state => state.passwordForgotten.password !== state.passwordForgotten.passwordConfirm;

export default slice.reducer;