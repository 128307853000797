import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import PageNames from '../utils/PageNames';

import { setActiveScreen } from '../store/appStateSlice';
import { setShowSidebar, setShowFilterIcon } from '../store/layoutSlice';

const showSidebarForPage = {
    [PageNames.PRODUCTS_LIST]: {
        showSidebar: true,
        showFilterIcon: true
    },
    [PageNames.CHECKOUT]: {
        showSidebar: false,
    },
    [PageNames.CART]: {
        showFilterIcon: true
    }
}

export const usePageLayoutConfigurator = (forPage = PageNames.PRODUCTS_LIST) => {
    const dispatch = useDispatch();

    useEffect(() => {
        if(showSidebarForPage[forPage] && showSidebarForPage[forPage]?.showSidebar) {
            dispatch(setShowSidebar(showSidebarForPage[forPage]?.showSidebar ?? false));
        }

        if(showSidebarForPage[forPage] && showSidebarForPage[forPage]?.showFilterIcon) {
            dispatch(setShowFilterIcon(showSidebarForPage[forPage]?.showFilterIcon ?? false));
        }

        dispatch(setActiveScreen(forPage));

        return () => {
            dispatch(setShowSidebar(false));
            dispatch(setShowFilterIcon(false));

            dispatch(setActiveScreen(""));
        }
    }, [dispatch, forPage]);
}