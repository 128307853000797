import React from 'react';
import { useTranslation } from "react-i18next";

import { Stack, Typography } from '@mui/material';

import ConfirmationDialogBase from './ConfirmationDialogBase';

export default function ReturnKeyDialog(props) {
    const { t } = useTranslation();
    const { title, text1, text2} = props;

    return (
        <ConfirmationDialogBase title={title} primaryButtonLabel={t("popups.addReturnKeyPrimaryButton")} secondaryButtonLabel={t("popups.addReturnKeySecondaryButton")} {...props}>
                <Stack direction="column" spacing={2}>
                    <Typography variant="body1">{text1}</Typography>
                    <Typography variant="body1">{text2}</Typography>
                </Stack>
        </ConfirmationDialogBase>           
    );
}