import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from "react-i18next";

import { List, ListSubheader, Stack, Typography } from '@mui/material';
import FilterListIcon from '@mui/icons-material/FilterList';

import SpecificationsFilter from '../../SpecificationsFilter';

import { selectSpecifications } from '../../../../../store/productsSlice';

export default function SpecificationsFilterSection() {
    const { t } = useTranslation();
    const specifications = useSelector(selectSpecifications);

    return (
        <>
            { (specifications && specifications.length > 0) ? <List component="nav"
                aria-labelledby="subheader-specifications-filter"
                subheader={
                    <ListSubheader component="div" id="subheader-specifications-filter" disableSticky={true}>
                        <Stack alignItems="center" direction="row" spacing={1}>
                            <Typography variant="headline" component="h5">{t("sidebar.specificationsFilter")}</Typography>
                            <FilterListIcon color="secondary" />
                        </Stack>
                    </ListSubheader>
                }>
                <SpecificationsFilter specifications={specifications}/>
            </List> : null}
        </>
    );
}