import DateTimeFormatConfig from "../../utils/DateTimeFormatConfig";
import RecurrenceOccurences from "../../utils/RecurrenceOccurences";

export class AvailabilityUtils {
    static MAXIMUM_RENTAL = 'max';
    static DIFFERENT_TENANT = 'tenant_not_allowed';
    static NOT_AVAILABLE = 'notAvailable';

    static LABEL = 'button.addToCart';
    static ERROR_PREFIX = '.disabled.';

    static handleAvailabilityResponse = (itemAvailability) => {
        let response = {
            available: itemAvailability,
            addToCartButtonLabel: ""
        };
        // Different tenant (itemAvailability === 'tenant_not_allowed')
        if(itemAvailability === AvailabilityUtils.DIFFERENT_TENANT) {
            response.available = false;
            response.addToCartButtonLabel = AvailabilityUtils.LABEL+""+AvailabilityUtils.ERROR_PREFIX+""+AvailabilityUtils.DIFFERENT_TENANT;
        }
        // Maximum rental period exceeded (itemAvailability === 'max')
        if(itemAvailability === AvailabilityUtils.MAXIMUM_RENTAL) {
            response.available = false;
            response.addToCartButtonLabel = AvailabilityUtils.LABEL+""+AvailabilityUtils.ERROR_PREFIX+""+AvailabilityUtils.MAXIMUM_RENTAL;
        }
        // Not available (itemAvailability === false)
        if(itemAvailability === false) {
            response.available = false;
            response.addToCartButtonLabel = AvailabilityUtils.LABEL+""+AvailabilityUtils.ERROR_PREFIX+""+AvailabilityUtils.NOT_AVAILABLE;
        }
        // Available (itemAvailability === true)
        if(itemAvailability === true) {
            response.available = true;
            response.addToCartButtonLabel = AvailabilityUtils.LABEL;
        }
        
        return response;
    }

    static prepareAvailabilityDataForProducts(products, state, mainProduct = {}, readAvailabilityForEachInterval = true) {
        let preparedDataForAvailabilityCheck = {};
        
        preparedDataForAvailabilityCheck = Object.values(products).map(product => {
            let productData = {
                article_id: product.product_id,
            };

            if(product.isConfigurable && product.subarticles) {
                productData.subarticles = [];
                for (const [id, subarticle] of Object.entries(product.subarticles)) {
                    let subarticleData = {
                        id,
                        quantity: 1,
                        intervals: AvailabilityUtils.prepareAvailabilityDataForItem(subarticle, state, mainProduct),
                        withAvailabilityForEachInterval: !readAvailabilityForEachInterval || subarticle.recurring ? false : true
                    };
                    productData.subarticles = [...productData.subarticles, subarticleData];
                }
            } else {
                productData.withAvailabilityForEachInterval = !readAvailabilityForEachInterval || product.isReturnKey || product.recurring ? false : true;
                productData.intervals = AvailabilityUtils.prepareAvailabilityDataForItem(product, state, mainProduct);
                productData.quantity = product?.quantity ?? mainProduct?.quantity ?? 1;
                productData.quantity = productData.quantity === "" ? 1 : productData.quantity;
            }
            return productData;
        });
        
        return preparedDataForAvailabilityCheck;
    }

    static prepareAvailabilityDataForItem(item, state, product) {
        let intervals = [];

        if(item.isReturnKey) {
            return state.cart.returnKeyData.intervalsForAvailability;
        }

        const itemDates = {
            recurring: item?.recurring ?? product?.recurring ?? state.filters.recurring,
            startDate: item?.startDate ?? product?.startDate ?? state.filters.startDate,
            endDate: item?.endDate ?? product?.endDate ?? state.filters.endDate,
            recurrenceType: item?.recurrenceType ?? product?.recurrenceType ?? state.filters.recurrenceType,
            startDateRecurring: item?.startDateRecurring ?? product?.startDateRecurring ?? state.filters.startDateRecurring,
            endDateRecurring: item?.endDateRecurring ?? product?.endDateRecurring ?? state.filters.endDateRecurring,
            startTime: item?.startTime ?? product?.startTime ?? state.filters.startTime,
            endTime: item?.endTime ?? product?.endTime ?? state.filters.endTime,
            repetitionInterval: item?.repetitionInterval ?? product?.repetitionInterval ?? state.filters.repetitionInterval,
            recurrenceStartDay: item?.recurrenceStartDay ?? product?.recurrenceStartDay ?? state.filters.recurrenceStartDay,
            recurrenceEndDay: item?.recurrenceEndDay ?? product?.recurrenceEndDay ?? state.filters.recurrenceEndDay,
        };

        if(itemDates.recurring) {
            intervals = RecurrenceOccurences.calculateOccurence(itemDates.startDateRecurring, itemDates.endDateRecurring, itemDates.repetitionInterval, itemDates.recurrenceStartDay, itemDates.recurrenceEndDay, itemDates.startTime, itemDates.endTime);
        } else {
            intervals = [{
                startDate: DateTimeFormatConfig.standardServerDateFormat(itemDates.startDate),
                endDate: DateTimeFormatConfig.standardServerDateFormat(itemDates.endDate)
            }];
        }

        return intervals;
    }
}