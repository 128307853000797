import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from "react-i18next";

import WorkingDays from '../../../../../utils/WorkingDays';
import RecurrenceDays from '../utils/RecurrenceDays';

import RecurrenceDayTimeSelectorView from './RecurrenceDayTimeSelectorView';

import { 
    // START
    selectRecurrenceStartDay,
    selectRecurrenceStartTime,
    selectDisabledDaysPickup,

    selectOutsideWorkingHoursPickup,
    selectOutsideWorkingHoursPickupError,
    changeRecurrenceStartDay,
    changeRecurrenceStartTime,
    changeRecurrenceStartTimeError,

    // END 
    selectRecurrenceEndDay,
    selectRecurrenceEndTime,
    selectDisabledDaysDropoff,

    selectOutsideWorkingHoursDropoff,
    selectOutsideWorkingHoursDropoffError,
    changeRecurrenceEndDay,
    changeRecurrenceEndTime,
    changeRecurrenceEndTimeError,
    setRecurrenceStartTimePickerEnabledHoursForDay,
    setRecurrenceEndTimePickerEnabledHoursForDay,
    selectRecurrenceStartTimePickerEnabledHoursForDay,
    selectRecurrenceEndTimePickerEnabledHoursForDay,
} from '../../../../../store/filtersSlice';

import { updateProduct } from '../../../../../store/productSlice';
import { updateCartItem } from '../../../../../store/cartSlice';
import { selectAllArticlesMustBeInTheSameInterval, selectSemesterSettings, selectWorkingHours } from '../../../../../store/settingsSlice';
import DateTimeFormatConfig from '../../../../../utils/DateTimeFormatConfig';

export default function RecurrenceDayAndTimeSelector(props) {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { item = false, subarticle = false, isCartItem = false, isStartDay } = props;

    const workingHours = useSelector(selectWorkingHours);
    const semesterSettings = useSelector(selectSemesterSettings);

    const allArticlesMustBeInTheSameInterval = useSelector(selectAllArticlesMustBeInTheSameInterval);
    
    const outsideWorkingHoursState = useSelector(isStartDay ? selectOutsideWorkingHoursPickup : selectOutsideWorkingHoursDropoff);
    const outsideWorkingHoursErrorState = useSelector(isStartDay ? selectOutsideWorkingHoursPickupError : selectOutsideWorkingHoursDropoffError);

    let outsideWorkingHours = isStartDay ? (subarticle?.outsideWorkingHoursPickup ?? item?.outsideWorkingHoursPickup ?? outsideWorkingHoursState) : (subarticle?.outsideWorkingHoursDropoff ?? item?.outsideWorkingHoursDropoff ?? outsideWorkingHoursState);
    let outsideWorkingHoursError = isStartDay ? (subarticle?.outsideWorkingHoursPickupError ?? item?.outsideWorkingHoursPickupError ?? outsideWorkingHoursErrorState) : (subarticle?.outsideWorkingHoursDropoffError ?? item?.outsideWorkingHoursDropoffError ?? outsideWorkingHoursErrorState);

    const stateOpeningTimes = useSelector(isStartDay ? selectRecurrenceStartTimePickerEnabledHoursForDay : selectRecurrenceEndTimePickerEnabledHoursForDay);
    let openingHours = isStartDay ? (subarticle?.openingTimesForStartDate ?? item?.openingTimesForStartDate ?? stateOpeningTimes) : (subarticle?.openingTimesForEndDate ?? item?.openingTimesForEndDate ?? stateOpeningTimes);

    const recurrenceDayState = useSelector(isStartDay ? selectRecurrenceStartDay : selectRecurrenceEndDay);
    const timeState = useSelector(isStartDay ? selectRecurrenceStartTime : selectRecurrenceEndTime);
    const disabledDaysState = useSelector(isStartDay ? selectDisabledDaysPickup : selectDisabledDaysDropoff);

    let recurrenceDay = isStartDay ? (subarticle?.recurrenceStartDay ?? item?.recurrenceStartDay ?? recurrenceDayState) : (subarticle?.recurrenceEndDay ?? item?.recurrenceEndDay ?? recurrenceDayState);
    let time = isStartDay ? (subarticle?.startTime ?? item?.startTime ?? timeState) : (subarticle?.endTime ?? item?.endTime ?? timeState);
    let disabledDays = isStartDay ? (subarticle?.disabledDaysPickup ?? item?.disabledDaysPickup ?? disabledDaysState) : (subarticle?.disabledDaysDropoff ?? item?.disabledDaysDropoff ?? disabledDaysState);

    const onTimeError = (reason) => {
        if(item) {
            if(isCartItem) {
                dispatch(updateCartItem({item, subarticle, field: (isStartDay ? "startTimeError" : "endTimeError"), value: (reason ? true : false)}));
            } else {
                dispatch(updateProduct({item, subarticle, field: (isStartDay ? "startTimeError" : "endTimeError"), value: (reason ? true : false)}));
            }

            if(allArticlesMustBeInTheSameInterval) {
                dispatch(isStartDay ? changeRecurrenceStartTimeError((reason ? true : false)) : changeRecurrenceEndTimeError((reason ? true : false)));
            }
        } else {
            dispatch(isStartDay ? changeRecurrenceStartTimeError((reason ? true : false)) : changeRecurrenceEndTimeError((reason ? true : false)));
        }
    }

    const doDateChangeForFilters = (isStartDay, newValue) => {
        dispatch(isStartDay ? changeRecurrenceStartDay(newValue) : changeRecurrenceEndDay(newValue));
        if(newValue !== 0) {
            const day = DateTimeFormatConfig.getNextDateForWeekday(newValue);
            const [openingTimesForSelectedDate, openingTime] = WorkingDays.prepareTimeSliderDataForDay(workingHours, semesterSettings, day, isStartDay ? RecurrenceDays.WORKING_HOURS_KEY_FOR_PICKUP : RecurrenceDays.WORKING_HOURS_KEY_FOR_DROPOFF, true);
            dispatch(isStartDay ? setRecurrenceStartTimePickerEnabledHoursForDay(openingTimesForSelectedDate) : setRecurrenceEndTimePickerEnabledHoursForDay(openingTimesForSelectedDate));
            dispatch(isStartDay ? changeRecurrenceStartTime(openingTime) : changeRecurrenceEndTime(openingTime));
        }
    }

    const handleRecurrenceDayChange = (event) => {
        const newValue = event.target.value;
        if(item) {
            if(isCartItem) {
                dispatch(updateCartItem({item, subarticle, field: (isStartDay ? "recurrenceStartDay" : "recurrenceEndDay"), value: newValue}));
            } else {
                dispatch(updateProduct({item, subarticle, field: (isStartDay ? "recurrenceStartDay" : "recurrenceEndDay"), value: newValue}));
            }

            if(allArticlesMustBeInTheSameInterval) {
                doDateChangeForFilters(isStartDay, newValue);
            }
        } else {
            doDateChangeForFilters(isStartDay, newValue);
        }
    };

    const handleRecurrenceTimeChange = (newValue) => {
        if(item) {
            if(isCartItem) {
                dispatch(updateCartItem({item, subarticle, field: (isStartDay ? "startTime" : "endTime"), value: newValue}));
            } else {
                dispatch(updateProduct({item, subarticle, field: (isStartDay ? "startTime" : "endTime"), value: newValue}));
            }

            if(allArticlesMustBeInTheSameInterval) {
                dispatch(isStartDay ? changeRecurrenceStartTime(newValue) : changeRecurrenceEndTime(newValue));
            }
        } else {
            dispatch(isStartDay ? changeRecurrenceStartTime(newValue) : changeRecurrenceEndTime(newValue));
        }
    }

    return (
        <RecurrenceDayTimeSelectorView 
            disabledDays={disabledDays} 
            recurrenceDay={recurrenceDay} 
            handleRecurrenceDayChange={handleRecurrenceDayChange} 
            selectDayLabel={t(isStartDay ? "sidebar.tab.recurring.startDay.select.label" : "sidebar.tab.recurring.endDay.select.label")} 
            recurrenceTime={time} 
            handleRecurrenceTimeChange={handleRecurrenceTimeChange} 
            recurrenceTimeLabel={t(isStartDay ? "sidebar.tab.recurring.startDay.input.label" : "sidebar.tab.recurring.endDay.input.label")}
            onTimeError={onTimeError}
            error={outsideWorkingHours && outsideWorkingHoursError !== ''}
            errorTitle=""
            errorText={outsideWorkingHoursError}
            isStartDay={isStartDay}
            openingHours={openingHours}
        />
    );
};