export const paddingLeft4 = { pl: 4 };

export const p1 = { p: 1 };
export const p2 = { p: 2 };
export const p3 = { p: 3 };
export const p4 = { p: 4 };

export const px1 = { px: 1 };
export const px2 = { px: 2 };
export const px3 = { px: 3 };
export const px4 = { px: 4 };

export const py1 = { py: 1 };
export const py2 = { py: 2 };
export const py3 = { py: 3 };
export const py4 = { py: 4 };

export const pr1 = { pr: 1 };
export const pr2 = { pr: 2 };
export const pr3 = { pr: 3 };
export const pr4 = { pr: 4 };

export const pt1 = { pt: 1 };
export const pt2 = { pt: 2 };
export const pt3 = { pt: 3 };
export const pt4 = { pt: 4 };

export const pb0 = { pb: 0 };
export const pb1 = { pb: 1 };
export const pb2 = { pb: 2 };
export const pb3 = { pb: 3 };
export const pb4 = { pb: 4 };

export const m1 = { m: 1 };
export const m2 = { m: 2 };
export const m3 = { m: 3 };
export const m4 = { m: 4 };

export const mx1 = { mx: 1 };
export const mx2 = { mx: 2 };
export const mx3 = { mx: 3 };
export const mx4 = { mx: 4 };

export const my1 = { my: 1 };
export const my2 = { my: 2 };
export const my3 = { my: 3 };
export const my4 = { my: 4 };

export const mt1 = { mt: 1 };
export const mt2 = { mt: 2 };
export const mt3 = { mt: 3 };
export const mt4 = { mt: 4 };

export const mb1 = { mb: 1 };
export const mb2 = { mb: 2 };
export const mb3 = { mb: 3 };
export const mb4 = { mb: 4 };

export const ml1 = { ml: 1 };
export const ml2 = { ml: 2 };
export const ml3 = { ml: 3 };
export const ml4 = { ml: 4 };

export const masonryFullWidthScrollable = {
    display: "flex",
    flexDirection: "column",
    flex: "1 1 auto",
    p: 2,
    overflow: 'auto',
    width: "100%"
}

export const xsOnlyDisplayFlex = { 
    display: { xs: 'flex', md: 'none' }
}

export const smAndUpDisplayFlex = {
    display: { xs: 'none', sm: 'flex' }
}

export const mdAndUpDisplayFlex = { 
    display: { xs: 'none', md: 'flex' }
}

export const flexRowFullWidth = { 
    display: 'flex', 
    flexDirection: "row", 
    flex: "1 1 auto", 
    overflow: 'hidden' 
};

export const flexColFullHeight = {
    display: "flex",
    flexDirection: "column",
    flex: "1 1 auto",
    overflow: 'hidden'
};

export const flexColFullHeightScrollable = {
    display: "flex",
    flexDirection: "column",
    flex: "1 1 auto",
    overflow: 'auto'
};

export const notScrollable = {
    overflow: "visible"
};

export const flexRowFullWidthCentered = { 
    display: 'flex', 
    flexDirection: "row", 
    flex: "1 1 auto", 
    overflow: 'hidden',
    justifyContent: "center",
    alignItems: "center"
};

export const noShrink = {
    flexShrink: 0
};

export const flexSpaceBetween = { 
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between"
};

export const flexRowCentered = { 
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center"
};

export const alignItemsEnd = { 
    alignItems: "end"
};

export const flexColCentered = { 
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center"
};

export const flexRowCenteredRight = { 
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center"
};

export const flexColStart = { 
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start"
};

export const fullWidthPadded1 = { 
    p: 1,
    width: 1,
};

export const fullWidth = { 
    width: 1
};
