import React from 'react';
import { useTranslation } from "react-i18next";

import { Stack, Typography, DialogTitle, DialogContent, DialogActions, Dialog } from '@mui/material';

import PrimaryButton from '../core/PrimaryButton';
import SecondaryButton from '../core/SecondaryButton';

import Logo from '../Logo';

const DIALOG_DISSMISSED_ESCAPE_KEY_DOWN = "escapeKeyDown";
const DIALOG_DISSMISSED_BACKDROP_CLICK = "backdropClick";

export default function ConfirmationDialogBase(props) {
    const { t } = useTranslation();
    const { children, title, open, onOk, onCancel, showActionBar = true, maxWidth = "xs", modal = true, showLogo = false, primaryButtonLabel = t("primaryButtonLabel"), secondaryButtonLabel = t("secondaryButtonLabel") } = props;

    const handleOk = (event, reason) => {
        if (modal && reason && (reason === DIALOG_DISSMISSED_ESCAPE_KEY_DOWN || reason === DIALOG_DISSMISSED_BACKDROP_CLICK ))  return;

        onOk();
    };
    
    const handleClose = (event, reason) => {
        if (modal && reason && (reason === DIALOG_DISSMISSED_ESCAPE_KEY_DOWN || reason === DIALOG_DISSMISSED_BACKDROP_CLICK ))  return;

        onCancel(false);
    };

    return (
            <Dialog
                open={open}
                onClose={handleClose}
                scroll="paper"
                maxWidth={maxWidth}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
            >
                <DialogTitle sx={{height: "unset"}} id="scroll-dialog-title">
                    <Stack direction="row" spacing={3}>
                        { showLogo && <Logo /> }
                        <Typography variant="h5">{title}</Typography>
                    </Stack>
                </DialogTitle>
                <DialogContent sx={{my: 2}}>
                        { children }
                </DialogContent>
                { showActionBar && 
                    <DialogActions sx={{m: 2, mt: 0}}>
                            <Stack direction="row" width="100%" spacing={2} justifyContent="space-between">
                                <SecondaryButton label={secondaryButtonLabel} onClick={handleClose} />
                                <PrimaryButton label={primaryButtonLabel} onClick={handleOk} />
                            </Stack>
                            
                    </DialogActions> }
            </Dialog>
    );
}