import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from "react-i18next";

import { Box, Typography } from '@mui/material';

import TextField from '../../common/ui/qvshop/components/TextField';
import PrimaryButton from '../../common/ui/qvshop/components/core/PrimaryButton';

import { resetPassword, selectCode, selectCodeError, selectCodeErrorCode, selectEmailUsername, selectPassword, selectPasswordConfirm, selectPasswordConfirmError, selectPasswordError, selectPasswordsDoNotMatch, setCode, setCodeError, setPassword, setPasswordConfirm, setPasswordConfirmError, setPasswordError } from '../../common/store/passwordForgottenSlice';

export default function ResetPassword() {
    const { t } = useTranslation(['passwordForgotten']);
    const dispatch = useDispatch();
    
    const emailUsername = useSelector(selectEmailUsername);
    const code = useSelector(selectCode);
    const codeError = useSelector(selectCodeError);
    const codeErrorCode = useSelector(selectCodeErrorCode);
    const password = useSelector(selectPassword);
    const passwordError = useSelector(selectPasswordError);
    const passwordConfirm = useSelector(selectPasswordConfirm);
    const passwordConfirmError = useSelector(selectPasswordConfirmError);
    const passwordsDoNotMatchError = useSelector(selectPasswordsDoNotMatch);

    const changeCode = (event) => {
        const value = event.target.value;
        dispatch(setCode(value));
    }

    const changePassword = (event) => {
        const value = event.target.value;
        dispatch(setPassword(value));
    }

    const changePasswordConfirm = (event) => {
        const value = event.target.value;
        dispatch(setPasswordConfirm(value));
    }

    const handleSubmit = async (event) => {
        event.preventDefault();

        let formHasErrors = false;
        if(code === '') {
            dispatch(setCodeError(true));
            formHasErrors = true;
        } else {
            dispatch(setCodeError(false));
        }

        if(password === '') {
            dispatch(setPasswordError(true));
            formHasErrors = true;
        } else {
            dispatch(setPasswordError(false));
        }

        if(passwordConfirm === '') {
            dispatch(setPasswordConfirmError(true));
            formHasErrors = true;
        } else {
            dispatch(setPasswordConfirmError(false));
        }

        if(!(formHasErrors 
            || passwordsDoNotMatchError
            )) 
        {
            dispatch(resetPassword());
        } else {
            console.log("Form has errors!");
        }
    };

    return (
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            <TextField
                margin="normal"
                fullWidth
                id="emailUsername"
                label={t("inputLabel.emailOrUsername")}
                name="emailUsername"
                autoComplete="emailUsername"
                value={emailUsername}
                InputProps={{
                    readOnly: true,
                }}
            />
            <Typography component="h2" variant="body2">
                {t("code.explanatoryText")}
            </Typography>
            <TextField
                error={codeError}
                errorTitle=""
                errorText={codeError && codeErrorCode !== '' ? t("codeError."+codeErrorCode) : ""}
                margin="normal"
                required
                fullWidth
                id="resetCode"
                label={t("inputLabel.code")}
                name="resetCode"
                value={code}
                onChange={changeCode}
            />
            <TextField
                error={passwordError}
                errorTitle=""
                errorText=""
                margin="normal"
                required
                fullWidth
                name="newPassword"
                label={t("inputLabel.password")}
                type="password"
                id="newPassword"
                value={password}
                onChange={changePassword}
            />
            <TextField
                error={passwordConfirmError || passwordsDoNotMatchError}
                errorTitle=""
                errorText={passwordsDoNotMatchError ? t("inputError.passwordsDoNotMatch") : ""}
                margin="normal"
                required
                fullWidth
                name="passwordConfirm"
                label={t("inputLabel.passwordConfirm")}
                type="password"
                id="passwordConfirm"
                value={passwordConfirm}
                onChange={changePasswordConfirm}
            />
            <PrimaryButton type="submit" fullWidth sx={{ mt: 3, mb: 2 }} label={t("button.resetPassword")} />
        </Box>
    );
}